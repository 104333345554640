import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import { AccessRight } from 'interfaces/Clients/clinician';

export const defaultWorkingSchedule = {
  monday: {
    isActive: false,
    startTime: '08:00',
    endTime: '17:00'
  },
  tuesday: {
    isActive: false,
    startTime: '08:00',
    endTime: '17:00'
  },
  wednesday: {
    isActive: false,
    startTime: '08:00',
    endTime: '17:00'
  },
  thursday: {
    isActive: false,
    startTime: '08:00',
    endTime: '17:00'
  },
  friday: {
    isActive: false,
    startTime: '08:00',
    endTime: '17:00'
  },
  saturday: {
    isActive: false,
    startTime: '08:00',
    endTime: '17:00'
  },
  sunday: {
    isActive: false,
    startTime: '08:00',
    endTime: '17:00'
  }
};

export const defaultCaseLoad: PractitionersDetailsInterface['caseLoad'] = {
  availableSlot: 0,
  activeClient: 0
};

export const defaultProfileData: ProfileInterface = {
  _id: '',
  accountId: '',
  specialisations: [''],
  consultantLocations: [''],
  title: 'None',
  titleValue: '',
  name: '',
  accessRight: AccessRight.User,
  email: '',
  mobileNumber: '',
  caseLoad: defaultCaseLoad,
  workingSchedule: defaultWorkingSchedule,
  status: 'active',
  customFeatures: []
};

interface ProfileDataState {
  data: ProfileInterface;
  isLoading: boolean;
}

const initialState: ProfileDataState = {
  data: defaultProfileData,
  isLoading: false
};

const profileDataSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfileData: (state, action: PayloadAction<ProfileInterface>) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    setProfileDataLoading: (state) => {
      state.isLoading = true;
    }
  }
});

export const myProfileInfo = (state: RootState) => state.profileDataSlice;

export const { setProfileData, setProfileDataLoading } = profileDataSlice.actions;

export default profileDataSlice.reducer;
