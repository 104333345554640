import { CalendarNav, CalendarNext, CalendarPrev, CalendarToday } from '@mobiscroll/react';
import styles from './CalendarHeader.module.scss';
import './MobiscrollCustom.scss';
import TimeZoneLabel from 'components/TimeZoneLabel/TimeZoneLabel';
import ToggleSwitchV2, { ToggleList } from 'components/ToggleSwitchV2/ToggleSwitchV2';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  calendarSettings,
  dayWeekType,
  setDayWeekView
} from 'redux/calendarAppointmentList/calendarAppointmentListDataSlice';
import CalendarViewConfig from './components/CalendarViewConfig/CalendarViewConfig';

const CalendarHeader = () => {
  const dispatch = useAppDispatch();
  const { dayWeekView } = useAppSelector(calendarSettings);

  const calendarViewToggleList = useMemo<ToggleList[]>(
    () => [
      {
        id: dayWeekType.day,
        label: 'Day',
        isActive: dayWeekView === dayWeekType.day
      },
      {
        id: dayWeekType.week,
        label: 'Week',
        isActive: dayWeekView === dayWeekType.week
      }
    ],
    [dayWeekView]
  );

  return (
    <div className={styles.container}>
      <div className={styles.nav} id={'calendarNavId'}>
        <CalendarToday className="cal-header-today" />
        <CalendarPrev className="cal-header-prev" />
        <CalendarNext className="cal-header-next" />
        <CalendarNav className="cal-header-nav" />
      </div>
      <ToggleSwitchV2
        id={'calendarView'}
        toggleList={calendarViewToggleList}
        onChangeStatus={(value) => {
          dispatch(setDayWeekView(value.id as dayWeekType));
        }}
        size={'small'}
        className={styles.viewToggle}
        activeLabelClassName={styles.activePill}
        indicatorClassName={styles.indicatorBg}
      />
      <CalendarViewConfig />
      <div className={styles.spacer} />
      <TimeZoneLabel />
    </div>
  );
};

export default CalendarHeader;
