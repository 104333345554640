import { TelehealthRecordingComposition } from 'interfaces/Schedule/Appointment';
import styles from './AppointmentRecordingsModalHeader.module.scss';
import { useMemo } from 'react';
import moment from 'moment';
import { MOMENTJS_SHORT_YEAR_FORMAT, MOMENTJS_TIME_FORMAT_DAY_PARTS } from 'utils/dateChecker';

interface AppointmentRecordingsModalHeaderProps {
  currentRecordingId?: string;
  recordings: TelehealthRecordingComposition[];
  onClickRecording: (recordingId: string) => void;
}

const AppointmentRecordingsModalHeader = ({
  currentRecordingId,
  recordings,
  onClickRecording
}: AppointmentRecordingsModalHeaderProps) => {
  const { current, previous, next } = useMemo(() => {
    const headerItems: {
      current?: TelehealthRecordingComposition;
      previous?: TelehealthRecordingComposition;
      next?: TelehealthRecordingComposition;
    } = {};

    const currentIndex = recordings.findIndex(({ _id }) => _id === currentRecordingId);

    if (currentIndex > -1) {
      headerItems.current = recordings[currentIndex];
      headerItems.previous = recordings[currentIndex - 1];
      headerItems.next = recordings[currentIndex + 1];
    }

    return headerItems;
  }, [currentRecordingId, recordings]);

  return (
    <div className={styles.container}>
      {previous && (
        <div className={`${styles.btnBox} ${styles.left}`} onClick={() => onClickRecording(previous._id)}>
          <i className={`material-icons ${styles.icon}`}>chevron_left</i>
          <div className={styles.date}>
            <div className={styles.label}>
              {moment(previous.createdAt).format(`${MOMENTJS_TIME_FORMAT_DAY_PARTS} ${MOMENTJS_SHORT_YEAR_FORMAT}`)}
            </div>
          </div>
        </div>
      )}
      <div className={styles.titleWrapper}>
        <div className={styles.title}>
          {current
            ? moment(current.createdAt).format(`${MOMENTJS_TIME_FORMAT_DAY_PARTS} ${MOMENTJS_SHORT_YEAR_FORMAT}`)
            : 'Appointment recording'}
        </div>
      </div>
      {next && (
        <div className={`${styles.btnBox} ${styles.right}`} onClick={() => onClickRecording(next._id)}>
          <div className={styles.date}>
            <div className={styles.label}>
              {moment(next.createdAt).format(`${MOMENTJS_TIME_FORMAT_DAY_PARTS} ${MOMENTJS_SHORT_YEAR_FORMAT}`)}
            </div>
          </div>
          <i className={`material-icons ${styles.icon}`}>chevron_right</i>
        </div>
      )}
    </div>
  );
};

export default AppointmentRecordingsModalHeader;
