import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { security } from 'utils/security';

export enum SSTagTypes {
  AppointmentTypes = 'Appointment Types',
  NextAppointmentByCurrent = 'Next Appointment By Current',
  AppointmentTypesForInlineAppointment = 'Appointment Types For Inline Appointment',
  RoomList = 'Room List',
  AvailableRoom = 'Available Room',
  ListOfReviewAppointments = 'List Of Review Appointments',
  AppointmentTypeById = 'Appointment Type By Id',
  CarePathwayAppointment = 'Care Pathway Appointment',
  AppointmentList = 'Appointment List',
  MinifiedPackageList = 'Minified Package List',
  ClientAppointmentsByDateRange = 'Client Appointments By Date Range',
  AppointmentTypesPaging = 'Appointment Types Paging',
  PackageList = 'Package List',
  ScheduledPayment = 'Scheduled Payment',
  PackageDetail = 'Package Detail',
  PackageAssignmentList = 'Package Assignment List',
  AppointmentReport = 'Appointment Report',
  AppointmentRecordingDownloadLink = 'Appointment Recording Download Link'
}

export const scheduleServicesApiSlice = createApi({
  reducerPath: 'scheduleServiceApis',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SCHED_SERVICE_URL,
    prepareHeaders: async (headers) => {
      const token = await security.getAccessTokenSilently();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: Object.values(SSTagTypes),
  endpoints: () => ({})
});
