import styles from './StartTimeSettingControl.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { calendarSettings, setStartTimeValue } from 'redux/calendarAppointmentList/calendarAppointmentListDataSlice';
import { CALENDAR_START_TIME_LS_VALUE } from '../../../../utils';
import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';

const TIME_OPTIONS = [
  { label: 'AUTO', value: 'auto' },
  { label: '7 AM', value: '7' },
  { label: '8 AM', value: '8' },
  { label: '9 AM', value: '9' },
  { label: '10 AM', value: '10' },
  { label: '11 AM', value: '11' },
  { label: '12 PM', value: '12' },
  { label: '1 PM', value: '13' },
  { label: '2 PM', value: '14' },
  { label: '3 PM', value: '15' },
  { label: '4 PM', value: '16' },
  { label: '5 PM', value: '17' },
  { label: '6 PM', value: '18' },
  { label: '7 PM', value: '19' },
  { label: '8 PM', value: '20' },
  { label: '9 PM', value: '21' },
  { label: '10 PM', value: '22' },
  { label: '11 PM', value: '23' }
];

const StartTimeSettingControl = () => {
  const dispatch = useAppDispatch();
  const { startTimeValue } = useAppSelector(calendarSettings);

  const handleSelectedTime = (timeValue: string) => {
    dispatch(setStartTimeValue(timeValue));
    localStorage.setItem(CALENDAR_START_TIME_LS_VALUE, timeValue);
  };

  /* render main component ---------------------------------------- */
  return (
    <div className={styles.container}>
      <i className={`material-icons ${styles.fromIcon}`}>schedule</i>
      <div className={styles.timeDropdownWrapper}>
        <DropdownSearchable
          isSplittedList
          selected={startTimeValue}
          options={TIME_OPTIONS}
          onSelect={handleSelectedTime}
          showBorderBottom
        />
      </div>
    </div>
  );
};

export default StartTimeSettingControl;
