import { InvoiceViewProps } from '../../InvoiceView';
import { calculateInvoiceTotals, formatInvoiceNumber } from './calculateInvoiceTotals';

import styles from './InvoiceTotal.module.scss';
import { useCurrency } from 'utils/hooks/useCurrency';

interface InvoiceTotalProps {
  discount: InvoiceViewProps['discount'];
  items: InvoiceViewProps['items'];
  taxRate: InvoiceViewProps['taxRate'];
  status: InvoiceViewProps['status'];
  invoiceAmount: number;
  amountChanged?: boolean;
}

const InvoiceTotal = ({ discount, items, taxRate, status, invoiceAmount, amountChanged }: InvoiceTotalProps) => {
  const { CURRENCY_SYMBOL } = useCurrency();

  const { totalCost, totalTax, totalPayment } = amountChanged
    ? { totalCost: 0, totalTax: 0, totalPayment: invoiceAmount }
    : calculateInvoiceTotals(items, taxRate, discount?.type, discount?.value);

  return (
    <div className={styles.container}>
      <span className={styles.totalLabel}>TOTAL</span>
      {!amountChanged && (
        <>
          <span className={styles.costTotal}>{formatInvoiceNumber(CURRENCY_SYMBOL, totalCost)}</span>
          <span className={styles.taxTotal}>{formatInvoiceNumber(CURRENCY_SYMBOL, totalTax)}</span>
        </>
      )}
      <span className={styles.finalTotal}>
        {status !== 'confirmPaid' && <span className={styles.label}>TO PAY</span>}
        {formatInvoiceNumber(CURRENCY_SYMBOL, totalPayment)}
      </span>
    </div>
  );
};

export default InvoiceTotal;
