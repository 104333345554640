import { notification } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import FormikMaterialInput from 'components/MaterialInput/FormikMaterialInput';
import FormikMaterialPhoneInput from 'components/MaterialPhoneInput/FormikMaterialPhoneInput';
import FormikSelect from 'components/Select/CommonSelect/FormikSelect';
import CreatableSelect from 'components/Select/CommonSelect/Select';
import { Formik, FormikHelpers } from 'formik';
import { ProfileInterface, RegistrationInterface } from 'interfaces/Profile/Profile';
import { ChangeEvent, useContext, useState } from 'react';
import { updateClinicianContactDetails, uploadAvatar } from 'utils/http/clinician';
import { updateClinicianProfile } from 'utils/http/ClinicianProfileService/Profile/profile';
import { getEmailUsed } from 'utils/http/verification';
import * as yup from 'yup';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { salutationOptions } from 'pages/SignUp/components/AboutYou/AboutYou';
import AvatarEditor from '../AvatarEditor/AvatarEditor';
import ConfirmUpdateContactDetailsModal from './components/ConfirmUpdateContactDetailsModal/ConfirmUpdateContactDetailsModal';
import styles from './ProfileAboutYou.module.scss';
import specialisations from './specialisations.json';
import FormikPlaceMaterialInput from 'components/FormikPlaceMaterialInput/FormikPlaceMaterialInput';
import { FIT_CLIENT_GENDER_LIST_LABELS, FitPsychologistGenderList } from '../HelmControl/interfaces/fitFilter';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import PractitionerAction from './components/PractitionerAction/PractitionerAction';
import ProfileMfaBadge from './components/ProfileMfaBadge/ProfileMfaBadge';
import { UserContext } from 'utils/UserContextProvider';
import { ClinicianStatus } from 'interfaces/Clients/clinician';
import classNames from 'classnames';
import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import TitleInput from 'components/v2/TitleInput/TitleInput';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { security } from 'utils/security';

const PRONOUN_OPTIONS = [
  { label: 'He / Him', value: 'heHim' },
  { label: 'She / Her', value: 'sheHer' },
  { label: 'They / Them', value: 'theyThem' }
];

export const SALUTATION_OPTIONS = [
  ...salutationOptions,
  { label: 'Other', value: 'Other' },
  { label: 'None', value: 'None' }
];

const GENDER_OPTIONS = Object.keys(FitPsychologistGenderList).map((key) => ({
  value: key,
  label: FIT_CLIENT_GENDER_LIST_LABELS[key as keyof typeof FitPsychologistGenderList]
}));

export interface ProfileError {
  registrations?: RegistrationInterface[] | undefined;
}

interface ProfileAboutYouProps {
  profile: ProfileInterface;
  onUpdateProfile: (newProfile: ProfileInterface) => void;
  fetchProfile?: () => void;
  isMobileOptional?: boolean;
}

const ProfileAboutYou = ({ profile, onUpdateProfile, fetchProfile, isMobileOptional }: ProfileAboutYouProps) => {
  const { clinicianProfile: loggedInClinicianProfile } = useContext(UserContext);
  const { isRegionUK } = useGetFeatureToggle();

  const profileSchema = yup.object().shape({
    avatar: yup.string().nullable(),
    bio: yup.string().nullable(),
    consultantLocations: yup.array().of(yup.string()),
    email: yup.string().required('Please enter your email address'),
    mobileNumber: isMobileOptional
      ? yup.string().nullable().min(11, 'Mobile number must be 10 digits or longer')
      : yup
          .string()
          .min(11, 'Mobile number must be 10 digits or longer')
          .required('Please enter your mobile phone number'),
    name: yup.string().required('Please enter your preferred name'),
    titleValue: yup.string().when('title', {
      is: (title: string) => title === 'Other',
      then: yup.string().required('Please tell us how we should address you'),
      otherwise: yup.string()
    }),
    pronouns: yup.string().nullable(),
    specialisations: yup.array().of(yup.string()),
    registrations: yup
      .array()
      .of(
        yup.object().shape({
          registerType: yup.string().required('Please select registration type'),
          registerNumber: yup.string().required('Please enter registration number')
        })
      )
      .nullable()
  });

  const REGISTRATION_TYPES_OPTIONS = isRegionUK
    ? [
        { label: 'HCPC', value: 'hcpc' },
        { label: 'BPS', value: 'bps' },
        { label: 'GMC', value: 'gmc' },
        { label: 'Other', value: 'other' }
      ]
    : [
        { label: 'AHPRA', value: 'ahpra' },
        { label: 'MAPS', value: 'maps' },
        { label: 'Medicare', value: 'medicare' },
        { label: 'Other', value: 'other' }
      ];

  const [isAvatarEditorVisible, setIsAvatarEditorVisible] = useState(false);
  const [isEmailAddressInputDisabled, setIsEmailAddressInputDisabled] = useState(true);
  const [isMobileNumberInputDisabled, setIsMobileNumberInputDisabled] = useState(true);
  const [isUpdateContactDetailsModalVisible, setIsUpdateContactDetailsModalVisible] = useState(false);
  const [uploadingAvatar, setUploadingAvatar] = useState(false);
  const [errors, setErrors] = useState<ProfileError>({});

  const [saveButtonStatus, setSaveButtonStatus] = useState<'' | 'active' | 'finished'>('');

  const { isEdgeAdminView, isEdgeReceptionistView } = useGetAccountPackageView();

  const isNotOwnProfile = profile._id !== loggedInClinicianProfile?._id;
  const isActivePractitioner = profile.status === ClinicianStatus.Active;
  const isWorkspaceClinician = profile.workspaces && profile.workspaces.length > 1;
  const hideUnlockContactField = isWorkspaceClinician || (!isEdgeAdminView && isNotOwnProfile) || !isActivePractitioner;
  const isShowTfaBadge = isEdgeAdminView && !hideUnlockContactField;

  const beforeInitialUploadAvatar = (file: File) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({ message: `You can only upload JPG or PNG` });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notification.error({ message: `Image cannot be bigger than 2MB!` });
    }
    return isJpgOrPng && isLt2M;
  };

  const handleInitialUploadAvatar = async (
    { file, onSuccess }: any,
    setFieldValue: FormikHelpers<ProfileInterface>['setFieldValue']
  ) => {
    setUploadingAvatar(true);
    const data = new FormData();
    data.append('file', file);
    const res = (await uploadAvatar(data)) as {
      [key: string]: any;
      [key: number]: any;
    };

    const body = await res.json();
    setFieldValue('avatar', body.data.url);
    setUploadingAvatar(false);
    onSuccess(res);
  };

  const handleAvatarEditorOpen = () => {
    setIsAvatarEditorVisible(true);
  };

  const handleAvatarEditorClose = () => {
    setIsAvatarEditorVisible(false);
  };

  const handleUpdateContactDetailsModalClose = () => {
    setIsUpdateContactDetailsModalVisible(false);
  };

  const handleAvatarEditorCancel = (
    setFieldValue: FormikHelpers<ProfileInterface>['setFieldValue'],
    originalAvatar?: string
  ) => {
    setFieldValue('avatar', originalAvatar);
    handleAvatarEditorClose();
  };

  const handleSpecialisationsChange = (
    values: ProfileInterface,
    setFieldValue: FormikHelpers<ProfileInterface>['setFieldValue'],
    specialisation?: { label: string; value: any }
  ) => {
    if (specialisation) {
      setFieldValue('specialisations', [...values.specialisations, specialisation.value]);
    }
  };

  const handleRemoveSpecialisation = (
    specialisation: string,
    values: ProfileInterface,
    setFieldValue: FormikHelpers<ProfileInterface>['setFieldValue']
  ) => {
    setFieldValue(
      'specialisations',
      values.specialisations.filter((value) => value !== specialisation)
    );
  };

  const handleConsultantLocationButtonClick = (
    locationIndex: number,
    values: ProfileInterface,
    setFieldValue: FormikHelpers<ProfileInterface>['setFieldValue']
  ) => {
    if (values.consultantLocations.length === 1) {
      setFieldValue('consultantLocations', [...values.consultantLocations, '']);
    } else {
      setFieldValue(
        'consultantLocations',
        values.consultantLocations.filter((location, index) => index !== locationIndex)
      );
    }
  };

  const handleVerifyError = (setFieldError: FormikHelpers<ProfileInterface>['setFieldError']) => {
    handleUpdateContactDetailsModalClose();

    notification.error({ message: 'Invalid phone number' });
    setFieldError('mobileNumber', 'Invalid phone number');
    setSaveButtonStatus('');
  };

  const handleUpdateProfile = async (values: ProfileInterface) => {
    try {
      const token = await security.getAccessTokenSilently();

      await updateClinicianProfile(token, values, isEdgeAdminView || isEdgeReceptionistView ? values._id : undefined);

      if (values.email !== profile.email || values.mobileNumber !== profile.mobileNumber) {
        await updateClinicianContactDetails(
          token,
          { email: values.email, mobileNumber: values.mobileNumber },
          isEdgeAdminView || isEdgeReceptionistView ? values._id : undefined
        );
      }
      fetchProfile && fetchProfile();

      !isEdgeAdminView && onUpdateProfile(values);

      handleUpdateContactDetailsModalClose();

      setIsEmailAddressInputDisabled(true);
      setIsMobileNumberInputDisabled(true);
      setSaveButtonStatus('finished');

      notification.success({
        message: 'Profile updated',
        closeIcon: <span className="success">OK</span>
      });

      setTimeout(() => setSaveButtonStatus(''), 2000);
    } catch (ex) {
      console.error(ex);

      setSaveButtonStatus('');

      notification.error({ message: 'Something went wrong while trying to update your practices' });
    }
  };

  const handleAddRegistrationButtonClick = (
    values: ProfileInterface,
    setFieldValue: FormikHelpers<ProfileInterface>['setFieldValue']
  ) => {
    setFieldValue('registrations', [...(values.registrations || []), { _id: uuidv4() }]);
  };

  const handleDeleteRegistrationButtonClick = (
    id: string,
    values: ProfileInterface,
    setFieldValue: FormikHelpers<ProfileInterface>['setFieldValue'],
    validateForm: FormikHelpers<ProfileInterface>['validateForm']
  ) => {
    setFieldValue(
      'registrations',
      (values.registrations || []).filter((value) => value._id !== id)
    );
    validateRegistrationForm(validateForm);
  };

  const handleRegistrationsChange = (
    fieldValue: string | number,
    fieldName: keyof RegistrationInterface,
    index: number,
    setFieldValue: FormikHelpers<ProfileInterface>['setFieldValue'],
    validateForm: FormikHelpers<ProfileInterface>['validateForm']
  ) => {
    setFieldValue(`registrations[${index}].${fieldName}`, fieldValue);
    validateRegistrationForm(validateForm);
  };

  const validateRegistrationForm = async (validateForm: FormikHelpers<ProfileInterface>['validateForm']) => {
    setTimeout(async () => {
      const formErrors = await validateForm();
      setErrors((prev) => ({ ...prev, registrations: formErrors.registrations as ProfileError['registrations'] }));
    }, 10);
  };

  const handleSubmit = async (values: ProfileInterface, { setFieldError }: FormikHelpers<ProfileInterface>) => {
    setSaveButtonStatus('active');

    let validateUser = false;
    if (values.email !== profile.email || values.mobileNumber !== profile.mobileNumber) {
      try {
        if (values.email !== profile.email) {
          const callGetClinicianEmailUsed = await getEmailUsed(values.email);

          const { used } = await callGetClinicianEmailUsed.json();

          if (used) {
            notification.error({ message: 'Email already in use' });
            setFieldError('email', 'Email already in use');
            setSaveButtonStatus('');
            return;
          }
        }
      } catch (ex) {
        notification.error({ message: 'Invalid email format' });
        setFieldError('email', 'Invalid email format');
        setSaveButtonStatus('');
        return;
      }
      validateUser = !(isEdgeAdminView || isEdgeReceptionistView);
    }
    if (validateUser) {
      setIsUpdateContactDetailsModalVisible(true);
    } else {
      const checkTitleValueIsNone = values.title !== 'None' ? values.title : '';
      const massageUpdateData = {
        ...values,
        title: values.title === 'Other' ? values.titleValue : checkTitleValueIsNone,
        titleValue: values.title !== 'Other' ? checkTitleValueIsNone : values.titleValue
      };
      await handleUpdateProfile(massageUpdateData);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <div className={styles.title}>About You</div>
          <div className={styles.description}>
            Write an overview about yourself and your specialisations for clients to get to know you a little better.
          </div>
        </div>
        <div>
          {isEdgeAdminView && (
            <PractitionerAction
              id={profile._id}
              practitionerRole={profile.accessRight}
              practitionerName={profile.name}
              practitionerEmail={profile.email}
              fetchProfile={fetchProfile}
              allowResetPassword={!hideUnlockContactField}
              isActive={isActivePractitioner}
            />
          )}
        </div>
      </div>
      <Formik initialValues={profile} validationSchema={profileSchema} onSubmit={handleSubmit}>
        {({ values, setFieldError, setFieldValue, submitForm, validateForm }) => (
          <>
            <AvatarEditor
              borderRadius={1000}
              filenameSuffix="profile-avatar"
              imgSrc={values.avatar}
              placeholder="Add your profile picture"
              title="Upload profile picture"
              visible={isAvatarEditorVisible}
              isAvatarUploading={uploadingAvatar}
              onBeforeUpload={beforeInitialUploadAvatar}
              onCancel={() => handleAvatarEditorCancel(setFieldValue, values.avatar)}
              onClose={handleAvatarEditorClose}
              onUploadAvatar={(value) => handleInitialUploadAvatar(value, setFieldValue)}
            />
            <ConfirmUpdateContactDetailsModal
              mobileNumber={values.mobileNumber}
              visible={isUpdateContactDetailsModalVisible}
              onCancel={handleUpdateContactDetailsModalClose}
              onSubmit={() => handleUpdateProfile(values)}
              onVerifyError={() => handleVerifyError(setFieldError)}
            />
            <div className={styles.basicDetails}>
              <div className={styles.avatarContainer} id={'profileAvatarId'}>
                {values.avatar ? (
                  <img className={styles.avatar} src={values.avatar} alt="Avatar" onClick={handleAvatarEditorOpen} />
                ) : (
                  <div className={styles.uploadAvatarPrompt} onClick={handleAvatarEditorOpen}>
                    <i className={`material-icons-outlined ${styles.icon}`}>add_a_photo</i>
                    ADD PROFILE PIC
                  </div>
                )}
              </div>
              <div className={styles.detailsInputContainer} id={'detailsInputContainerId'}>
                <div className={styles.name}>
                  {profile?.titleValue} {profile.name}
                  {isShowTfaBadge && (
                    <ProfileMfaBadge profile={profile} onUpdateEnforceMfa={() => fetchProfile && fetchProfile()} />
                  )}
                </div>
                <div className={styles.informationContainer}>
                  <div className={styles.salutationContainer}>
                    <div className={styles.salutationOptionContainer}>
                      <div className={styles.label}>Your salutation</div>
                      <FormikSelect
                        styles={{
                          valueContainer: (base: any) => ({
                            ...base,
                            paddingLeft: 0
                          })
                        }}
                        noCreateNewOption
                        name={'title'}
                        options={SALUTATION_OPTIONS}
                      />
                    </div>
                    {values.title === 'Other' && (
                      <div className={styles.otherValueContainer}>
                        <div className={styles.label}>Salutation</div>
                        <FormikMaterialInput id={'salutation'} maxLength={8} label="" name={'titleValue'} required />
                      </div>
                    )}
                  </div>
                  <div className={styles.genderContainer}>
                    <div className={styles.genderOptionContainer}>
                      <div className={styles.label}>Your Gender</div>
                      <FormikSelect
                        styles={{
                          valueContainer: (base: any) => ({
                            ...base,
                            paddingLeft: 0
                          })
                        }}
                        noCreateNewOption
                        name={'gender'}
                        options={GENDER_OPTIONS}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.nameDetailsContainer}>
                  <div className={styles.nameContainer}>
                    <div className={styles.label}>
                      Preferred name
                      <HelpOutLineWithTooltips
                        id={'name-input'}
                        desc={
                          'This is the name we will show to clients whenever you are referenced in a message or communication.  You can go informal e.g. just your first name, or more formal e.g. Dr S Howard depending on what best fits your communication style'
                        }
                      />
                    </div>
                    <FormikMaterialInput id={'name'} label="" name="name" required />
                  </div>
                  <div className={styles.pronounsContainer}>
                    <div className={styles.label}>
                      Your pronouns
                      <HelpOutLineWithTooltips
                        id={'pronoun-select'}
                        desc={
                          'Please select your preferred pronouns.  This will be used whenever you are referenced in the third person.  E.g. Dr Sarah Howard has requested you send her an updated referral '
                        }
                      />
                    </div>
                    <FormikSelect
                      styles={{ valueContainer: (base: any) => ({ ...base, paddingLeft: 0 }) }}
                      name="pronouns"
                      options={PRONOUN_OPTIONS}
                    />
                  </div>
                </div>
                <div className={styles.mobileAndEmailContainer}>
                  <div className={styles.mobileAndEmailInputContainer}>
                    <div className={styles.mobileNumberContainer}>
                      {isMobileNumberInputDisabled ? (
                        <div className={styles.mobileNumberValue}>
                          <div className={styles.label}>Mobile phone number</div>
                          {values.mobileNumber ? (
                            <div className={styles.value}>{values.mobileNumber}</div>
                          ) : (
                            <div className={styles.noValue}>(none)</div>
                          )}
                        </div>
                      ) : (
                        <div className={styles.mobileFieldWrapper}>
                          <FormikMaterialPhoneInput
                            id={'mobileNumber'}
                            className={styles.inputContainer}
                            label="Mobile phone number*"
                            name="mobileNumber"
                            onChange={(e: string) => setFieldValue('mobileNumber', e)}
                            required
                          />
                        </div>
                      )}
                    </div>
                    <div className={styles.emailContainer}>
                      {isEmailAddressInputDisabled ? (
                        <div className={styles.emailValue}>
                          <div className={styles.label}>Email address</div>
                          <div className={styles.value}>{values.email}</div>
                        </div>
                      ) : (
                        <FormikMaterialInput
                          id={'emailAddress'}
                          className={styles.inputContainer}
                          label="Email address*"
                          name="email"
                          required
                        />
                      )}
                    </div>
                  </div>
                  <div className={styles.mobileAndEmailInfoContainer}>
                    {isActivePractitioner && isWorkspaceClinician && (
                      <div className={styles.workspaceInformationContainer}>
                        <i className={`material-icons-outlined ${styles.icon}`}>info</i>
                        <div className={styles.workspaceInformation}>
                          Accessible Workspaces:
                          <ul className={styles.workspaceList}>
                            {profile.workspaces
                              ?.filter((workspace) => workspace.practice.name)
                              .map((workspace, idx) => (
                                <li key={idx}>{workspace.practice.name}</li>
                              ))}
                          </ul>
                          If you wish to update your email or mobile number, please reach out to Tacklit support team
                          for assistance
                        </div>
                      </div>
                    )}
                    {!hideUnlockContactField && (
                      <div
                        className={classNames(
                          styles.mobileNumberContainer,
                          !isMobileNumberInputDisabled && styles.hiddenButton
                        )}
                      >
                        <ButtonAlt
                          size={'medium'}
                          variant="text"
                          onClick={() => setIsMobileNumberInputDisabled(false)}
                          icon={'lock'}
                        >
                          Unlock to edit
                        </ButtonAlt>
                      </div>
                    )}
                    {!hideUnlockContactField && (
                      <div
                        className={classNames(
                          styles.emailContainer,
                          !isEmailAddressInputDisabled && styles.hiddenButton
                        )}
                      >
                        <ButtonAlt
                          size={'medium'}
                          variant="text"
                          icon={'lock'}
                          onClick={() => setIsEmailAddressInputDisabled(false)}
                        >
                          Unlock to edit
                        </ButtonAlt>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.professionalSummaryContainer} id={'professionalSummaryId'}>
              <div className={styles.title}>Professional Summary</div>
              <div className={styles.bioContainer}>
                <textarea
                  className={styles.bioInput}
                  maxLength={1500}
                  rows={4}
                  value={values.bio || ''}
                  onChange={(e) => setFieldValue('bio', e.target.value)}
                />
                <span className={styles.characterCount}>
                  {values.bio?.length || 0} / {1500} characters
                </span>
              </div>
            </div>
            <div className={styles.specialisationsContainer} id={'specialisationsContainerId'}>
              <div className={styles.specialisationsValueContainer}>
                {values.specialisations.map((specialisation) => (
                  <div className={styles.specialisation} key={specialisation}>
                    {specialisation}
                    <div
                      className={styles.iconContainer}
                      onClick={() => handleRemoveSpecialisation(specialisation, values, setFieldValue)}
                    >
                      <i className={`material-icons-outlined ${styles.icon}`}>close</i>
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.label}>Select specialisations</div>
              <CreatableSelect
                styles={{
                  container: (base: any) => ({ ...base, zIndex: 2 }),
                  valueContainer: (base: any) => ({ ...base, paddingLeft: 0 })
                }}
                options={specialisations.data.filter(
                  (specialisation) => !values.specialisations.includes(specialisation.value)
                )}
                placeholder="Search here"
                value={''}
                closeMenuOnSelect={false}
                // Value will always be { label: string; value: any } | undefined
                onChange={(value) =>
                  handleSpecialisationsChange(values, setFieldValue, value as { label: string; value: any } | undefined)
                }
              />
            </div>

            <div className={styles.registrationsContainer} id={'registrationsContainerId'}>
              <div className={styles.title}>Registration Details</div>
              <div className={styles.detailsContainer}>
                {values.registrations?.map((registration, index) => (
                  <div className={styles.editContainer} id={'registrationsContainerId'} key={registration._id}>
                    <div className={styles.label}>Registration {index + 1}</div>
                    <div className={styles.dropdownWrapper}>
                      <DropdownSearchable
                        error={errors.registrations?.[index]?.registerType}
                        hideErrorDesc
                        placeholder="Type"
                        options={REGISTRATION_TYPES_OPTIONS}
                        selected={registration.registerType}
                        disabled={REGISTRATION_TYPES_OPTIONS.length <= 1}
                        onSelect={(value) =>
                          handleRegistrationsChange(value, 'registerType', index, setFieldValue, validateForm)
                        }
                      />
                    </div>

                    <div className={styles.dropdownWrapper}>
                      <TitleInput
                        error={errors.registrations?.[index]?.registerNumber}
                        inputProps={{
                          placeholder: 'Registration Number',
                          value: registration.registerNumber,
                          onChange: (e: ChangeEvent<HTMLInputElement>) =>
                            handleRegistrationsChange(
                              e.target.value,
                              'registerNumber',
                              index,
                              setFieldValue,
                              validateForm
                            )
                        }}
                      />
                    </div>

                    <i
                      onClick={() =>
                        handleDeleteRegistrationButtonClick(registration._id, values, setFieldValue, validateForm)
                      }
                      className={`material-icons-outlined ${styles.itemIcon}`}
                    >
                      delete
                    </i>
                  </div>
                ))}

                <ButtonAlt
                  size={'medium'}
                  className={styles.addButton}
                  variant="text"
                  onClick={() => handleAddRegistrationButtonClick(values, setFieldValue)}
                  icon="add_circle_outline"
                >
                  Registration
                </ButtonAlt>
              </div>
            </div>

            <div className={styles.locationsContainer} id={'locationsContainerId'}>
              <div className={styles.title}>Set primary location for your consultations</div>
              {values.consultantLocations.map((location, index) => (
                <div className={styles.location} key={index}>
                  <FormikPlaceMaterialInput
                    name={`consultantLocations.${index}`}
                    label={`Location ${
                      values.consultantLocations.length > 0 && (index === 0 ? '(Primary)' : '(Secondary)')
                    }`}
                    placeholder={''}
                    containerClassName={styles.inputContainer}
                    required
                  />
                  <ButtonAlt
                    size={'medium'}
                    className={styles.removeButton}
                    variant="text"
                    onClick={() => handleConsultantLocationButtonClick(index, values, setFieldValue)}
                    icon={values.consultantLocations.length === 1 ? 'add' : 'close'}
                    fab={values.consultantLocations.length === 1 ? false : true}
                  >
                    {values.consultantLocations.length === 1 ? 'Add another location' : ''}
                  </ButtonAlt>
                </div>
              ))}
            </div>
            <div className={styles.buttonContainer}>
              <ButtonAlt
                onClick={() => {
                  validateRegistrationForm(validateForm);
                  submitForm();
                }}
                id={'saveProfileId'}
                status={saveButtonStatus}
              >
                Save
              </ButtonAlt>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default ProfileAboutYou;
