import React, { useState, useRef, useEffect, useCallback } from 'react';
import styles from './AudioRecorder.module.scss';
import { faMicrophone, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons';
import AudioVisualizer from './AudioVisualizer/AudioVisualizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import ButtonAlt, { ButtonTheme } from 'components/v2/ButtonAlt/ButtonAlt';

const countDownTimer = 60;

const AudioRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const chunks = useRef<BlobPart[]>([]); // For storing the recorded audio data
  const [audioURL, setAudioURL] = useState<string | null>(null); // URL for playback
  const isDiscarded = useRef(false);
  const [countdown, setCountdown] = useState(countDownTimer);

  const [isMediaSupported, setIsMediaSupported] = useState(true);
  const [isMicAccessible, setIsMicAccessible] = useState(true);

  // Function to check if MediaRecorder and microphone are supported and accessible
  const checkMediaSupport = useCallback(async () => {
    if (!window.MediaRecorder) {
      setIsMediaSupported(false);
      return;
    }

    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      setIsMicAccessible(true);
    } catch (error) {
      setIsMicAccessible(false); // Microphone access denied or not available
    }
  }, []);

  useEffect(() => {
    checkMediaSupport();
  }, [checkMediaSupport]);

  const startRecording = useCallback(async () => {
    try {
      setIsRecording(true);
      isDiscarded.current = false;
      setCountdown(countDownTimer);

      const newAudioContext = new AudioContext();
      setAudioContext(newAudioContext);

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);
      recorder.start();

      recorder.ondataavailable = (event) => {
        chunks.current.push(event.data);
      };

      recorder.onstop = () => {
        if (!isDiscarded.current) {
          const blob = new Blob(chunks.current, { type: 'audio/wav' });
          const url = URL.createObjectURL(blob);
          setAudioURL(url);
        }
        chunks.current = [];
      };
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  }, []);

  const stopRecording = useCallback(() => {
    setIsRecording(false);

    if (mediaRecorder) {
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      mediaRecorder.stop();
      setMediaRecorder(null);
    }
    if (audioContext) {
      audioContext.close();
      setAudioContext(null);
    }
  }, [mediaRecorder, audioContext]);

  const discardRecording = useCallback(() => {
    isDiscarded.current = true;
    stopRecording();
    setAudioURL(null);
  }, [stopRecording]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isRecording && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else if (countdown === 0) {
      stopRecording(); // Automatically stop recording when the countdown ends
    }
    return () => clearInterval(timer);
  }, [isRecording, countdown, stopRecording]);

  return !isMediaSupported ? (
    <div className={styles.notSupported}>
      <div className={styles.micWrapper}>
        <div className={styles.micButton}>
          <FontAwesomeIcon className={styles.micIcon} icon={faMicrophoneSlash} />
        </div>
      </div>
      <div className={styles.notSupportedLabel}>Voice note is not supported in this browser.</div>
    </div>
  ) : !isMicAccessible ? (
    <div className={styles.notSupported}>
      <div className={styles.micWrapper}>
        <div className={styles.micButton}>
          <FontAwesomeIcon className={styles.micIcon} icon={faMicrophoneSlash} />
        </div>
      </div>
      <div className={styles.notSupportedLabel}>
        Microphone access is denied. <br />
        Please enable it in your browser settings.
      </div>
    </div>
  ) : (
    <div className={isRecording ? styles.recording : styles.notRecording}>
      <div className={styles.micWrapper}>
        <div className={styles.visualizerWrapper}>
          <AudioVisualizer mediaRecorder={mediaRecorder} audioContext={audioContext} />
        </div>
        <button className={styles.micButton} onClick={isRecording ? stopRecording : startRecording}>
          <FontAwesomeIcon className={styles.micIcon} icon={faMicrophone} />
        </button>
      </div>
      <div className={styles.instructionWrapper}>
        {isRecording ? (
          <div className={styles.recordingInstructionWrapper}>
            <ButtonAlt error variant={'outlined'} icon={'delete_forever'} size={'small'} onClick={discardRecording}>
              Discard
            </ButtonAlt>
            <div className={styles.recordingLabelWrapper}>
              <div className={styles.recordingLabel}>RECORDING...</div>
              <div className={styles.recordCountDown}>{moment.utc(countdown * 1000).format('mm:ss')}s</div>
            </div>
            <ButtonAlt
              buttonTheme={ButtonTheme.User}
              variant={'outlined'}
              icon={'start'}
              size={'small'}
              onClick={stopRecording}
            >
              Done
            </ButtonAlt>
          </div>
        ) : (
          <div className={styles.instructionRecordLabel}>
            <div>Click to record a voice note as prompt input.</div>
            <div>Max length {countDownTimer} seconds</div>
          </div>
        )}
      </div>
      {!isRecording && audioURL && (
        <div style={{ paddingTop: '24px' }}>
          <audio controls src={audioURL} />
        </div>
      )}
    </div>
  );
};

export default AudioRecorder;
