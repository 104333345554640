import { PracticeInterface, ProfileInterface } from 'interfaces/Profile/Profile';
import { SALUTATION_OPTIONS } from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/ProfileAboutYou/ProfileAboutYou';
import { CPSTagTypes, clinicianProfileServicesApiSlice } from 'redux/services/clinicianProfileServicesApiSlice';
import { defaultCaseLoad, defaultWorkingSchedule } from 'redux/slice/profileDataSlice';

const massageClinicianProfileData = (response: ProfileInterface): ProfileInterface => {
  return {
    ...response,
    consultantLocations:
      response.consultantLocations && response.consultantLocations.length > 0 ? response.consultantLocations : [''],
    caseLoad: response.caseLoad ? response.caseLoad : defaultCaseLoad,
    workingSchedule: response.workingSchedule ? response.workingSchedule : defaultWorkingSchedule,
    title: response?.title
      ? SALUTATION_OPTIONS.find((obj) => obj.value === response.title)?.value !== undefined
        ? response.title
        : 'Other'
      : 'None',
    titleValue: response.title
  };
};

export const clinicianProfile = clinicianProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClinicianProfile: builder.query<ProfileInterface, void>({
      query: () => ({
        url: '/profile'
      }),
      providesTags: [CPSTagTypes.ClinicianProfile],
      transformResponse: (response: ProfileInterface) => massageClinicianProfileData(response)
    }),
    // Get clinician profile by id
    getClinicianProfileById: builder.query<ProfileInterface, { clinicianId: string }>({
      query: ({ clinicianId }) => ({
        url: `/clinicians/${clinicianId}/profile`
      }),
      providesTags: [CPSTagTypes.ClinicianProfileById],
      transformResponse: (response: ProfileInterface) => massageClinicianProfileData(response)
    }),
    getPracticeInfo: builder.query<PracticeInterface, { accountId: string }>({
      query: ({ accountId }) => ({
        url: `/accounts/${accountId}/practiceInfo`
      }),
      providesTags: [CPSTagTypes.PracticeInfo]
    }),
    getPublicPracticeInfo: builder.query<PracticeInterface, { accountIdOrSlugUrl: string }>({
      query: ({ accountIdOrSlugUrl }) => ({
        url: `/public/accounts/${accountIdOrSlugUrl}/practiceInfo`
      }),
      providesTags: [CPSTagTypes.PracticeInfo]
    }),
    updatePracticeInfo: builder.mutation({
      query: ({ accountId, payload }: { accountId: string; payload: PracticeInterface }) => ({
        url: `/accounts/${accountId}/practiceInfo`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: [CPSTagTypes.PracticeInfo]
    }),
    deletePracticeInfo: builder.mutation({
      query: ({ accountId }: { accountId: string }) => ({
        url: `/accounts/${accountId}/practiceInfo`,
        method: 'DELETE'
      }),
      invalidatesTags: [CPSTagTypes.PracticeInfo]
    })
  })
});

export const {
  useGetClinicianProfileQuery,
  useGetClinicianProfileByIdQuery,
  useGetPracticeInfoQuery,
  useGetPublicPracticeInfoQuery,
  useUpdatePracticeInfoMutation,
  useDeletePracticeInfoMutation
} = clinicianProfile;
