import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './InvoiceActionMenuV2.module.scss';
import ThreeDotMenuItem from 'components/T23/ThreeDotMenuItem/ThreeDotMenuItem';
import { Invoice, InvoiceStatus, InvoiceStatusToBeChange, OnChangeInvoiceStatus } from 'pages/Invoices/interface';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import classNames from 'classnames';
import InvoiceCardPayment from 'pages/InvoicesV2/components/InvoiceCardPayment/InvoiceCardPayment';
import RightSidebar from 'components/RightSidebar/RightSidebar';
import { useCurrency } from 'utils/hooks/useCurrency';
import { CreateOwedPaymentResponse, useCreateOwedPaymentMutation } from 'redux/endpoints/billingServices/invoices';
import { notification } from 'antd';
import { InvoiceWithType } from 'pages/InvoicesV2/components/InvoiceList/constants';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectFilters,
  setIsShowCreateClaimModal,
  setIsShowViewClaimModal,
  setSelectedClaim,
  setSelectedInvoice
} from 'redux/invoices/invoiceSlice';
import CreateClaimPeeking from 'components/CreateClaimPeeking/CreateClaimPeeking';
import { PendingClaimsFunder } from 'interfaces/invoices/pendingClaimInvoices';
import { getClaimInvoiceDetailsInfo } from 'pages/InvoicePendingClaims/utils';
import moment from 'moment';
import { toWord } from 'utils/general';
import { myProfileInfo } from 'redux/slice/profileDataSlice';

type InvoiceActions = 'changeStatus' | 'payment' | 'refund' | 'createClaim' | 'adjustTotal';

interface InvoiceActionMenuV2Props {
  invoice: InvoiceWithType;
  stripeAccountId: string | undefined;
  isInvoiceActionProcessing?: boolean;
  ableToCreateClaim?: boolean;
  isPendingClaimDataLoading?: boolean;
  isSubmittedClaimDataLoading?: boolean;
  onChangeStatus: OnChangeInvoiceStatus;
  groupInvoiceDetail?: Invoice['group'];
  minifiedButton?: boolean;
  isExistLocationWithType?: boolean;
  onPartialRefund?: () => void;
  onFullRefund?: () => void;
  onUpdateBalance?: () => void;
  onWriteOff?: () => void;
  onCreateClaim?: () => void;
  onMatchMBSBenefit?: () => void;
  onManualAdjustTotal?: () => void;
}

// eslint-disable-next-line
const InvoiceActionMenuV2 = ({
  invoice,
  stripeAccountId,
  isInvoiceActionProcessing,
  isExistLocationWithType,
  isPendingClaimDataLoading,
  isSubmittedClaimDataLoading,
  onChangeStatus,
  groupInvoiceDetail,
  minifiedButton,
  onPartialRefund,
  onFullRefund,
  onUpdateBalance,
  onWriteOff,
  onCreateClaim,
  onMatchMBSBenefit,
  onManualAdjustTotal
}: InvoiceActionMenuV2Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: clinicianProfile } = useAppSelector(myProfileInfo);
  const { formatCurrency, CURRENCY_SYMBOL } = useCurrency();
  const { INVOICES } = useRoutesGenerator();
  const [createOwedPayment] = useCreateOwedPaymentMutation();
  const { isEdgeAdminView, isEdgeUserView } = useGetAccountPackageView();
  const filters = useAppSelector(selectFilters);

  const menuNode = useRef<HTMLDivElement>(null);
  const changeStatusSubMenu = useRef<HTMLDivElement>(null);
  const paymentSubMenu = useRef<HTMLDivElement>(null);
  const refundSubMenu = useRef<HTMLDivElement>(null);
  const createClaimSubMenu = useRef<HTMLDivElement>(null);
  const adjustTotalSubMenu = useRef<HTMLDivElement>(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const [changeStatusExpand, setChangeStatusExpand] = useState(false);
  const [paymentExpand, setPaymentExpand] = useState(false);
  const [refundExpand, setRefundExpand] = useState(false);
  const [showCardPayment, setShowCardPayment] = useState(false);
  const [createClaimExpand, setCreateClaimExpand] = useState(false);
  const [adjustTotalExpand, setAdjustTotalExpand] = useState(false);

  const {
    _id,
    status,
    paid,
    owed = 0,
    paymentRequests,
    clinician,
    accountId,
    clientRecord,
    invoiceType,
    funder,
    mbsCode,
    isClaimReady,
    foundAppointment,
    foundClinician,
    foundProvider,
    foundClientRecord,
    foundGeneralPractitioner
  } = invoice;
  const { participantType } = filters;

  const paymentAmount = paid?.payments || 0;
  const handleCloseMenu = () => {
    setIsExpanded(false);
    setChangeStatusExpand(false);
    setPaymentExpand(false);
    setRefundExpand(false);
    setCreateClaimExpand(false);
    setAdjustTotalExpand(false);
  };

  const handleClickOutSide = (event: any) => {
    if (
      menuNode.current?.contains(event.target) ||
      changeStatusSubMenu.current?.contains(event.target) ||
      paymentSubMenu.current?.contains(event.target) ||
      refundSubMenu.current?.contains(event.target) ||
      createClaimSubMenu.current?.contains(event.target) ||
      adjustTotalSubMenu.current?.contains(event.target)
    ) {
      return;
    }
    handleCloseMenu();
    closeCardPayment();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  const handleExpandMenu = () => {
    setIsExpanded(!isExpanded);
    closeAllExpandedMenu();
  };

  const closeAllExpandedMenu = () => {
    if (changeStatusExpand) {
      setChangeStatusExpand(false);
    }
    if (paymentExpand) {
      setPaymentExpand(false);
    }
    if (refundExpand) {
      setRefundExpand(false);
    }
    if (adjustTotalExpand) {
      setAdjustTotalExpand(false);
    }
    if (createClaimExpand) {
      setCreateClaimExpand(false);
    }
  };

  const handleExpandSubMenu = (item: InvoiceActions) => {
    closeAllExpandedMenu();
    switch (item) {
      case 'changeStatus': {
        setChangeStatusExpand(!changeStatusExpand);
        break;
      }
      case 'payment': {
        setPaymentExpand(!paymentExpand);
        break;
      }
      case 'refund': {
        setRefundExpand(!refundExpand);
        break;
      }
      case 'createClaim': {
        setCreateClaimExpand(!createClaimExpand);
        break;
      }
      case 'adjustTotal': {
        setAdjustTotalExpand(!adjustTotalExpand);
        break;
      }
      default:
        break;
    }
  };

  const handleChangeStatus = (status: InvoiceStatusToBeChange, isRecreate?: boolean) => {
    handleCloseMenu();
    onChangeStatus(_id, status, groupInvoiceDetail, isRecreate);
  };

  const handlePartialRefund = () => {
    handleCloseMenu();
    onPartialRefund && onPartialRefund();
  };

  const handleFullRefund = () => {
    handleCloseMenu();
    onFullRefund && onFullRefund();
  };

  const handleUpdateBalance = () => {
    handleCloseMenu();
    onUpdateBalance && onUpdateBalance();
  };

  const handleWriteOff = () => {
    handleCloseMenu();
    onWriteOff && onWriteOff();
  };

  const handleCreateClaim = () => {
    handleCloseMenu();
    onCreateClaim && onCreateClaim();
  };

  const handleMatchMBSBenefit = () => {
    handleCloseMenu();
    onMatchMBSBenefit && onMatchMBSBenefit();
  };

  const handleManualAdjustTotal = () => {
    handleCloseMenu();
    onManualAdjustTotal && onManualAdjustTotal();
  };

  const isChangeStatusDisabled = [
    InvoiceStatus.Draft,
    InvoiceStatus.Voided,
    InvoiceStatus.WriteOff,
    InvoiceStatus.ConfirmPaid
  ].includes(status);

  const isWriteOffDisabled = [
    InvoiceStatus.Draft,
    InvoiceStatus.Voided,
    InvoiceStatus.WriteOff,
    InvoiceStatus.Closed,
    InvoiceStatus.ClaimComplete,
    InvoiceStatus.ClaimSuccess
  ].includes(status);

  const isPaymentDisabledByStatus = [
    InvoiceStatus.Voided,
    InvoiceStatus.Draft,
    InvoiceStatus.Closed,
    InvoiceStatus.WriteOff
  ].includes(status);
  const allowPartialRefund = !!paymentRequests && paymentRequests.length > 0;
  const canChangeStatus = !(isEdgeUserView && clinicianProfile?._id !== clinician?._id);
  const isDraft = status === InvoiceStatus.Draft;
  const viewInvoicePath = isDraft ? `${INVOICES.BASE}/${_id}/edit` : `${INVOICES.BASE}/${_id}`;
  const hasSavedCard = !!clientRecord?.integrations?.stripePaymentMethod?.id;
  const card = clientRecord?.integrations?.stripePaymentMethod?.card;
  const isClientPaymentEnabled = !!(
    participantType === ParticipantType.OneToOne &&
    clientRecord?._id &&
    stripeAccountId
  );

  const { isDvaClient, isMedicareClient } = useMemo(
    () => getClaimInvoiceDetailsInfo(foundClientRecord, foundGeneralPractitioner),
    [foundClientRecord, foundGeneralPractitioner]
  );
  const isPendingClaimInvoice = InvoiceStatus.PendingClaim === invoice.status;
  const isMissingMedicareDetail = useMemo(
    () => !((isMedicareClient || isDvaClient) && (!isDvaClient || isExistLocationWithType)),
    [isMedicareClient, isDvaClient, isExistLocationWithType]
  );
  const isFutureAppointment = useMemo(() => moment(foundAppointment?.date).isAfter(moment()), [foundAppointment?.date]);

  const onChargeOwedAmount = async () => {
    try {
      handleCloseMenu();
      notification.warning({
        message: 'Processing card payment...'
      });

      await createOwedPayment({
        accountId,
        invoiceId: _id
      }).unwrap();

      notification.destroy();
      notification.success({
        message: 'Payment success'
      });
    } catch (ex) {
      console.error(ex);
      notification.destroy();
      const error = ex as { data: CreateOwedPaymentResponse };

      notification.destroy();
      notification.error({
        message: `Payment failed. ${
          error?.data?.message || 'Please try again, if failures persist please contact us to investigate.'
        }`
      });
    }
  };

  const closeCardPayment = () => {
    setShowCardPayment(false);
  };

  const handleEditClaim = () => {
    handleCloseMenu();
    dispatch(setSelectedInvoice(invoice));
    dispatch(setIsShowCreateClaimModal(true));
  };

  const handleViewClaim = () => {
    handleCloseMenu();
    dispatch(setSelectedClaim(invoice.submittedClaim));
    dispatch(setIsShowViewClaimModal(true));
  };

  const cardBrandSrc = useMemo(() => {
    switch (card?.brand) {
      case 'visa':
        return 'visa.png';
      case 'mastercard':
        return 'mastercard.png';
      case 'discover':
        return 'discover.jpg';
      case 'jcb':
        return 'jcb.png';
      case 'eftpos':
        return 'eftpos.png';
      case 'amex':
        return 'amex.png';
      case 'diners':
        return 'diners.png';
      case 'unionpay':
        return 'unionpay.png';
      default:
        return '';
    }
  }, [card?.brand]);

  return (
    <div ref={menuNode} className={styles.container}>
      {minifiedButton ? (
        <ButtonAlt size="medium" variant="text" fab icon="more_vert" onClick={handleExpandMenu} />
      ) : (
        <ButtonAlt
          size="medium"
          fullWidth
          icon="arrow_drop_down"
          iconPostFix
          variant="outlined"
          onClick={handleExpandMenu}
          status={isInvoiceActionProcessing ? 'active' : ''}
          loadingWord="Processing"
        >
          Select...
        </ButtonAlt>
      )}
      <div className={styles.menuWrapper}>
        <div className={isExpanded ? styles.menuShow : styles.menuHide}>
          {/* View Invoice */}
          <div className={styles.menuItem}>
            <ThreeDotMenuItem
              label={`${isDraft ? 'Edit' : 'View'} Invoice`}
              onClick={() => navigate(viewInvoicePath, isDraft ? { state: { invoice } } : undefined)}
            />
          </div>

          {/* Download Invoice */}
          {/* <div className={styles.menuItem}>
            <ThreeDotMenuItem label="Download Invoice" onClick={() => {}} />
          </div> */}

          {/* Change status */}
          {canChangeStatus && (
            <div className={styles.menuItem}>
              <ThreeDotMenuItem
                id={`disabledStatusAction_${_id}`}
                label="Change status"
                onClick={() => {
                  handleExpandSubMenu('changeStatus');
                }}
                postIcon="navigate_next"
                disabled={isChangeStatusDisabled}
                tooltipContent={`Unable to change status from a ${toWord(status)} invoice`}
              />

              {/* Change status sub menu */}
              <div
                ref={changeStatusSubMenu}
                className={classNames(
                  styles.mediumSubMenu,
                  changeStatusExpand ? styles.subMenuShow : styles.subMenuHide
                )}
              >
                <ThreeDotMenuItem
                  id={`disabledChangeToPaidAction_${_id}`}
                  label="Paid"
                  disabled={[
                    InvoiceStatus.PendingClaim,
                    InvoiceStatus.ClaimSuccess,
                    InvoiceStatus.ClaimComplete,
                    InvoiceStatus.ClaimRejected
                  ].includes(status)}
                  tooltipContent={`Unable to change status to Paid from a ${toWord(status)} invoice`}
                  onClick={() => handleChangeStatus(InvoiceStatus.ConfirmPaid)}
                />
                {status !== InvoiceStatus.Closed && (
                  <ThreeDotMenuItem label="Closed" onClick={() => handleChangeStatus(InvoiceStatus.Closed)} />
                )}
                <ThreeDotMenuItem
                  id={`disabledChangeToVoidAction_${_id}`}
                  disabled={paymentAmount !== 0}
                  label="Voided"
                  onClick={() => handleChangeStatus(InvoiceStatus.Voided)}
                  tooltipContent="Unable to void an invoice with payment collected"
                />
                <ThreeDotMenuItem
                  id={`disabledChangeToVoidAndCreateAction_${_id}`}
                  disabled={paymentAmount !== 0}
                  label="Void and Recreate"
                  onClick={() => handleChangeStatus(InvoiceStatus.Voided, true)}
                  tooltipContent="Unable to void an invoice with payment collected"
                />
              </div>
            </div>
          )}

          {/* Payment */}
          {isEdgeAdminView && (
            <div className={styles.menuItem}>
              <ThreeDotMenuItem
                id={`disabledPaymentAction_${_id}`}
                label="Payment"
                postIcon="navigate_next"
                disabled={isPaymentDisabledByStatus || invoiceType === PendingClaimsFunder.BULK_BILL}
                tooltipContent={
                  isPaymentDisabledByStatus
                    ? `Unable to collect payment from a ${toWord(status)} invoice`
                    : invoiceType === PendingClaimsFunder.BULK_BILL
                    ? 'Bulk Bill invoice can only be paid via claim process'
                    : ''
                }
                onClick={() => {
                  handleExpandSubMenu('payment');
                }}
              />

              {/* Payment sub menu */}
              <div
                ref={paymentSubMenu}
                className={classNames(styles.wideSubMenuShow, paymentExpand ? styles.subMenuShow : styles.subMenuHide)}
              >
                <ThreeDotMenuItem
                  id={`disabledChargeCardOnFileAction_${_id}`}
                  label="Charge Card on File:"
                  className={styles.savedCard}
                  labelClassName={styles.cardLabel}
                  postLabelComponent={
                    <span className={classNames(styles.postLabel, hasSavedCard ? '' : styles.disabled)}>
                      {formatCurrency(owed)}
                    </span>
                  }
                  postComponent={
                    <>
                      {hasSavedCard && card && (
                        <div className={styles.cardInfo}>
                          <i className={`material-icons ${styles.icon}`}>credit_card</i>
                          {cardBrandSrc && (
                            <img src={`/card-brands/${cardBrandSrc}`} className={styles.cardBrand} alt={card.brand} />
                          )}
                          <div className={styles.cardNumber}>••••{card.last4}</div>
                        </div>
                      )}
                    </>
                  }
                  onClick={onChargeOwedAmount}
                  disabled={owed <= 0 || !hasSavedCard || !isClientPaymentEnabled}
                  tooltipContent={
                    owed <= 0
                      ? 'Invoice is fully paid'
                      : !hasSavedCard
                      ? "Client doesn't have a saved payment method"
                      : !isClientPaymentEnabled
                      ? 'Payment function is not enabled'
                      : ''
                  }
                />
                <ThreeDotMenuItem
                  id={`disabledTakeACardPaymentAction_${_id}`}
                  label="Take a Card Payment"
                  postIcon="menu_open"
                  onClick={() => {
                    setShowCardPayment(!showCardPayment);
                  }}
                  disabled={owed <= 0 || !isClientPaymentEnabled}
                  tooltipContent={
                    owed <= 0
                      ? 'Invoice is fully paid'
                      : !isClientPaymentEnabled
                      ? 'Payment function is not enabled'
                      : ''
                  }
                />
                <ThreeDotMenuItem label="Update Balance" onClick={handleUpdateBalance} />
              </div>
            </div>
          )}

          {/* Refund */}
          {isEdgeAdminView && (
            <div className={styles.menuItem}>
              <ThreeDotMenuItem
                id={`disabledRefundAction_${_id}`}
                labelClassName={styles.menuItemLabel}
                label="Refund"
                postIcon="navigate_next"
                onClick={() => handleExpandSubMenu('refund')}
                disabled={paymentAmount === 0 || isPaymentDisabledByStatus || !isClientPaymentEnabled}
                tooltipContent={
                  isPaymentDisabledByStatus
                    ? `Unable to issue refund from a ${toWord(status)} invoice`
                    : paymentAmount === 0
                    ? 'Payment has not been collected'
                    : !isClientPaymentEnabled
                    ? 'Payment function is not enabled'
                    : ''
                }
              />
              {/* Refund sub menu */}
              <div
                ref={refundSubMenu}
                className={classNames(styles.wideSubMenuShow, refundExpand ? styles.subMenuShow : styles.subMenuHide)}
              >
                <ThreeDotMenuItem
                  label="Full Refund:"
                  labelClassName={styles.menuItemLabel}
                  postComponent={<span className={styles.postLabel}>{formatCurrency(paymentAmount)}</span>}
                  onClick={handleFullRefund}
                />
                <ThreeDotMenuItem
                  id={`disabledPartialRefundAction_${_id}`}
                  label="Partial Refund"
                  labelClassName={styles.menuItemLabel}
                  disabled={!allowPartialRefund}
                  onClick={handlePartialRefund}
                  tooltipContent="Payment has not been collected"
                />
              </div>
            </div>
          )}

          {/* Write off */}
          {isEdgeAdminView && (
            <div className={styles.menuItem}>
              <ThreeDotMenuItem
                id={`disabledWriteOffAction_${_id}`}
                labelClassName={styles.menuItemLabel}
                label="Write Off"
                onClick={handleWriteOff}
                disabled={owed === 0 || isWriteOffDisabled}
                tooltipContent={
                  isWriteOffDisabled
                    ? `Unable to write off a ${toWord(status)} invoice`
                    : owed === 0
                    ? 'Unable to write off a fully paid invoice'
                    : ''
                }
              />
            </div>
          )}

          {/* Create Claim */}
          {invoiceType === PendingClaimsFunder.BULK_BILL && (
            <div className={styles.menuItem}>
              <>
                <ThreeDotMenuItem
                  id={`disableCreateClaimAction_${_id}`}
                  isActive={createClaimExpand}
                  disabled={
                    !(isPendingClaimInvoice && !isMissingMedicareDetail && !isFutureAppointment) ||
                    isPendingClaimDataLoading
                  }
                  isLoading={isPendingClaimDataLoading}
                  label="Create Claim"
                  onClick={() => {
                    handleExpandSubMenu('createClaim');
                  }}
                  postIcon="navigate_next"
                  tooltipContent={
                    isPendingClaimDataLoading
                      ? ''
                      : [InvoiceStatus.ClaimComplete, InvoiceStatus.ClaimSuccess, InvoiceStatus.ClaimRejected].includes(
                          status
                        )
                      ? 'Claim already successfully processed'
                      : !isPendingClaimInvoice
                      ? 'Invoice is not in Pending Claim status'
                      : isMissingMedicareDetail
                      ? 'Missing client Medicare details'
                      : isFutureAppointment
                      ? 'Unable to create claim for future appointment'
                      : ''
                  }
                />
                <div
                  ref={createClaimSubMenu}
                  className={classNames(
                    styles.wideSubMenuShow,
                    createClaimExpand ? styles.subMenuShow : styles.subMenuHide
                  )}
                >
                  <CreateClaimPeeking
                    appointmentStartDateTime={foundAppointment?.startDateTime || ''}
                    clinicianName={foundClinician?.name}
                    funder={funder}
                    mbsCode={mbsCode}
                    providerNumber={foundProvider?.providerNumber}
                    isClaimReady={!!isClaimReady}
                    onSubmit={handleCreateClaim!}
                    onEdit={handleEditClaim}
                  />
                </div>
              </>
            </div>
          )}

          {/* View Claim */}
          {invoiceType === PendingClaimsFunder.BULK_BILL &&
            [InvoiceStatus.ClaimComplete, InvoiceStatus.ClaimSuccess, InvoiceStatus.ClaimRejected].includes(status) && (
              <div className={styles.menuItem}>
                <ThreeDotMenuItem
                  id={`disabledViewClaim_${_id}`}
                  label="View Claim"
                  isLoading={isSubmittedClaimDataLoading}
                  disabled={!invoice.submittedClaim || isSubmittedClaimDataLoading}
                  onClick={handleViewClaim}
                  postIcon="open_in_browser"
                  tooltipContent="No claim has been submitted"
                />
              </div>
            )}

          {/* Adjust Total */}
          {isEdgeAdminView && (
            <div className={styles.menuItem}>
              <ThreeDotMenuItem
                id={`disableAdjustAmount_${_id}`}
                labelClassName={styles.menuItemLabel}
                label={`Adjust ${CURRENCY_SYMBOL} Total`}
                postIcon="navigate_next"
                onClick={() => handleExpandSubMenu('adjustTotal')}
                disabled={InvoiceStatus.Draft === status}
                tooltipContent={`Unable to adjust invoice amount from a ${toWord(InvoiceStatus.Draft)} invoice`}
              />
              {/* Adjust Total sub menu */}
              <div
                ref={adjustTotalSubMenu}
                className={classNames(
                  styles.mediumSubMenu,
                  adjustTotalExpand ? styles.subMenuShow : styles.subMenuHide
                )}
              >
                <ThreeDotMenuItem
                  id={`disableMatchMBSBenefit_${_id}`}
                  label="Match MBS Benefit"
                  disabled={
                    isSubmittedClaimDataLoading ||
                    ![InvoiceStatus.ClaimComplete, InvoiceStatus.ClaimSuccess, InvoiceStatus.ClaimRejected].includes(
                      status
                    )
                  }
                  labelClassName={styles.menuItemLabel}
                  isLoading={
                    [InvoiceStatus.ClaimComplete, InvoiceStatus.ClaimSuccess, InvoiceStatus.ClaimRejected].includes(
                      status
                    ) && isSubmittedClaimDataLoading
                  }
                  onClick={handleMatchMBSBenefit}
                  tooltipContent="No claim has been submitted"
                />
                <ThreeDotMenuItem
                  label="Manual Change"
                  labelClassName={styles.menuItemLabel}
                  onClick={handleManualAdjustTotal}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {showCardPayment && stripeAccountId && (
        <RightSidebar title="Pay with Card" setHide={closeCardPayment}>
          <InvoiceCardPayment stripeAccountId={stripeAccountId} invoice={invoice} closePayment={closeCardPayment} />
        </RightSidebar>
      )}
    </div>
  );
};

export default InvoiceActionMenuV2;
