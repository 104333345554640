import { useAuth0 } from '@auth0/auth0-react';
import { createContext, ReactNode, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { security } from 'utils/security';

const SubTabContext = createContext<{
  showWorkspace: boolean;
  setShowWorkspace: (v: boolean) => void;
  showSubTab: boolean;
  setShowSubTab: (v: boolean) => void;
  showCreateNewReportModal: boolean;
  setShowCreateNewReportModal: (v: boolean) => void;
  sendAdhocAssessmentVisible: boolean;
  setSendAdhocAssessmentVisible: (v: boolean) => void;
  selectedClientProfileId: string;
  setSelectedClientProfileId: (id: string) => void;
}>({
  showWorkspace: false,
  setShowWorkspace: () => {},
  showSubTab: false,
  setShowSubTab: () => {},
  showCreateNewReportModal: false,
  setShowCreateNewReportModal: () => {},
  sendAdhocAssessmentVisible: false,
  setSendAdhocAssessmentVisible: () => {},
  selectedClientProfileId: '',
  setSelectedClientProfileId: () => {}
});

export { SubTabContext };

const SubTabContextProvider = ({ children }: { children: ReactNode }) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [showWorkspace, setShowWorkspace] = useState(false);
  const [showSubTab, setShowSubTab] = useState(false);
  const [showCreateNewReportModal, setShowCreateNewReportModal] = useState(false);
  const [showSendFormModal, setShowSendFormModal] = useState(false);
  const [selectedClientProfileId, setSelectedClientProfileId] = useState('');

  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  security.setGetAccessTokenSilently(() =>
    getAccessTokenSilently({ audience: process.env.REACT_APP_API_AUDIENCE }).catch(() =>
      getAccessTokenWithPopup({ audience: process.env.REACT_APP_API_AUDIENCE }).catch(() => '')
    )
  );

  const location = useLocation();
  const { HELM_CONTENT, ANALYTICS, VALION_PROGRAM_CONTENT } = useRoutesGenerator();

  useEffect(() => {
    if ([HELM_CONTENT.BASE, ANALYTICS.BASE, VALION_PROGRAM_CONTENT.BASE].every((path) => location.pathname !== path)) {
      if (firstLoad) {
        setShowSubTab(true);
        setFirstLoad(false);
      }
    } else {
      setShowSubTab(false);
    }
  }, [location.pathname, HELM_CONTENT.BASE, ANALYTICS.BASE, VALION_PROGRAM_CONTENT.BASE, firstLoad]);

  return (
    <SubTabContext.Provider
      value={{
        showWorkspace: showWorkspace,
        setShowWorkspace: setShowWorkspace,
        showSubTab: showSubTab,
        setShowSubTab: setShowSubTab,
        showCreateNewReportModal: showCreateNewReportModal,
        setShowCreateNewReportModal: setShowCreateNewReportModal,
        sendAdhocAssessmentVisible: showSendFormModal,
        setSendAdhocAssessmentVisible: setShowSendFormModal,
        selectedClientProfileId: selectedClientProfileId,
        setSelectedClientProfileId: setSelectedClientProfileId
      }}
    >
      {children}
    </SubTabContext.Provider>
  );
};
export default SubTabContextProvider;
