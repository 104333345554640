import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import { useParams } from 'react-router-dom';

import ControlPanelContent from './components/ControlPanelContent/ControlPanelContent';
import ControlPanelHeader from './components/ControlPanelHeader/ControlPanelHeader';
import styles from './ControlPanel.module.scss';
import { clinicianProfileServicesApiSlice, CPSTagTypes } from 'redux/services/clinicianProfileServicesApiSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { myProfileInfo } from 'redux/slice/profileDataSlice';
import LoadingDot from 'components/LoadingDot/LoadingDot';

const ControlPanel = () => {
  const dispatch = useDispatch();
  const { controlPanelTab = '' } = useParams<{ controlPanelTab?: string }>();
  const { data: myProfileData, isLoading } = useAppSelector(myProfileInfo);

  return (
    <HelmetWrapper title="Tacklit - Control Panel">
      <ContentLayout className={styles.container}>
        <div className={styles.container}>
          {isLoading ? (
            <div className={styles.loadingWrapper}>
              <LoadingDot />
            </div>
          ) : (
            <>
              <ControlPanelHeader tab={controlPanelTab} practitionerName={myProfileData?.name} />
              <ControlPanelContent
                profile={myProfileData}
                controlPanelTab={controlPanelTab}
                onUpdateProfile={() => {
                  dispatch(clinicianProfileServicesApiSlice.util.invalidateTags([CPSTagTypes.ClinicianProfile]));
                }}
              />
            </>
          )}
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default ControlPanel;
