import { useContext, useEffect, useRef, useState } from 'react';
import { notification } from 'antd';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';

import PatientDetailsReportsHeader from './components/PatientDetailsReportsHeader/PatientDetailsReportsHeader';
import styles from './PatientDetailsReports.module.scss';
import { useGetAccessToken } from 'utils/hooks/token';
import { downloadReportPdf, getReport, getReportByAdmin } from 'utils/http/DocumentService/Reports/reports';
import { Report } from 'interfaces/Reports/report';
import ReportClientTabPreview from '../../../../../Report/ReportClientTabPreview/ReportClientTabPreview';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import Select from 'components/v2/Select/Select';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetAccountPackage } from 'utils/hooks/GetAccountInfo/accountPackage';
import { IOptionItem } from 'components/v2/DropdownSearchable/OptionItem';
import { useAppSelector } from 'redux/hooks';
import { selectIsFetchingEpisodes, selectIsShowAllData } from 'redux/episodes/episodeSlice';
import { useFetchReportList } from '../../hooks/actions';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import ReportContentAsPDF from 'pages/Report/components/ReportContentAsPDF/ReportContentAsPDF';
import { generatePrintableReportPayload, triggerDownload } from 'pages/Report/utils';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import REPORTBUILDERIMG from 'assets/images/reportBuilderImg.png';
import { useTranslation } from 'react-i18next';
import { SubTabContext } from '../../../../../../layouts/AuthLayoutT23/context/SubTabContextProvider';
import ReportCreateNewModal from '../../../../../Report/ReportsList/components/ReportCreateNewModal/ReportCreateNewModal';
import { myProfileInfo } from 'redux/slice/profileDataSlice';

interface PatientDetailsReportsProps {
  recordId: string;
  currentTab: string;
  reportId?: string;
  clientRecordData: clientRecordsInterface;
}

const PatientDetailsReports = ({ recordId, currentTab, reportId, clientRecordData }: PatientDetailsReportsProps) => {
  const [t] = useTranslation();
  const { token } = useGetAccessToken();
  const { CLIENTS } = useRoutesGenerator();
  const { isEdgePlanUser } = useGetAccountPackage();
  const [isLoadingReportData, setIsLoadingReportData] = useState(true);
  const [reportData, setReportData] = useState<Report>();
  const [selectOptions, setSelectOptions] = useState<IOptionItem[]>([]);
  const [selectValue, setSelectValue] = useState<IOptionItem | undefined>();
  const [selectedReportId, setSelectedReportId] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const { data: clinicianProfile } = useAppSelector(myProfileInfo);
  const { accountId } = useGetAccountId();

  const isFetchingEpisodes = useAppSelector(selectIsFetchingEpisodes);
  const isShowAllData = useAppSelector(selectIsShowAllData);
  const { isEoCEnabled } = useGetFeatureToggle();

  const [downloadPDFButtonStatus, setDownloadPDFButtonStatus] = useState<ButtonStatusType>('');
  const reportRef = useRef(null);

  const { reportList, isReportListLoading, isFetchingReportList } = useFetchReportList(token, recordId);

  useEffect(() => {
    //Prevent showing old report detail when changing episode
    if (isEoCEnabled && !isShowAllData && reportList[0]?._id) {
      navigate(`${CLIENTS.BASE}/${recordId}/report/${reportList[0]._id}`);
      return;
    }
    if (!reportId && !isReportListLoading && !isFetchingReportList && reportList && reportList[0]?._id) {
      navigate(`${CLIENTS.BASE}/${recordId}/report/${reportList[0]._id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportList, isReportListLoading, isFetchingReportList]);

  useEffect(() => {
    setSelectedReportId('');
    setSelectOptions([]);
    setSelectValue(undefined);
  }, [isFetchingEpisodes, isFetchingReportList, isReportListLoading]);

  const noReportId = !isReportListLoading && !isFetchingReportList && reportList.length !== 0 ? reportList[0]._id : '';
  const noReports = !isReportListLoading && !isFetchingReportList && reportList?.length === 0;

  const getReportId: string = isEdgePlanUser
    ? location.pathname.split('/')[5] ?? noReportId
    : location.pathname.split('/')[4] ?? noReportId;

  const fetchReportDetails = async (patientReportId: string) => {
    if (patientReportId) {
      setIsLoadingReportData(true);

      try {
        const getReportRes =
          isEdgeAdminView || isEdgeReceptionist
            ? await getReportByAdmin(token, patientReportId)
            : await getReport(token, patientReportId);
        const report = await getReportRes.json();
        setReportData({
          ...report,
          clientRecord: clientRecordData
        });
        setIsLoadingReportData(false);
      } catch (ex) {
        notification.error({ message: 'Something went wrong while trying to get this reports detail' });
      }
    } else {
      setIsLoadingReportData(false);
    }
  };

  useEffect(() => {
    const id = reportId || '';
    if (token) {
      fetchReportDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId, token, recordId]);

  useEffect(() => {
    if (!reportList?.length || isFetchingReportList) {
      return;
    }
    const itemLabel = reportList.find((i) => i._id === getReportId);
    if (itemLabel) {
      setSelectedReportId(itemLabel.reportName);
    }
    setSelectOptions(
      reportList
        ?.filter((item, idx, arr) => arr.findIndex((i) => i.reportName === item.reportName) === idx)
        ?.map((i) => ({ value: i._id, label: i.reportName }))
    );

    setSelectValue(
      reportList.length
        ? selectedReportId !== ''
          ? {
              value: reportList.find((i) => i.reportName === selectedReportId)?._id || '',
              label: selectedReportId
            }
          : reportList[0]
          ? { value: reportList[0]._id, label: reportList[0].reportName }
          : undefined
        : undefined
    );
  }, [reportList, getReportId, setSelectedReportId, selectedReportId, isFetchingReportList]);

  const { showCreateNewReportModal, setShowCreateNewReportModal } = useContext(SubTabContext);

  const downloadPdf = async () => {
    if (reportId && reportData) {
      setDownloadPDFButtonStatus('active');
      try {
        const retrievedPDF = await downloadReportPdf(
          accountId,
          token,
          reportId,
          !reportData.attachment
            ? {
                printPayload: generatePrintableReportPayload(reportRef.current) || ''
              }
            : undefined
        );
        if (retrievedPDF) {
          triggerDownload(retrievedPDF, `Report_${reportId}`);
        }
        setDownloadPDFButtonStatus('finished');
        notification.success({
          closeIcon: <span className="success">OK</span>,
          duration: 2,
          message: 'Download report as PDF successful.'
        });
      } catch (ex) {
        notification.error({
          closeIcon: <span className="success">OK</span>,
          duration: 2,
          message: 'Fail to download report as PDF.'
        });
      } finally {
        setTimeout(() => {
          setDownloadPDFButtonStatus('');
        }, 1000);
      }
    }
  };

  return (
    <>
      <div className={styles.headerContainer}>
        {!noReports && (
          <>
            <div>
              <Select
                filledArrow
                label="REPORTS"
                options={selectOptions}
                onChange={(val: any) => {
                  if (val?.label !== selectedReportId) {
                    setSelectedReportId(val?.label);
                    navigate(`${CLIENTS.BASE}/${recordId}/report/${val?.value}`);
                  }
                }}
                isSearchable={false}
                //@ts-ignore
                value={selectValue}
                isDisabled={isReportListLoading && isFetchingReportList}
                isLoading={isReportListLoading || isFetchingReportList}
                containerClass={styles.selectReportId}
                controlStyle={{ boxShadow: 'none' }}
              />
            </div>
            <ButtonAlt
              variant="outlined"
              icon="file_download"
              onClick={downloadPdf}
              className={styles.downloadButton}
              status={downloadPDFButtonStatus}
              disabled={!reportData || isLoadingReportData}
              loadingWord="Preparing PDF"
            >
              Download as PDF
            </ButtonAlt>
          </>
        )}
      </div>
      <div className={styles.container}>
        {isReportListLoading || isFetchingReportList ? (
          <div className={styles.loading}>
            <LoadingCircle />
          </div>
        ) : (
          <>
            {reportId && reportList?.length > 0 ? (
              <>
                <PatientDetailsReportsHeader
                  recordId={recordId}
                  currentTab={currentTab}
                  reportId={reportId}
                  reportList={reportList}
                />
                {clinicianProfile && reportData && (
                  <ReportContentAsPDF
                    type="report"
                    reportDetails={reportData}
                    practice={clinicianProfile?.practice}
                    ref={reportRef}
                  />
                )}
                {isLoadingReportData || isReportListLoading ? (
                  <div className={styles.loading}>
                    <LoadingCircle />
                  </div>
                ) : (
                  reportData && <ReportClientTabPreview reportData={reportData} />
                )}
              </>
            ) : (
              <div className={styles.noDataWrapper} id={'noReportListingId'}>
                <img className={styles.image} alt={'no report img'} src={REPORTBUILDERIMG} />
                <div className={styles.infoWrapper}>
                  <div className={styles.text}>{t('label.no_report_for_any_client')}</div>
                  <ButtonAlt
                    size={'medium'}
                    variant="outlined"
                    onClick={() => setShowCreateNewReportModal(true)}
                    icon="add_circle_outline"
                  >
                    Create New Report
                  </ButtonAlt>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <ReportCreateNewModal
        isShowCreateNewModal={showCreateNewReportModal}
        onCloseModal={() => setShowCreateNewReportModal(false)}
      />
    </>
  );
};

export default PatientDetailsReports;
