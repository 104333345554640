import { notification } from 'antd';
import classNames from 'classnames';
import CheckBox from 'components/CheckBox/CheckBox';
import Chip from 'components/Chip/Chip';
import Radio from 'components/Radio/Radio';
import ToggleSwitchV2 from 'components/ToggleSwitchV2/ToggleSwitchV2';
import ButtonAlt, { ButtonStatusType, ButtonTheme } from 'components/v2/ButtonAlt/ButtonAlt';
import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import TextEditor2 from 'components/v2/TextEditor2/TextEditor2';
import { ClientRecordType, ProfileRole } from 'interfaces/Clients/clientsRecord';
import { ParticipantType } from 'pages/AssessmentDetails/CaseNoteTemplate/interface';
import useGetCaseNoteTemplatesList from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsNotes/hooks/useGetCaseNoteTemplatesList';
import { useFetchClientRecordById } from 'pages/PatientDetails/hooks/GetClientRecord';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useCreateNoteMutation } from 'redux/endpoints/checkInServices/notes';
import { clientRecordsApiSlice } from 'redux/endpoints/clinicianProfileServices/client';
import { CPSTagTypes } from 'redux/services/clinicianProfileServicesApiSlice';
import { combineName } from 'utils/general';
import { useGetAccountPackage } from 'utils/hooks/GetAccountInfo/accountPackage';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccessToken } from 'utils/hooks/token';
import styles from './ClientAICompanion.module.scss';
import AudioRecorder from '../AudioRecorder/AudioRecorder';

const CHARACTER_LIMIT = 1000;

enum GenerateOptions {
  CaseNote = 'caseNote'
}

enum PromptOptions {
  Shorthand = 'shorthand',
  VoiceNote = 'voiceNote'
}

enum ViewType {
  Edit = 'edit',
  History = 'history',
  Settings = 'settings'
}

const ClientAICompanion = () => {
  const menuNode = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const { isEdgePlanUser } = useGetAccountPackage();
  const { isNormalUserView, isEdgeAdminView } = useGetAccountPackageView();

  const isProfilePage = isEdgePlanUser ? location.pathname.split('/')[4] : location.pathname.split('/')[3];

  const clientRecordId = isProfilePage
    ? isEdgePlanUser
      ? location.pathname.split('/')[3]
      : location.pathname.split('/')[2]
    : '';

  const [showCompanionMenu, setShowCompanionMenu] = useState(false);
  const [includePsychometric, setIncludePsychometric] = useState(false);
  const [submitButtonStatus, setSubmitButtonStatus] = useState<ButtonStatusType>('');
  const [generateType, setGenerateType] = useState<GenerateOptions>(GenerateOptions.CaseNote);
  const [caseNoteTemplate, setCaseNoteTemplate] = useState<string>('');
  const [prompt, setPrompt] = useState<PromptOptions>(PromptOptions.Shorthand);
  const [note, setNote] = useState('');
  const [viewType, setViewType] = useState<ViewType>(ViewType.Edit);
  const [savedNote, setSavedNote] = useState<string | null>(null);

  const dispatch = useDispatch();
  const [createNote] = useCreateNoteMutation();

  const { clientRecordData } = useFetchClientRecordById(token, clientRecordId, accountId);

  const clientPrimaryName = useMemo(() => {
    if (clientRecordData) {
      const primaryClientProfileName = [ClientRecordType.Child, ClientRecordType.YoungPerson].includes(
        clientRecordData.recordType
      )
        ? clientRecordData.clientProfiles.filter(
            ({ role }) => role && [ProfileRole.Child, ProfileRole.YoungPerson].includes(role)
          )
        : clientRecordData.clientProfiles;

      return clientRecordData?.clientProfiles
        ? combineName(primaryClientProfileName, clientRecordData.recordType === 'couple')
        : '-';
    }
  }, [clientRecordData]);

  const {
    currentTemplate: currentCaseNoteTemplate,
    isLoading: isTemplateListLoading,
    templatesList: caseNotesTemplateList
  } = useGetCaseNoteTemplatesList(token, ParticipantType.Individual, isEdgeAdminView || isNormalUserView);

  useEffect(() => {
    if (currentCaseNoteTemplate && currentCaseNoteTemplate._id) {
      setCaseNoteTemplate(currentCaseNoteTemplate._id);
    }
  }, [currentCaseNoteTemplate]);

  const handleClickOutSide = (event: any) => {
    if (menuNode.current?.contains(event.target) || event.target.closest('.jodit')) {
      return;
    }
    setShowCompanionMenu(false);
  };

  const stripHtmlTags = (htmlContent: string) => {
    const div = document.createElement('div');
    div.innerHTML = htmlContent;
    return div.textContent || div.innerText || '';
  };

  const charCount = useMemo(() => {
    const plainText = stripHtmlTags(note);
    return plainText.length;
  }, [note]);

  const handleSaveProfileNote = async () => {
    if (!charCount) {
      notification.error({ message: 'Please enter note' });
      return;
    }
    setSubmitButtonStatus('active');

    try {
      await createNote({
        clientRecordId,
        title: 'AI companion note',
        body: note,
        pinType: ''
      }).unwrap();
      dispatch(clientRecordsApiSlice.util.invalidateTags([CPSTagTypes.ClientEncrypted, CPSTagTypes.ClientList]));

      setSubmitButtonStatus('finished');
      notification.success({
        message: 'Note saved',
        duration: 5,
        closeIcon: <span className="success">OK</span>
      });

      setTimeout(() => {
        setSubmitButtonStatus('');
      }, 2000);
    } catch (e) {
      console.error(e);
      notification.error({ message: 'Something went wrong on saving profile note' });
      setSubmitButtonStatus('');
    }
  };

  const handleGenerateNote = async () => {
    setSavedNote(note);
  };

  const getTitleByTab = (tab: ViewType) => {
    switch (tab) {
      case ViewType.History:
        return 'HISTORY';
      case ViewType.Settings:
        return 'SETTINGS';
      default:
        return 'GENERATE';
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  return (
    <div className={styles.container} ref={menuNode}>
      <Chip
        title={'AI Companion'}
        icon={showCompanionMenu ? 'expand_less' : 'auto_fix_high'}
        theme={'primary'}
        onClick={() => setShowCompanionMenu(!showCompanionMenu)}
        active={showCompanionMenu}
      />

      <div className={styles.menuWrapper}>
        <div className={showCompanionMenu ? styles.menuShow : styles.menuHide}>
          <div className={styles.menuItem}>
            <div className={styles.row}>
              <div className={styles.header}>
                <div className={styles.title}>{getTitleByTab(viewType)}</div>
                <div className={styles.viewType}>
                  <i
                    className={`material-icons-outlined ${styles.icon} ${
                      viewType === ViewType.Edit ? styles.selected : ''
                    }`}
                    onClick={() => setViewType(ViewType.Edit)}
                  >
                    edit
                  </i>
                  <i
                    className={`material-icons-outlined ${styles.icon} ${
                      viewType === ViewType.History ? styles.selected : ''
                    }`}
                    onClick={() => setViewType(ViewType.History)}
                  >
                    history
                  </i>
                  <i
                    className={`material-icons-outlined ${styles.icon} ${
                      viewType === ViewType.Settings ? styles.selected : ''
                    }`}
                    onClick={() => setViewType(ViewType.Settings)}
                  >
                    tune
                  </i>
                </div>
              </div>
            </div>

            {viewType === ViewType.Edit && (
              <div className={styles.editContainer}>
                <div className={styles.caseNoteContainer}>
                  <Radio
                    options={[
                      {
                        label: (
                          <div className={styles.caseNoteLabel}>
                            <div>Case Note</div>
                            <DropdownSearchable
                              className={styles.input}
                              controllerClassName={styles.inputController}
                              labelWrapperClassName={styles.labelWrapper}
                              labelClassName={styles.inputLabel}
                              optionWrapperClassName={styles.optionWrapper}
                              placeholder={'Set Note Template... *'}
                              options={caseNotesTemplateList.map((template) => ({
                                value: template._id || '',
                                label: template.name
                              }))}
                              selected={caseNoteTemplate}
                              onSelect={(value) => setCaseNoteTemplate(value)}
                              hideErrorDesc
                              showBorderBottom
                              isLoading={isTemplateListLoading}
                              disabled={submitButtonStatus === 'active'}
                            />
                          </div>
                        ),
                        value: GenerateOptions.CaseNote
                      }
                    ]}
                    value={generateType}
                    vertical
                    disabled={submitButtonStatus === 'active'}
                    onChange={(e) => setGenerateType(e.target.value as GenerateOptions)}
                    className={styles.radioContainer}
                    labelClassName={styles.labelContainer}
                    name="generate-type"
                    radioClass={styles.radio}
                  />
                </div>
                <div className={styles.separator} />
                <div className={classNames(styles.row, styles.clientSection)}>
                  {clientRecordData && (
                    <div className={styles.item}>
                      <div className={styles.title}>CLIENT</div>
                      <div className={styles.client}>
                        <div className={styles.name}>{clientPrimaryName}</div>
                        <div className={styles.details}>
                          {clientRecordData.recordType === ClientRecordType.Adult && (
                            <span>{clientRecordData.clientProfiles?.[0].dateOfBirth}</span>
                          )}
                          <span>{clientRecordData.tacklitId}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className={styles.item}>
                    <div className={styles.title}>PROMPT</div>
                    <ToggleSwitchV2
                      id="prompt-type"
                      toggleList={[
                        {
                          id: PromptOptions.Shorthand,
                          label: 'SHORTHAND',
                          isActive: prompt === PromptOptions.Shorthand
                        },
                        {
                          id: PromptOptions.VoiceNote,
                          label: 'VOICE NOTE',
                          isActive: prompt === PromptOptions.VoiceNote
                        }
                      ]}
                      size="small"
                      className={styles.toggleContainer}
                      activeLabelClassName={styles.activeLabel}
                      indicatorClassName={styles.indicator}
                      onChangeStatus={(val) => {
                        setPrompt(val.id as PromptOptions);
                      }}
                    />
                  </div>
                </div>
                {prompt === PromptOptions.VoiceNote ? (
                  <AudioRecorder />
                ) : (
                  <>
                    <div className={styles.editorContainer}>
                      {savedNote === note && (
                        <div
                          className={styles.resetButton}
                          onClick={() => {
                            setNote('');
                            setSavedNote('');
                          }}
                        >
                          <i className={`material-icons-outlined ${styles.icon}`}>restart_alt</i>
                          <span>Start again</span>
                        </div>
                      )}
                      <div className={styles.counter}>{CHARACTER_LIMIT - charCount} characters remaining</div>
                      <TextEditor2
                        height={380}
                        value={note}
                        onChange={(content) => {
                          setNote(content);
                        }}
                        limitChars={CHARACTER_LIMIT}
                        readOnly={submitButtonStatus === 'active'}
                        toolbarButtons={savedNote === note ? [] : 'bold,italic,underline,ol,ul'}
                      />

                      <div className={styles.editorFooter}>
                        <ButtonAlt
                          onClick={() => handleSaveProfileNote()}
                          variant="outlined"
                          icon="insert_comment"
                          size="small"
                          buttonTheme={ButtonTheme.User}
                          disabled={submitButtonStatus === 'active'}
                          className={styles.button}
                        >
                          Save Input
                        </ButtonAlt>
                      </div>

                      <CheckBox
                        id="isIncludePsychometric"
                        value={includePsychometric}
                        label={'Include psychometric scores (if available)'}
                        onChange={(e) => {
                          setIncludePsychometric(e.target.checked);
                        }}
                        disabled={submitButtonStatus === 'active'}
                      />
                    </div>

                    <ButtonAlt
                      icon="bolt"
                      buttonTheme={ButtonTheme.User}
                      onClick={() => handleGenerateNote()}
                      status={submitButtonStatus}
                    >
                      Generate New Note
                    </ButtonAlt>

                    <div className={styles.description}>
                      <i className={`material-icons-outlined ${styles.icon}`}>waving_hand</i> Please check the output
                      from our note companion carefully
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientAICompanion;
