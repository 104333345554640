import { notification } from 'antd';

import { ClinicalAssessmentResponseList } from 'interfaces/checkInService/clinicalAssessment';
import { LetterClientTab, LetterStatus } from 'interfaces/Letters/letter';
import { ReportClientTab, ReportListInterface } from 'interfaces/Reports/report';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetLettersQuery } from 'redux/endpoints/documentServices/letter';
import { useGetReportsQuery } from 'redux/endpoints/documentServices/report';
import {
  selectCurrentEpisodeId,
  selectIsFetchingEpisodes,
  selectIsOutsideAllEpisodes,
  selectIsShowAllData
} from 'redux/episodes/episodeSlice';
import { useAppSelector } from 'redux/hooks';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { getClinicalAssessmentList } from 'utils/http/CheckInService/Assessment/clinicalAssessment';
import { ReportStatus } from 'components/layout/HeaderT23/components/Notification/components/NotificationsModal/components/ReportNotification/interfaces';

export const massageNameInId = (records: ClinicalAssessmentResponseList[]) => {
  const addApostrophe = (name: string): string => (name.slice(-1) === 's' ? `${name}'` : `${name}'s`);
  return records.map((record) => ({
    ...record,
    id: `${addApostrophe(record.assessedBy)} ${record.id}`
  }));
};

export const useFetchAssessmentList = (token: string, recordId: string, nameInId?: boolean) => {
  const [assessmentList, setAssessmentsList] = useState<ClinicalAssessmentResponseList[] | undefined>(undefined);
  const [isAssessmentListLoading, setIsAssessmentListLoading] = useState(true);
  const { isEdgeReceptionist } = useGetAccountPackageView();
  const [t] = useTranslation();

  const fetchAssessmentList = useCallback(async () => {
    setAssessmentsList(undefined); // clear list to prevent race condition
    try {
      const callGetClinicalAssessmentResponsesListByPatientId =
        !isEdgeReceptionist && (await getClinicalAssessmentList(token, recordId));

      const assessmentList =
        callGetClinicalAssessmentResponsesListByPatientId &&
        (await callGetClinicalAssessmentResponsesListByPatientId.json());

      setAssessmentsList(!nameInId ? assessmentList : massageNameInId(assessmentList));
      setIsAssessmentListLoading(false);
    } catch (ex) {
      notification.error({ message: t('form.error.fetch_client_assessment_list') });
    }
  }, [isEdgeReceptionist, nameInId, recordId, t, token]);

  useEffect(() => {
    if (token) {
      fetchAssessmentList();
    }
  }, [fetchAssessmentList, token]);

  return { assessmentList, isAssessmentListLoading };
};

export const useFetchReportList = (token: string, recordId: string) => {
  const [reportList, setReportList] = useState<ReportClientTab[]>([]);
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const [t] = useTranslation();
  const currentEpisodeId = useAppSelector(selectCurrentEpisodeId);
  const isOutsideAllEpisodes = useAppSelector(selectIsOutsideAllEpisodes);
  const isShowAllData = useAppSelector(selectIsShowAllData);
  const isFetchingEpisodes = useAppSelector(selectIsFetchingEpisodes);
  const { isEoCEnabled } = useGetFeatureToggle();

  const {
    data: reportData,
    isLoading,
    isFetching
  } = useGetReportsQuery(
    {
      payload: {
        clientRecordId: recordId,
        ...(!isEdgeAdminView && { status: ReportStatus.Published }),
        ...(!isShowAllData && currentEpisodeId && isEoCEnabled && { episodeId: currentEpisodeId }),
        ...(!isShowAllData && isOutsideAllEpisodes && isEoCEnabled && { showOutsideOfEpisode: isOutsideAllEpisodes })
      },
      isAdmin: isEdgeAdminView
    },
    {
      skip:
        isEdgeReceptionist ||
        (isEoCEnabled && isFetchingEpisodes) ||
        (isEoCEnabled && !isOutsideAllEpisodes && !isShowAllData && !currentEpisodeId)
    }
  );

  const mapReportList = async () => {
    if (!isEdgeReceptionist && reportData) {
      try {
        const reportList = (reportData as ReportListInterface[])
          .filter(({ status }) => status === 'published')
          .sort(({ createdAt: reportACreatedAt }, { createdAt: reportBCreatedAt }) =>
            reportBCreatedAt.localeCompare(reportACreatedAt)
          )
          .map(({ _id, reportName, createdAt }) => ({ _id, reportName, createdAt }))
          .filter((reportItems): reportItems is ReportClientTab => !!reportItems);

        // ToDo: To type this properly. Now we are dealing with string.
        setReportList(reportList);
      } catch (ex) {
        console.log(ex);
        notification.error({ message: t('form.error.fetch_client_report') });
      }
    }
  };

  useEffect(() => {
    if (recordId) {
      mapReportList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData, isFetching, recordId, currentEpisodeId]);

  return { reportList, isReportListLoading: isLoading, isFetchingReportList: isFetching };
};

export const useFetchLetterList = (accountId: string, clinicianId: string, recordId: string) => {
  const [letterList, setLetterList] = useState<LetterClientTab[]>([]);
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const currentEpisodeId = useAppSelector(selectCurrentEpisodeId);
  const isOutsideAllEpisodes = useAppSelector(selectIsOutsideAllEpisodes);
  const isShowAllData = useAppSelector(selectIsShowAllData);
  const isFetchingEpisodes = useAppSelector(selectIsFetchingEpisodes);
  const { isEoCEnabled } = useGetFeatureToggle();
  const [t] = useTranslation();
  const {
    data: letterData,
    isLoading: isLetterListLoading,
    isFetching: isLetterListFetching
  } = useGetLettersQuery(
    {
      payload: {
        clientRecordId: recordId,
        ...(!(isEdgeAdminView || isEdgeReceptionist) && { status: LetterStatus.Published }),
        ...(!isShowAllData && currentEpisodeId && isEoCEnabled && { episodeId: currentEpisodeId }),
        ...(!isShowAllData && isOutsideAllEpisodes && isEoCEnabled && { showOutsideOfEpisode: isOutsideAllEpisodes })
      },
      accountId,
      clinicianId,
      isAdmin: isEdgeAdminView || isEdgeReceptionist
    },
    {
      skip:
        (isEoCEnabled && isFetchingEpisodes) ||
        (isEoCEnabled && !isOutsideAllEpisodes && !isShowAllData && !currentEpisodeId)
    }
  );

  const mapLetterList = async () => {
    try {
      const letterList = (letterData as LetterClientTab[])
        .filter(({ status }) => status === 'published')
        .sort(({ createdAt: letterACreatedAt }, { createdAt: letterBCreatedAt }) =>
          letterBCreatedAt.localeCompare(letterACreatedAt)
        )
        .map(({ _id, letterName, createdAt }) => ({ _id, letterName, createdAt }))
        .filter((letterItems): letterItems is LetterClientTab => !!letterItems);

      // ToDo: To type this properly. Now we are dealing with string.
      setLetterList(letterList);
    } catch (ex) {
      console.log(ex);
      notification.error({ message: t('form.error.fetch_client_letters') });
    }
  };

  useEffect(() => {
    if (recordId && letterData) {
      mapLetterList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId, letterData]);

  return { letterList, isLetterListLoading, isLetterListFetching };
};
