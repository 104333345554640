import LoadingDot from 'components/LoadingDot/LoadingDot';
import styles from './VideoPreviewModal.module.scss';
import ModalV2 from 'components/ModalV2/ModalV2';
import { ReactNode, useRef } from 'react';

interface VideoPreviewModalProps {
  errorMessage?: string;
  modalTitle?: ReactNode;
  videoSrc?: string;
  isLoading?: boolean;
  isOpen: boolean;
  onClose: () => void;
}

const VideoPreviewModal = ({
  errorMessage,
  modalTitle,
  videoSrc,
  isLoading,
  isOpen,
  onClose
}: VideoPreviewModalProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleClose = () => {
    onClose();
    videoRef.current?.pause();
  };

  return (
    <ModalV2
      title={modalTitle}
      containerClassName={styles.container}
      titleClassName={styles.title}
      bodyStyle={{
        padding: 0,
        borderRadius: '16px'
      }}
      width={820}
      isModalOpen={isOpen}
      onModalClose={handleClose}
    >
      {isLoading ? (
        <div className={styles.loading}>
          <LoadingDot />
        </div>
      ) : errorMessage ? (
        <div className={styles.error}>{errorMessage}</div>
      ) : videoSrc ? (
        <video className={styles.video} ref={videoRef} src={videoSrc} autoPlay controls />
      ) : (
        <div className={styles.noVideoSrc}>No video source provided</div>
      )}
    </ModalV2>
  );
};

export default VideoPreviewModal;
