import { useEffect, useState } from 'react';
import { GroupPsychometricMenu } from 'pages/Groups/Interfaces/Groups';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import { useFetchGroupAssessmentClients } from '../../hooks/getGroupAssessmentClients';
import PsychometricHeader from './components/PsychometricHeader/PsychometricHeader';
import PsychometricListContent from './components/PsychometricListContent/PsychometricListContent';
import styles from './PsychometricList.module.scss';
import LoadingDot from 'components/LoadingDot/LoadingDot';

import NoPsychometrics from './nopsychometric.png';
import Button from 'components/v2/Button/Button';
import SendAssessmentModal from '../../../SendAssessmentModal/SendAssessmentModal';
import Select from 'components/v2/Select/Select';
import { useFetchPsychometricList } from 'pages/Groups/GroupDetails/hooks/getPsychometricMenuList';

interface PsychometricListProps {
  psychometricId?: string;
  selectedClinicalAssessmentId?: string;
  psychometricMenuList: GroupPsychometricMenu[];
  onCreateSuccess: () => void;
}

interface SelectedPsychometricProps {
  id: string;
  value: string;
  label: string;
}

const PsychometricList = ({
  psychometricId,
  selectedClinicalAssessmentId,
  psychometricMenuList,
  onCreateSuccess
}: PsychometricListProps) => {
  const navigate = useNavigate();
  const { token } = useGetAccessToken();
  const { CARECOORDINATION } = useRoutesGenerator();
  const {
    groupId = '',
    contentId: paramPsychometricId,
    childContentId: paramClinicalAssessmentId
  } = useParams<{ groupId: string; contentId: string; childContentId: string }>();
  const [visibleSendAssessment, setVisibleSendAssessment] = useState<boolean>(false);
  const { psychometricList, isPsychometricListLoading } = useFetchPsychometricList(token, groupId);
  const { groupAssessmentClients, isGroupAssessmentClientsLoading } = useFetchGroupAssessmentClients(
    token,
    groupId,
    psychometricId || '',
    selectedClinicalAssessmentId || ''
  );
  const [selectedPsychometric, setSelectedPsychometric] = useState<SelectedPsychometricProps>();
  const noPsychometrics = !isPsychometricListLoading && psychometricList?.length === 0;

  useEffect(() => {
    if (!psychometricId && psychometricMenuList && psychometricMenuList.length > 0) {
      const isSentPsychometric = psychometricMenuList.filter((obj) => obj.isSent);
      if (isSentPsychometric.length > 0) {
        const redirectUrl = generatePath(CARECOORDINATION.GROUP_PSYCHOMETRICS, {
          groupId: groupId,
          psychometricId: isSentPsychometric[0]._id,
          clinicalAssessmentId: isSentPsychometric[0].clinicalAssessmentId
        });
        navigate(redirectUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [psychometricMenuList]);

  useEffect(() => {
    const psychometric = psychometricList.find(
      (i) => i._id === paramPsychometricId && i.clinicalAssessmentId === paramClinicalAssessmentId
    );
    if (psychometric) {
      setSelectedPsychometric({
        value: `${psychometric._id}_${psychometric.clinicalAssessmentId}`,
        id: psychometric._id,
        label: psychometric.clinicalAssessmentId
      });
    }
  }, [psychometricList, paramPsychometricId, paramClinicalAssessmentId, setSelectedPsychometric]);

  const handleCancel = () => {
    setVisibleSendAssessment(false);
  };

  return (
    <div className={styles.columnContainer}>
      {!noPsychometrics && (
        <div className={styles.headerContainer}>
          <Select
            filledArrow
            label="PSYCHOMETRICS"
            options={psychometricList?.map((i) => ({
              value: `${i._id}_${i.clinicalAssessmentId}`,
              id: i._id,
              label: i.clinicalAssessmentId
            }))}
            onChange={(val: any) => {
              if (val?.value !== selectedPsychometric?.value) {
                setSelectedPsychometric(val);
                const redirectUrl = generatePath(CARECOORDINATION.GROUP_PSYCHOMETRICS, {
                  groupId: groupId,
                  psychometricId: val?.id,
                  clinicalAssessmentId: val?.label
                });
                navigate(redirectUrl);
              }
            }}
            isSearchable={false}
            value={
              selectedPsychometric || {
                value: `${psychometricList[0]?._id}_${psychometricList[0]?.clinicalAssessmentId}`,
                id: psychometricList[0]?._id,
                label: psychometricList[0]?.clinicalAssessmentId
              }
            }
            isLoading={isPsychometricListLoading}
            containerClass={styles.selectPsychometricId}
            controlStyle={{ boxShadow: 'none' }}
          />
        </div>
      )}
      <div className={styles.container}>
        {groupAssessmentClients.length === 0 && isGroupAssessmentClientsLoading ? (
          <div className={styles.loadingContainer}>
            <LoadingDot />
          </div>
        ) : psychometricMenuList.length > 0 && groupAssessmentClients.length > 0 ? (
          <>
            <PsychometricHeader
              selectedClinicalAssessmentId={selectedClinicalAssessmentId}
              groupId={groupId}
              psychometricId={psychometricId}
              psychometricMenuList={psychometricMenuList}
            />
            <PsychometricListContent
              psychometricList={groupAssessmentClients}
              isPsychometricListLoading={isGroupAssessmentClientsLoading}
            />
          </>
        ) : psychometricId ? (
          'No clients were in the group when this psychometric assessment was sent, or all clients who have been sent this psychometric assessment have been removed from the group.'
        ) : (
          <div className={styles.noPsychometrics}>
            <img className={styles.image} src={NoPsychometrics} alt="No psychometric" />
            <div className={styles.prompt}>
              <div className={styles.text}>No psychometrics have been sent for this group.</div>
              <Button className={styles.button} onClick={() => setVisibleSendAssessment(true)}>
                <i className={`material-icons-outlined ${styles.icon}`}>send</i>Send assessment
              </Button>
              <SendAssessmentModal
                groupId={groupId}
                showModal={visibleSendAssessment}
                handleCancel={handleCancel}
                onCreateSuccess={onCreateSuccess}
                setShowModal={(value) => setVisibleSendAssessment(value)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PsychometricList;
