import { CustomFeature } from '../UserContextProvider';
import { useGetAccountPackageView } from '../hooks/GetAccountInfo/accountPackageView';
import { isDevelopmentENV } from './DevelopmentToggle';
import { useAppSelector } from 'redux/hooks';
import { myProfileInfo } from 'redux/slice/profileDataSlice';

export const useGetFeatureToggle = () => {
  const { isNormalUserView, isEdgeUserView, isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const {
    data: { customFeatures }
  } = useAppSelector(myProfileInfo);

  const featureEnableCheck = (feature: CustomFeature) =>
    Array.isArray(customFeatures) && customFeatures.includes(feature);

  const isMentorFeatureToggle = featureEnableCheck(CustomFeature.Mentor);
  const isGroupsFeatureToggle = isEdgeUserView || isEdgeAdminView || isEdgeReceptionist;
  const isEnableStonlyGuide = isNormalUserView;

  const isRegionAU = process.env.REACT_APP_GAE_REGION === 'au';
  const isRegionUK = process.env.REACT_APP_GAE_REGION === 'gb';

  const helmContentFeatureToggle = featureEnableCheck(CustomFeature.HelmContent);
  const medicareRebateFeatureToggle = isRegionAU && featureEnableCheck(CustomFeature.MedicareRebate);
  const isHelmFeatureToggle = featureEnableCheck(CustomFeature.Helm);
  const isSomeoneHealthFeatureToggle = featureEnableCheck(CustomFeature.SomeoneHealth);
  const isCaWFeatureToggle = featureEnableCheck(CustomFeature.CaW);
  const isEaseWellbeingFeatureToggle = featureEnableCheck(CustomFeature.EaseWellbeing);
  const isRechargeWellnessFeatureToggle = featureEnableCheck(CustomFeature.RechargeWellness);
  const isSelectPsychologyFeatureToggle = featureEnableCheck(CustomFeature.SelectPsychology);
  const isGroupsTagsToggle = false;
  const isVadcFeatureToggle = featureEnableCheck(CustomFeature.Vadc);
  const isRequestApprovalByDefault = featureEnableCheck(CustomFeature.RequestApprovalByDefault);
  const templateNameEnable = featureEnableCheck(CustomFeature.TemplateName);
  const faxEnable = featureEnableCheck(CustomFeature.Fax);
  const isCarePlanEnabled = featureEnableCheck(CustomFeature.CarePlan) || isDevelopmentENV();
  const isEoCEnabled = featureEnableCheck(CustomFeature.EoC);
  const isMultiTimeZoneEnabled = isRegionAU && featureEnableCheck(CustomFeature.MultiTimeZone);
  const isEngagePublicProfileEnabled = featureEnableCheck(CustomFeature.EngagePublicProfile);
  const isInvoiceTemplateEnabled = isDevelopmentENV() || featureEnableCheck(CustomFeature.InvoiceTemplate);
  const isClientTimeZoneEnabled = isRegionAU;
  const isEgristEnabled = featureEnableCheck(CustomFeature.Egrist);
  const isHealthDataEnabled = featureEnableCheck(CustomFeature.HealthData);
  const isHidePrivateCaseNotes = featureEnableCheck(CustomFeature.HidePrivateCaseNotes);
  const isPackageEnabled = featureEnableCheck(CustomFeature.Packages);
  const isProcessAppointmentEnabled = featureEnableCheck(CustomFeature.ProcessAppointment);
  const isAddressBookFeatureToggle = isEdgeAdminView && featureEnableCheck(CustomFeature.AddressBook);
  const isInvoiceListV2Enabled = featureEnableCheck(CustomFeature.InvoiceListV2);
  const ableToAccessTaskList = isProcessAppointmentEnabled && isEdgeAdminView;
  const isChargeAppointmentTagEnabled =
    isEaseWellbeingFeatureToggle || isRechargeWellnessFeatureToggle || isSelectPsychologyFeatureToggle;
  const isTwilioTelehealthEnabled = featureEnableCheck(CustomFeature.TwilioTelehealth);
  const isReferralV2Enabled = featureEnableCheck(CustomFeature.ReferralV2);
  const isReferralV2ListEnabled = (isEdgeAdminView || isEdgeReceptionist) && isReferralV2Enabled;
  const isPaymentListEnabled = isEdgeAdminView && featureEnableCheck(CustomFeature.SomeoneHealth);
  const isPractitionerReportEnabled = isEdgeAdminView || isEdgeUserView;
  const isAICompanionEnabled = featureEnableCheck(CustomFeature.AICompanion);
  const isValionFeatureToggle = featureEnableCheck(CustomFeature.Valion);

  return {
    isEnableStonlyGuide,
    isGroupsFeatureToggle,
    isGroupsTagsToggle,
    isHelmFeatureToggle,
    isMentorFeatureToggle,
    isRegionAU,
    isRegionUK,
    isVadcFeatureToggle,
    helmContentFeatureToggle,
    medicareRebateFeatureToggle,
    isRequestApprovalByDefault,
    templateNameEnable,
    faxEnable,
    isSomeoneHealthFeatureToggle,
    isCaWFeatureToggle,
    isCarePlanEnabled,
    isEaseWellbeingFeatureToggle,
    isEoCEnabled,
    isMultiTimeZoneEnabled,
    isEngagePublicProfileEnabled,
    isInvoiceTemplateEnabled,
    isRechargeWellnessFeatureToggle,
    isClientTimeZoneEnabled,
    isEgristEnabled,
    isHealthDataEnabled,
    isHidePrivateCaseNotes,
    isPackageEnabled,
    isSelectPsychologyFeatureToggle,
    isProcessAppointmentEnabled,
    isAddressBookFeatureToggle,
    isInvoiceListV2Enabled,
    ableToAccessTaskList,
    isChargeAppointmentTagEnabled,
    isTwilioTelehealthEnabled,
    isReferralV2ListEnabled,
    isReferralV2Enabled,
    isPaymentListEnabled,
    isPractitionerReportEnabled,
    isAICompanionEnabled,
    isValionFeatureToggle
  };
};
