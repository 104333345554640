import { MbscPageLoadingEvent } from '@mobiscroll/react';
import classNames from 'classnames';
import ContentLayoutT23 from 'components/ContentLayoutT23/ContentLayoutT23';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ShareableLinkButton from 'components/ShareableLinkButton/ShareableLinkButton';
import { useEffect, useState } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import EventCreationModal from '../Modals/EventCreationModal/EventCreationModal';
import styles from './CalendarWithHighlightsT23.module.scss';
import CalendarFilterSection from './components/CalendarFilterSection/CalendarFilterSection';
import CalendarView from './components/CalendarView/CalendarView';
import { useAppointmentEvents } from './components/CalendarView/hooks/useAppointmentEvents';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { practiceInfo } from 'redux/slice/practiceDataSlice';
import { scheduleServicesApiSlice, SSTagTypes } from 'redux/services/scheduleServicesApiSlice';
import { notification } from 'antd';
import { useFetchFilterList } from './components/CalendarFilterSection/hooks/GetFilterList';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import {
  AppointmentViewType,
  resetAppointmentData,
  setAppointmentView,
  setOriginalDateTime,
  setSelectedDate,
  setSelectedPractitioner,
  setSelectedTime
} from 'redux/features/appointmentCreation/appointmentCreationSlice';
import moment from 'moment';
import { myProfileInfo } from 'redux/slice/profileDataSlice';

const CalendarWithHighlightsT23 = () => {
  const dispatch = useAppDispatch();
  const { isEdgeAdminView, isEdgeUserView } = useGetAccountPackageView();
  const { data: clinicianProfile } = useAppSelector(myProfileInfo);
  const { isSomeoneHealthFeatureToggle } = useGetFeatureToggle();
  const { isFiltersLoading } = useFetchFilterList();

  const { data: practiceData, isLoading: isPracticeDataLoading } = useAppSelector(practiceInfo);
  const [isShowInlineAppointment, setIsShowInlineAppointment] = useState<{
    position: 'default' | 'custom';
    showModal: boolean;
  }>({
    position: 'default',
    showModal: false
  });
  const [showOtherAppointmentForm, setShowOtherAppointmentForm] = useState<boolean>(false);

  const [calendarDate, setCalendarDate] = useState<MbscPageLoadingEvent>();

  const {
    events,
    appointmentsWithFilter,
    isLoading: calendarEventLoading,
    isFetching: calendarEventFetching
  } = useAppointmentEvents({
    calendarDate: calendarDate
  });

  const onCreateEditSuccess = () => {
    dispatch(scheduleServicesApiSlice.util.invalidateTags([SSTagTypes.AppointmentList]));
    setShowOtherAppointmentForm(false);
  };

  const clientDomain = process.env.REACT_APP_CLIENT_DOMAIN;
  const shareableUrl = isEdgeAdminView
    ? (isPracticeDataLoading && '') || (practiceData?.slugUrl && `${clientDomain}/p/${practiceData?.slugUrl}`)
    : `${clientDomain}/profile/${clinicianProfile?.slugUrl}`;

  const someoneHealthUrl = `${process.env.REACT_APP_SOMEONE_HEALTH_DOMAIN}/psychologists-profile/${clinicianProfile?.slugUrl}`;

  const handleShowNewAppointment = () => {
    dispatch(resetAppointmentData());
    // Set date and time data to inline form
    const currentDate = moment().format('YYYY-MM-DD');
    const currentStartTime = moment().format('HH:mm');
    const currentEndTime = moment().add(50, 'minutes').format('HH:mm');

    const practitionerInfo = isEdgeAdminView
      ? {
          _id: '',
          name: clinicianProfile?.practice?.name,
          avatar: clinicianProfile?.practice?.logo
        }
      : {
          _id: clinicianProfile?._id,
          name: clinicianProfile?.name,
          avatar: clinicianProfile?.avatar
        };

    dispatch(
      setSelectedPractitioner({
        _id: practitionerInfo?._id || '',
        name: practitionerInfo?.name || '',
        avatar: practitionerInfo?.avatar || ''
      })
    );

    dispatch(setSelectedDate(currentDate));
    dispatch(setSelectedTime({ startTime: currentStartTime, endTime: currentEndTime }));
    dispatch(setOriginalDateTime({ date: currentDate, startTime: currentStartTime, endTime: currentEndTime }));
    dispatch(setAppointmentView(AppointmentViewType.Calendar));
    setIsShowInlineAppointment({
      position: 'default',
      showModal: true
    });
  };

  useEffect(() => {
    if (calendarEventLoading || calendarEventFetching || isFiltersLoading) {
      notification.destroy();
      notification.warning({
        message: 'Fetching calendar events...',
        closeIcon: <span className={'notify'}>OK</span>,
        duration: 10
      });
    } else {
      notification.destroy();
    }
  }, [calendarEventLoading, calendarEventFetching, isFiltersLoading]);

  return (
    <HelmetWrapper title={'Tacklit - Calendar'}>
      <ContentLayoutT23
        className={classNames(styles.contentLayout, 't23-calendar', isEdgeAdminView && 'cal-admin-theme')}
        subTab={<CalendarFilterSection />}
        subTabTitle={
          <div className={styles.copyShareableLinkContainer}>
            Calendar
            {isSomeoneHealthFeatureToggle && (isEdgeAdminView || isEdgeUserView) ? (
              <ShareableLinkButton
                id="copy-someoneHealth-engage-link"
                url={someoneHealthUrl}
                tooltip="Copy your engage profile link"
              />
            ) : (
              !isSomeoneHealthFeatureToggle && (
                <ShareableLinkButton
                  id="copy-calendar-invite"
                  url={shareableUrl}
                  tooltip="Copy your booking page link"
                />
              )
            )}
          </div>
        }
      >
        <div className={classNames(isEdgeAdminView && 'cal-admin-theme')}>
          <div className={styles.main}>
            <div id={'calendarWrapperId'}>
              <CalendarView
                calendarDate={calendarDate}
                setCalendarDate={setCalendarDate}
                events={events}
                appointments={appointmentsWithFilter}
                isLoading={calendarEventLoading}
                isShowInlineAppointment={isShowInlineAppointment}
                setShowInlineAppointment={(value) =>
                  setIsShowInlineAppointment({
                    position: 'custom',
                    showModal: value
                  })
                }
                setShowOtherAppointmentBooking={() => setShowOtherAppointmentForm(true)}
              />
            </div>
            <div className={styles.newEventBtnWrapper}>
              <ButtonAlt
                className={styles.newEventBtn}
                iconClassName={styles.newEventIcon}
                icon={'add_circle_outline'}
                onClick={handleShowNewAppointment}
              >
                Add
              </ButtonAlt>
            </div>
            <div className={styles.bottomEdge} />
          </div>
        </div>
        <EventCreationModal
          visible={showOtherAppointmentForm}
          onClose={() => setShowOtherAppointmentForm(false)}
          onCreateEditSuccess={onCreateEditSuccess}
        />
      </ContentLayoutT23>
    </HelmetWrapper>
  );
};

export default CalendarWithHighlightsT23;
