import {
  faBook,
  faCalendarAlt,
  faChartArea,
  faCommentDots,
  faDollarSign,
  faFileInvoice,
  faIdBadge,
  faPenClip,
  faSterlingSign,
  faUserFriends,
  faUsers,
  faWrench,
  IconDefinition,
  faListCheck,
  faAddressCard,
  faAddressBook
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import {
  useFetchPractitionerListCount,
  useGetPractitionerFilterValue
} from 'pages/PractitionersListing/hooks/GetPractitionersList';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetPermissionToggle } from 'utils/featureToggle/permissionToggle';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import ClientSubTab from './components/ClientSubTab/ClientSubTab';
import ControlsSubTab from './components/ControlsSubTab/ControlsSubTab';
import FormsSubTab from './components/FormsSubTab/FormsSubTab';
import GroupsSubTab from './components/GroupsSubTab/GroupsSubTab';
import InvoicesSubTab from './components/InvoicesSubTab/InvoicesSubTab';
import MessagesSubTab from './components/MessagesSubTab/MessagesSubTab';
import PractitionerSubTab from './components/PractitionerSubTab/PractitionerSubTab';
import SubTabWrapper from './components/SubTabWrapper/SubTabWrapper';
import styles from './SideDashboardT23.module.scss';

import { resetAll as resetClientListFilters } from 'redux/clients/clientListSlice';
import { resetAll as resetAppointmentTypeFilters } from 'redux/appointmentTypes/appointmentTypesSlice';
import { resetAll as resetReportsFilter } from 'redux/reports/reportSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useGetAccountSettings } from 'utils/hooks/GetAccountSettings/getAccountSettings';
import { useGetAccessToken } from '../../utils/hooks/token';
import MenuTooltip from './components/MenuTooltip/MenuTooltip';
import SidebarBreadcrumb from './components/SidebarBreadcrumb/SidebarBreadcrumb';
import { useGetGroupList } from 'redux/endpoints/clinicianProfileServices/getGroupList';
import ReportsAndLettersSubTab from './components/ReportsAndLettersSubTab/ReportsAndLettersSubTab';
import { resetAppointmentData } from 'redux/features/appointmentCreation/appointmentCreationSlice';
import WorkspaceSidebar from './components/WorkspaceSideBar/WorkspaceSideBar';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import { debounce } from 'lodash';
import AddressBookSubTab from './components/AddressBookSubTab/AddressBookSubTab';
import TasksSubTab from './components/TasksSubTab/TasksSubTab';
import ReferralsSubTab from './components/ReferralsSubTab/ReferralsSubTab';
import { practiceInfo } from 'redux/slice/practiceDataSlice';
import { myProfileInfo } from '../../redux/slice/profileDataSlice';

interface SideDashboardItem {
  icon: IconDefinition;
  label: string;
  path: string;
  alternatePath?: string[];
  isActive: boolean;
  id: string;
  subTab?: JSX.Element;
  defaultSubTab?: JSX.Element;
  /**
   * passing this prop will add shareable link besides the sidebar title
   */
  withShareableLink?: {
    componentId: string;
    url?: string;
    tooltip: string;
  };
}

interface SideDashboardProps {
  isEdgeUser: boolean;
  isEdgeReceptionist: boolean;
  isEdgeAdminView: boolean;
  isEdgeUserView: boolean;
  isEdgePlanUser: boolean;
  isNormalUserView: boolean;
  showWorkspace: boolean;
  setShowWorkspace: (v: boolean) => void;
  showSubTab: boolean;
  setShowSubTab: (v: boolean) => void;
  isEdgeAdminUser?: boolean;
}

const ON_HOVER_DELAY_MS = 200;

const SideDashboard = ({
  isEdgeUser,
  isEdgeReceptionist,
  isEdgeAdminView,
  isEdgeUserView,
  isEdgeAdminUser,
  showWorkspace,
  setShowWorkspace,
  showSubTab,
  setShowSubTab
}: SideDashboardProps) => {
  const navigate = useNavigate();
  const {
    CLIENTS,
    CARECOORDINATION,
    PRACTITIONER,
    MESSAGES,
    CALENDAR,
    REPORTS,
    LETTERS,
    INVOICES,
    FORMS,
    CONTROL_PANEL,
    ANALYTICS,
    HELM_CONTENT,
    ADDRESS_BOOK,
    TASKS,
    REFERRALS,
    VALION_PROGRAM_CONTENT
  } = useRoutesGenerator();
  const { token } = useGetAccessToken();
  const {
    isGroupsFeatureToggle,
    isAddressBookFeatureToggle,
    helmContentFeatureToggle,
    isRegionUK,
    isSomeoneHealthFeatureToggle,
    ableToAccessTaskList,
    isCarePlanEnabled,
    isReferralV2ListEnabled,
    isValionFeatureToggle
  } = useGetFeatureToggle();
  const { isInvoiceReadDenied } = useGetPermissionToggle();
  const { groupCountByStatus } = useGetGroupList();
  const { filterValue: practitionerFilterValue } = useGetPractitionerFilterValue();
  const { practitionersCount } = useFetchPractitionerListCount(token, practitionerFilterValue);
  const { data: clinicianProfile } = useAppSelector(myProfileInfo);
  const toggleSideDashboardItem = (show: boolean, item: SideDashboardItem) => (show ? [item] : []);
  const [t] = useTranslation();
  const location = useLocation();
  const { accountSettings } = useGetAccountSettings();

  // referral link for client tab
  // move this into another component so that it doesn't polute this.
  const { data: practiceData } = useAppSelector(practiceInfo);
  const clientDomain = process.env.REACT_APP_CLIENT_DOMAIN;
  const shareableUrl = practiceData?.slugUrl ? `${clientDomain}/p/${practiceData?.slugUrl}/referral` : '';

  const someoneHealthUrl = `${process.env.REACT_APP_SOMEONE_HEALTH_DOMAIN}/psychologists-profile/${clinicianProfile?.slugUrl}`;

  const [isSecondTab, setIsSecondTab] = useState<boolean>(false);
  const [isWorkspacePinned, setIsWorkspacePinned] = useState(false);
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const sideBarMenu: SideDashboardItem[] = [
    {
      icon: faUserFriends,
      label: t('sidedashboard.client'),
      path: CLIENTS.BASE,
      isActive: false,
      id: 'clientMenuId',
      defaultSubTab: <ClientSubTab pinned setIsSecondTab={() => {}} />,
      subTab: <ClientSubTab setIsSecondTab={setIsSecondTab} />,
      ...(isSomeoneHealthFeatureToggle && (isEdgeAdminView || isEdgeUserView)
        ? {
            withShareableLink: {
              componentId: 'copy-psychologist-link',
              url: someoneHealthUrl,
              tooltip: 'Copy your engage profile link'
            }
          }
        : isEdgeUser &&
          !isSomeoneHealthFeatureToggle && {
            withShareableLink: {
              componentId: 'copy-client-registration-form-link',
              url: shareableUrl,
              tooltip: 'Copy your Client Registration form link'
            }
          })
    },
    ...toggleSideDashboardItem(isGroupsFeatureToggle, {
      icon: faUsers,
      label: isCarePlanEnabled ? 'Care Coordination' : 'Groups',
      path: CARECOORDINATION.GROUPS,
      alternatePath: [CARECOORDINATION.CAREPATHWAYS],
      isActive: false,
      id: 'groupMenuId',
      defaultSubTab: <GroupsSubTab groupCountByStatus={groupCountByStatus} pinned setIsSecondTab={() => {}} />,
      subTab: <GroupsSubTab groupCountByStatus={groupCountByStatus} setIsSecondTab={setIsSecondTab} />
    }),
    ...toggleSideDashboardItem(isReferralV2ListEnabled, {
      icon: faAddressCard,
      label: 'Referrals',
      path: REFERRALS.BASE,
      isActive: false,
      id: 'referralMenuId',
      subTab: <ReferralsSubTab />,
      ...(accountSettings?.referralFormLink && {
        withShareableLink: {
          componentId: 'copy-referral-form-link',
          url: accountSettings.referralFormLink,
          tooltip: 'Copy your referral form link'
        }
      })
    }),
    ...toggleSideDashboardItem(isEdgeAdminView || isEdgeReceptionist, {
      id: 'teamMenuId',
      icon: faIdBadge,
      label: t('sidedashboard.team'),
      path: PRACTITIONER.BASE,
      isActive: false,
      subTab: <PractitionerSubTab practitionersCount={practitionersCount} />
    }),
    {
      icon: faCommentDots,
      label: 'Messages',
      path: MESSAGES.BASE,
      isActive: false,
      id: 'messageMenuId',
      subTab: <MessagesSubTab />
    },
    {
      icon: faCalendarAlt,
      label: 'Calendar',
      path: CALENDAR.BASE,
      isActive: false,
      id: 'calendarMenuId'
    },
    {
      icon: faPenClip,
      label: 'Reports & Letters',
      path: REPORTS.BASE,
      alternatePath: [LETTERS.BASE],
      isActive: false,
      id: 'reportMenuId',
      subTab: <ReportsAndLettersSubTab />
    },
    ...toggleSideDashboardItem(!isInvoiceReadDenied, {
      icon: isRegionUK ? faSterlingSign : faDollarSign,
      label: 'Invoices',
      path: INVOICES.BASE,
      isActive: false,
      id: 'invoicesMenuId',
      subTab: <InvoicesSubTab />
    }),
    ...toggleSideDashboardItem(isAddressBookFeatureToggle, {
      icon: faAddressBook,
      label: 'Address Book',
      path: ADDRESS_BOOK.BASE,
      isActive: false,
      id: 'addressBookMenuId',
      subTab: <AddressBookSubTab />
    }),
    {
      icon: faFileInvoice,
      label: 'Forms',
      path: FORMS.BASE,
      isActive: false,
      id: 'formMenuId',
      subTab: <FormsSubTab />
    },
    {
      icon: faWrench,
      label: 'Control Panel',
      path: CONTROL_PANEL.BASE,
      isActive: false,
      id: 'controlPanelMenuId',
      subTab: <ControlsSubTab />
    },
    ...toggleSideDashboardItem(isEdgeAdminView && !!accountSettings?.analytics?.url, {
      icon: faChartArea,
      label: 'Analytics',
      path: ANALYTICS.BASE,
      isActive: false,
      id: 'analyticMenuId',
      subTab: undefined
    }),
    ...toggleSideDashboardItem(helmContentFeatureToggle, {
      icon: faBook,
      label: 'Helm Content',
      path: HELM_CONTENT.BASE,
      isActive: false,
      id: 'helmContentMenuId',
      subTab: undefined
    }),
    ...toggleSideDashboardItem(isValionFeatureToggle, {
      icon: faBook,
      label: 'Program Educational Content',
      path: VALION_PROGRAM_CONTENT.BASE,
      isActive: false,
      id: 'valionProgramContentMenuId',
      subTab: undefined
    }),
    ...toggleSideDashboardItem(ableToAccessTaskList, {
      icon: faListCheck,
      label: 'Tasks',
      path: TASKS.BASE,
      isActive: false,
      id: 'tasksMenuId',
      subTab: <TasksSubTab />
    })
  ];

  const mapUrlWithBtn = () => {
    sideBarMenu.map((obj) => {
      let sideMenu = { ...obj };
      if (
        obj &&
        obj.path.length > 1 &&
        (location.pathname.includes(obj.path) || obj?.alternatePath?.find((path) => location.pathname.includes(path)))
      ) {
        obj.isActive = true;
      }
      return sideMenu;
    });

    return sideBarMenu;
  };

  const sideMenu = mapUrlWithBtn();

  const workspaces = clinicianProfile?.workspaces;
  const workspaceEnabled = workspaces && workspaces.length > 1;

  const onClickBtn = (val: string) => {
    if (workspaceEnabled && showWorkspace) {
      expandWorkspaceSideBar(false);
    }
    navigate(val);
  };

  const handleSwitchLink = () => {
    if (workspaceEnabled && showWorkspace) {
      expandWorkspaceSideBar(false);
    }
    dispatch(resetClientListFilters());
    dispatch(resetAppointmentTypeFilters());
    dispatch(resetReportsFilter());
    dispatch(resetAppointmentData());
    navigate(isEdgeAdminView ? `user/client` : `admin/client`);
  };

  const colorTheme = isEdgeAdminView ? styles.edgeSUTheme : styles.defaultTheme;
  const logoImg = isEdgeAdminView ? clinicianProfile?.practice?.logo : clinicianProfile?.avatar;

  const getSideBarColor = () => {
    if (isEdgeReceptionist) {
      return styles.lightBlue;
    } else if (isEdgeAdminView) {
      return styles.maroon;
    } else {
      return styles.darkBlue;
    }
  };

  const sideBarColor = getSideBarColor();

  const activeMenu = sideMenu.find((i) => i.isActive);

  const [hoveredBtnTouched, setHoveredBtnTouched] = useState(false);
  const [hoveredBtn, setHoveredBtn] = useState<boolean | null>();
  const [hoveredSubmenu, setHoveredSubmenu] = useState<SideDashboardItem | undefined>(activeMenu);

  const closeSubMenu = () => {
    setHoveredBtn(false);
  };

  const hoverHandler = (item: SideDashboardItem) => {
    if (!hoveredBtnTouched) {
      setHoveredBtnTouched(true);
    }
    if ((item.defaultSubTab && isSecondTab) || (item.subTab && (!showSubTab || item.id !== activeMenu?.id))) {
      setHoveredBtn(true);
      setHoveredSubmenu(item);
    } else {
      closeSubMenu();
    }
  };

  const onClickPracticeLogo = () => {
    if (workspaceEnabled) {
      if (isWorkspacePinned) {
        expandWorkspaceSideBar(false);
      }
      setIsWorkspacePinned(!isWorkspacePinned);
    } else {
      navigate(isEdgeAdminView ? CONTROL_PANEL.PRACTICE : CONTROL_PANEL.BASE);
    }
  };

  const expandWorkspaceSideBar = (expand: boolean) => {
    if (workspaceEnabled) {
      setShowWorkspace(expand);
    }
  };

  const debouncedHandleMouseEnter = debounce(() => expandWorkspaceSideBar(true), ON_HOVER_DELAY_MS);
  const debouncedHandleMouseLeave = debounce(() => expandWorkspaceSideBar(false), ON_HOVER_DELAY_MS);

  const onMouseEnterPracticeLogo = () => {
    debouncedHandleMouseEnter();
    debouncedHandleMouseLeave.cancel();
  };

  const onMouseLeaveContainer = () => {
    closeSubMenu();
    if (!isWorkspacePinned) {
      debouncedHandleMouseLeave();
      debouncedHandleMouseEnter.cancel();
    }
  };

  useOnClickOutside(ref, () => {
    expandWorkspaceSideBar(false);
    setIsWorkspacePinned(false);
  });

  return (
    <div className={styles.sideBarContainer} onMouseLeave={onMouseLeaveContainer} ref={ref}>
      <div
        className={classnames(
          styles.sideBar,
          sideBarColor,
          workspaceEnabled && styles.withWorkspace,
          showWorkspace && styles.showWorkspace
        )}
      >
        {workspaceEnabled && <WorkspaceSidebar />}
        <div className={classnames(styles.container, colorTheme)}>
          <div className={styles.topContainer} onMouseEnter={closeSubMenu}>
            <div className={styles.logo}>
              <div className={styles.logoWrapper}>
                <img
                  className={workspaceEnabled ? styles.pointer : ''}
                  src={logoImg}
                  alt={'logo'}
                  onClick={onClickPracticeLogo}
                  onMouseEnter={onMouseEnterPracticeLogo}
                />
              </div>
            </div>
            {isEdgeAdminUser && (
              <div
                className={classnames(styles.viewSwitch, !isEdgeAdminView && styles.leftActive)}
                onClick={handleSwitchLink}
              >
                <div className={styles.background}>
                  <div className={styles.shadow} />
                  <MenuTooltip id="viewSwitchTooltipSuperUser" title="SUPERUSER" placement="top">
                    <div className={styles.icon} data-tip data-for={'viewSwitchTooltipSuperUser'}>
                      <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.7358 14.0049H12.189V1.53613C12.189 1.17369 11.8952 0.879883 11.5327 0.879883H2.34521C1.98277 0.879883 1.68896 1.17369 1.68896 1.53613V14.0049H1.14209C0.960883 14.0049 0.813965 14.1518 0.813965 14.333V14.8799H13.064V14.333C13.064 14.1518 12.917 14.0049 12.7358 14.0049ZM4.31396 2.95801C4.31396 2.7768 4.46088 2.62988 4.64209 2.62988H5.73584C5.91705 2.62988 6.06396 2.7768 6.06396 2.95801V4.05176C6.06396 4.23296 5.91705 4.37988 5.73584 4.37988H4.64209C4.46088 4.37988 4.31396 4.23296 4.31396 4.05176V2.95801ZM4.31396 5.58301C4.31396 5.4018 4.46088 5.25488 4.64209 5.25488H5.73584C5.91705 5.25488 6.06396 5.4018 6.06396 5.58301V6.67676C6.06396 6.85796 5.91705 7.00488 5.73584 7.00488H4.64209C4.46088 7.00488 4.31396 6.85796 4.31396 6.67676V5.58301ZM5.73584 9.62988H4.64209C4.46088 9.62988 4.31396 9.48296 4.31396 9.30176V8.20801C4.31396 8.0268 4.46088 7.87988 4.64209 7.87988H5.73584C5.91705 7.87988 6.06396 8.0268 6.06396 8.20801V9.30176C6.06396 9.48296 5.91705 9.62988 5.73584 9.62988ZM7.81396 14.0049H6.06396V11.708C6.06396 11.5268 6.21088 11.3799 6.39209 11.3799H7.48584C7.66705 11.3799 7.81396 11.5268 7.81396 11.708V14.0049ZM9.56396 9.30176C9.56396 9.48296 9.41705 9.62988 9.23584 9.62988H8.14209C7.96088 9.62988 7.81396 9.48296 7.81396 9.30176V8.20801C7.81396 8.0268 7.96088 7.87988 8.14209 7.87988H9.23584C9.41705 7.87988 9.56396 8.0268 9.56396 8.20801V9.30176ZM9.56396 6.67676C9.56396 6.85796 9.41705 7.00488 9.23584 7.00488H8.14209C7.96088 7.00488 7.81396 6.85796 7.81396 6.67676V5.58301C7.81396 5.4018 7.96088 5.25488 8.14209 5.25488H9.23584C9.41705 5.25488 9.56396 5.4018 9.56396 5.58301V6.67676ZM9.56396 4.05176C9.56396 4.23296 9.41705 4.37988 9.23584 4.37988H8.14209C7.96088 4.37988 7.81396 4.23296 7.81396 4.05176V2.95801C7.81396 2.7768 7.96088 2.62988 8.14209 2.62988H9.23584C9.41705 2.62988 9.56396 2.7768 9.56396 2.95801V4.05176Z"
                          fill={isEdgeAdminView ? '#6F1D38' : '#CCD4F2'}
                        />
                      </svg>
                    </div>
                  </MenuTooltip>
                  <MenuTooltip id="viewSwitchTooltipPractitioner" title="PRACTITIONER" placement="top">
                    <div className={styles.icon} data-tip data-for={'viewSwitchTooltipPractitioner'}>
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.334 3.33332H10.334V1.99999C10.334 1.25999 9.74065 0.666656 9.00065 0.666656H5.00065C4.26065 0.666656 3.66732 1.25999 3.66732 1.99999V3.33332H1.66732C0.927318 3.33332 0.333984 3.92666 0.333984 4.66666V12C0.333984 12.74 0.927318 13.3333 1.66732 13.3333H12.334C13.074 13.3333 13.6673 12.74 13.6673 12V4.66666C13.6673 3.92666 13.074 3.33332 12.334 3.33332ZM5.00065 1.99999H9.00065V3.33332H5.00065V1.99999ZM12.334 12H1.66732V10.6667H12.334V12ZM12.334 8.66666H1.66732V4.66666H3.66732V5.99999H5.00065V4.66666H9.00065V5.99999H10.334V4.66666H12.334V8.66666Z"
                          fill={isEdgeAdminView ? '#CCD4F2' : '#3F52FF'}
                        />
                      </svg>
                    </div>
                  </MenuTooltip>
                </div>
              </div>
            )}
          </div>
          <div
            className={classnames(styles.sideMenuWrapper, isEdgeAdminView ? styles.sideMenuAdmin : styles.sideMenuUser)}
          >
            {sideMenu.map((menu, index) => (
              <MenuTooltip key={index} id={menu.id} title={menu.label.toUpperCase()} align={{ offset: [-20] }}>
                <div
                  id={menu.id}
                  className={menu.isActive ? styles.btnWrapperActive : styles.btnWrapper}
                  onClick={() => onClickBtn(menu.path)}
                  onMouseEnter={() => hoverHandler(menu)}
                  data-for={menu.id}
                >
                  <div className={styles.menuIconContainer}>
                    <FontAwesomeIcon icon={menu.icon} className={styles.icon} />
                  </div>
                </div>
              </MenuTooltip>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        {!showWorkspace && hoveredSubmenu?.subTab && (
          <div>
            <SubTabWrapper
              title={hoveredSubmenu?.label}
              className={classnames(
                styles.previewSubTab,
                hoveredBtnTouched && hoveredBtn ? styles.slideIn : styles.slideOut,
                hoveredSubmenu.id === 'calendarMenuId' ? 't23-calendar' : '',
                isEdgeAdminView && 'cal-admin-theme'
              )}
              showExpandIcon={false}
              shareableLink={hoveredSubmenu.withShareableLink}
            >
              {hoveredSubmenu?.defaultSubTab ? hoveredSubmenu.defaultSubTab : hoveredSubmenu.subTab}
            </SubTabWrapper>
          </div>
        )}
        {activeMenu && activeMenu.subTab && activeMenu.id !== 'calendarMenuId' && (
          <SubTabWrapper
            title={
              activeMenu.label === t('sidedashboard.client') ? (
                <SidebarBreadcrumb
                  baseUrl={CLIENTS.BASE}
                  baseLabel={t('sidedashboard.client')}
                  subPageLabel={'Profile'}
                />
              ) : activeMenu.label === t('sidedashboard.group') ? (
                <SidebarBreadcrumb
                  baseUrl={CARECOORDINATION.GROUPS}
                  baseLabel={t('sidedashboard.group')}
                  subPageLabel={'Group Detail'}
                />
              ) : activeMenu.label === t('sidedashboard.team') ? (
                <SidebarBreadcrumb
                  baseUrl={PRACTITIONER.BASE}
                  baseLabel={t('sidedashboard.team')}
                  subPageLabel={'Control Panel'}
                />
              ) : (
                activeMenu.label
              )
            }
            className={classnames(styles.subTab, isEdgeAdminView && 'admin-theme', showWorkspace && styles.slideOut)}
            showSubTab={showSubTab}
            setShowSubTab={setShowSubTab}
            shareableLink={activeMenu.withShareableLink}
          >
            {activeMenu.subTab}
          </SubTabWrapper>
        )}
      </div>
    </div>
  );
};

export default SideDashboard;
