import styles from './DocumentViewerModal.module.scss';
import './force.css';
import { Modal, notification } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import mime from 'mime';
import { downloadAttachmentByRecordId } from 'utils/http/DocumentService/Attachments/attachments';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import Loading from 'components/Loading/Loading';
import { downloadAttachmentByGroupId } from 'utils/http/DocumentService/Attachments/groupAttachment';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { downloadCarePathwayTemplateAttachment } from 'utils/http/DocumentService/Attachments/carePathwayTemplateAttachment';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { getDecryptedDocument } from 'utils/http/ClinicianProfileService/DecryptedDocument/download';
import TiffViewer from './component/TiffViewer';
import { security } from 'utils/security';
import DocxViewer from './component/DocxViewer';
import RtfViewer from './component/RtfViewer';

const KNOWN_UNSUPPORTED_PREVIEW_FILETYPES = ['.xls', '.xlsx', '.ppt', '.pptx']; // doc has exclusion clause for rtf content
const DOCX_FILETYPES = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword'
];

interface Props {
  title: string;
  attachmentId: string;
  visible: boolean;
  onCancel: () => void;
}

// if client attachments, pass recordId only, without groupId
interface ClientAttachmentProps extends Props {
  recordId: string;
  groupId?: never;
  carePathwayTemplateId?: never;
  bucketName?: never;
}

// if group attachments, pass groupId only, without recordId
interface GroupAttachmentProps extends Props {
  groupId: string;
  recordId?: never;
  carePathwayTemplateId?: never;
  bucketName?: never;
}

// if care pathway attachments, pass groupId only, without recordId
interface CarePathwayTemplateAttachmentProps extends Props {
  groupId?: never;
  recordId?: never;
  carePathwayTemplateId: string;
  bucketName?: never;
}

interface BucketAttachment extends Omit<Props, 'attachmentId'> {
  attachmentId?: never;
  groupId?: never;
  recordId?: never;
  carePathwayTemplateId?: never;
  bucketName: string;
}

type DocumentViewerModalProp =
  | ClientAttachmentProps
  | GroupAttachmentProps
  | CarePathwayTemplateAttachmentProps
  | BucketAttachment;

const DocumentViewerModal = ({
  title,
  recordId,
  groupId,
  attachmentId,
  carePathwayTemplateId,
  bucketName,
  visible,
  onCancel
}: DocumentViewerModalProp) => {
  const { auth0ClinicianId } = useGetClinicianId();

  const [isLoading, setIsLoading] = useState(true);
  const [documentUrl, setDocumentUrl] = useState('');
  const [documentBlob, setDocumentBlob] = useState<Blob | null>();
  const [docArrayBuffer, setDocArrayBuffer] = useState<ArrayBuffer | null>();
  const [noPreviewAvailable, setNoPreviewAvailable] = useState(false);

  const [RTFText, setRTFText] = useState('');

  const fileType = useMemo(() => mime.getType(title), [title]);

  const loadDocument = async () => {
    setIsLoading(true);
    try {
      const token = await security.getAccessTokenSilently();

      const callGetAttachmentById = recordId
        ? await downloadAttachmentByRecordId(token, recordId, attachmentId)
        : groupId
        ? await downloadAttachmentByGroupId(token, groupId, attachmentId)
        : carePathwayTemplateId
        ? await downloadCarePathwayTemplateAttachment(token, auth0ClinicianId, attachmentId)
        : bucketName
        ? await getDecryptedDocument(token, bucketName, title)
        : null;

      if (!callGetAttachmentById) {
        throw new Error('Fail to fetch the file');
      }
      const attachment = await callGetAttachmentById.arrayBuffer();

      if (fileType && DOCX_FILETYPES.includes(fileType)) {
        setDocArrayBuffer(attachment);
      }

      const blob = new Blob([attachment], {
        type: fileType || 'application/octet-stream'
      });

      setDocumentBlob(blob);

      if (KNOWN_UNSUPPORTED_PREVIEW_FILETYPES.some((ext) => title.toLowerCase().endsWith(ext))) {
        setNoPreviewAvailable(true);
        setIsLoading(false);
        return;
      }

      if (bucketName) {
        setDocumentUrl(window.URL.createObjectURL(blob));
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(blob);

        reader.onload = () => {
          setDocumentUrl(reader.result as string);
        };
        reader.onerror = () => {
          throw new Error('Fail to load the file');
        };

        if (fileType === 'application/msword' || fileType === 'application/rtf') {
          const readAsText = new FileReader();
          readAsText.readAsText(blob);

          readAsText.onload = () => {
            if (
              (fileType === 'application/msword' || fileType === 'application/rtf') &&
              readAsText.result?.toString().includes('\\rtf1')
            ) {
              setRTFText(readAsText.result?.toString());
            }
          };
        }
      }

      setIsLoading(false);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to fetch this attachment.' });
    }
  };

  useEffect(() => {
    if (visible) {
      loadDocument();
    }
    // eslint-disable-next-line
  }, [visible]);

  const handleDownload = () => {
    if (!documentBlob) {
      return;
    }
    const anchor = document.createElement('a');

    anchor.href = URL.createObjectURL(documentBlob);
    anchor.download = title;
    anchor.click();
    anchor.remove();
    notification.success({ message: 'File successfully downloaded.' });
  };

  return (
    <Modal
      className={styles.modalContainer}
      open={visible}
      onCancel={onCancel}
      title={
        <div className={styles.modalHeader}>
          <p className={styles.title}>{title}</p>
          <ButtonAlt
            size={'medium'}
            variant={'outlined'}
            onClick={() => handleDownload()}
            icon={'download'}
            disabled={!documentBlob}
          >
            Download Attachment
          </ButtonAlt>
        </div>
      }
      footer={
        !noPreviewAvailable &&
        !isLoading && (
          <div className={styles.footer}>
            Document previews are provided on a best-effort basis and may not reflect the exact formatting or content of
            the original file.
          </div>
        )
      }
    >
      {isLoading ? (
        <div>
          <Loading />
        </div>
      ) : noPreviewAvailable ? (
        <div className={styles.noPreviewAvailable}>
          <p>Preview is not available for this file type.</p>
          <ButtonAlt
            size={'medium'}
            variant={'outlined'}
            onClick={() => handleDownload()}
            icon={'download'}
            disabled={!documentBlob}
          >
            Download Attachment
          </ButtonAlt>
        </div>
      ) : (
        documentUrl && (
          <>
            {fileType === 'image/tiff' ? (
              <TiffViewer documentUrl={documentUrl} />
            ) : // handle doc that has rtf content (\rtf1)
            RTFText ? (
              <div className={styles.rtfViewer}>
                <RtfViewer rtfText={RTFText} />
              </div>
            ) : // handle docx
            fileType && DOCX_FILETYPES.includes(fileType) && docArrayBuffer ? (
              <DocxViewer docArrayBuffer={docArrayBuffer} />
            ) : (
              <DocViewer
                className={styles.docViewer}
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: true
                  }
                }}
                pluginRenderers={DocViewerRenderers}
                documents={[
                  {
                    uri: encodeURI(documentUrl),
                    fileName: title
                  }
                ]}
              />
            )}
          </>
        )
      )}
    </Modal>
  );
};

export default DocumentViewerModal;
