import { useContext, useState } from 'react';
import { Props } from 'react-select';

import { Invoice } from 'pages/Invoices/interface';
import {
  MedicareItemDuration,
  MedicareItemFormat,
  MedicareItemMode
} from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/interfaces';
import { ClaimFormData, ClaimFormErrors } from '../../initValue';

import Checkbox from 'components/CheckBox/CheckBox';
import InvoiceView from 'components/InvoiceView/InvoiceView';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import Button, { ButtonStatusType } from 'components/v2/Button/Button';
import MBSOption from './components/MBSOption/MBSOption';
import MBSSingleValue from './components/MBSSingleValue/MBSSingleValue';

import styles from './ClaimForm.module.scss';
import Radio from 'components/Radio/Radio';
import { UserContext } from 'utils/UserContextProvider';
import classnames from 'classnames';
import { ClaimType } from 'pages/PatientDetails/components/PatientDetailsContent/components/PatientDetailsMedicare/hooks/getClaims';

const ONE_TO_ONE_DURATION_OPTIONS = [
  { label: 'Less than 20 minutes', value: MedicareItemDuration.OneToOneLessThan20 },
  { label: 'More than 20 minutes', value: MedicareItemDuration.OneToOneMoreThan20 },
  { label: 'More than 30 minutes', value: MedicareItemDuration.OneToOneMoreThan30 },
  { label: 'Less than 40 minutes', value: MedicareItemDuration.OneToOneLessThan40 },
  { label: 'More than 40 minutes', value: MedicareItemDuration.OneToOneMoreThan40 },
  { label: 'More than 45 minutes', value: MedicareItemDuration.OneToOneMoreThan45 },
  { label: 'Less than 50 minutes', value: MedicareItemDuration.OneToOneLessThan50 },
  { label: 'More than 50 minutes', value: MedicareItemDuration.OneToOneMoreThan50 }
];
const GROUP_DURATION_OPTIONS = [{ label: 'More than 60 minutes', value: MedicareItemDuration.GroupMoreThan60 }];
const FORMAT_OPTIONS = [
  { label: 'One to one', value: MedicareItemFormat.OneToOne },
  { label: 'Group', value: MedicareItemFormat.Group }
];
const ONE_TO_ONE_MODE_OPTIONS = [
  { label: 'Face to face', value: MedicareItemMode.FaceToFace },
  { label: 'Video call', value: MedicareItemMode.VideoCall },
  { label: 'Phone call', value: MedicareItemMode.PhoneCall },
  { label: 'Telehealth', value: MedicareItemMode.Telehealth }
];
const GROUP_MODE_OPTIONS = [
  { label: 'Face to face', value: MedicareItemMode.FaceToFace },
  { label: 'Telehealth', value: MedicareItemMode.Telehealth }
];

export interface MBSCodeOption {
  label: string;
  value: string;
  benefit?: number;
  description?: string;
}

interface ClaimFormProps {
  claimForm: ClaimFormData;
  errors: ClaimFormErrors;
  createButtonStatus: ButtonStatusType;
  invoice: Invoice;
  practitionersOptions: { label: string; value: string }[];
  locationOptions: Props['options'];
  providerOptions: Props['options'];
  mbsCodeOptions: MBSCodeOption[];
  allowPracticePayee: boolean;
  isPractitionersOptionsLoading: boolean;
  isProvidersLoading: boolean;
  isPrepopulateLoading: boolean;
  isConfirmChecked: boolean;
  isSubmitButtonDisabled: boolean;
  showErrors: boolean;
  onChange: (claimForm: ClaimFormData) => void;
  onConfirmCheckedChange: (checked: boolean) => void;
  onSubmit: () => void;
  selectedClaimType?: ClaimType;
}

const ClaimForm = ({
  claimForm,
  errors,
  createButtonStatus,
  invoice,
  practitionersOptions,
  locationOptions,
  providerOptions,
  mbsCodeOptions,
  allowPracticePayee,
  isPractitionersOptionsLoading,
  isProvidersLoading,
  isPrepopulateLoading,
  isConfirmChecked,
  isSubmitButtonDisabled,
  showErrors,
  onChange,
  onConfirmCheckedChange,
  onSubmit,
  selectedClaimType
}: ClaimFormProps) => {
  const [showManualMbsCodeSelect, setShowManualMbsCodeSelect] = useState(false);

  const { clinicianProfile } = useContext(UserContext);

  const selectedMbsItem = mbsCodeOptions.find(({ value }) => value === claimForm.mbsCode);

  const handleChangeClinicianId = (clinicianId: string) => {
    onChange({
      ...claimForm,
      clinicianId,
      providerNumber: ''
    });
  };

  const handleChangeMinorId = (minorId: string) => {
    onChange({
      ...claimForm,
      minorId,
      providerNumber: ''
    });
  };

  const handleChangeProviderNumber = (providerNumber: string) => {
    onChange({
      ...claimForm,
      providerNumber
    });
  };

  const handleChangeDuration = (duration: string) => {
    onChange({
      ...claimForm,
      duration
    });
  };

  const handleChangeDVACondition = (condition: string) => {
    onChange({
      ...claimForm,
      dvaCondition: condition
    });
  };

  const handleChangeFormat = (format: string) => {
    onChange({
      ...claimForm,
      format,
      duration: format === MedicareItemFormat.Group ? MedicareItemDuration.GroupMoreThan60 : '',
      ...(format === MedicareItemFormat.Group &&
        (claimForm.mode === MedicareItemMode.VideoCall || claimForm.mode === MedicareItemMode.PhoneCall) && {
          mode: MedicareItemMode.Telehealth
        })
    });
  };

  const handleChangeMode = (mode: string) => {
    onChange({
      ...claimForm,
      mode
    });
  };

  const handleChangeMbsCode = (mbsCode: string) => {
    onChange({
      ...claimForm,
      mbsCode
    });
  };

  const handleChangeIsPracticePayee = (value: string) => {
    onChange({
      ...claimForm,
      isPracticePayee: value === 'practice'
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.invoiceContainer}>
        <InvoiceView
          clientRecord={invoice.clientRecord}
          clinician={invoice.clinician}
          description={invoice.description}
          discount={invoice.discount}
          dueDate={invoice.dueDate}
          invoiceId={invoice.invoiceId}
          issueDate={invoice.issueDate}
          items={invoice.items}
          practice={invoice.practice}
          taxRate={invoice.taxRate}
          selectedGroup={invoice.group}
          medicare={invoice.medicare}
          invoiceAmount={invoice.invoiceAmount}
          amountChanged={invoice.amountChanged}
        />
      </div>
      <div className={styles.inputSection}>
        <div className={styles.title}>SERVICE DELIVERED BY</div>
        <div className={styles.inputContainer}>
          <div className={styles.dropdown}>
            <MaterialSelect
              id="providerName"
              label="Servicing Provider Name"
              options={practitionersOptions}
              value={claimForm.clinicianId}
              loading={isPrepopulateLoading || isPractitionersOptionsLoading}
              isSearchable
              isValidNewOption={() => false}
              onChange={handleChangeClinicianId}
            />
            {showErrors && errors.clinicianId && <div className={styles.error}>{errors.clinicianId}</div>}
          </div>
          <div className={styles.dropdown}>
            <MaterialSelect
              id="locationId"
              label="Location ID"
              options={locationOptions}
              value={claimForm.minorId}
              loading={isPrepopulateLoading}
              isSearchable
              isValidNewOption={() => false}
              onChange={handleChangeMinorId}
            />
            {showErrors && errors.minorId && <div className={styles.error}>{errors.minorId}</div>}
          </div>
          <div className={styles.dropdown}>
            <MaterialSelect
              id="providerNumber"
              label="Servicing Provider Number"
              options={providerOptions}
              value={claimForm.providerNumber}
              loading={isPrepopulateLoading || isProvidersLoading}
              isSearchable
              isValidNewOption={() => false}
              onChange={handleChangeProviderNumber}
            />
            {showErrors && errors.providerNumber && <div className={styles.error}>{errors.providerNumber}</div>}
          </div>
        </div>
      </div>
      <div className={styles.inputSection}>
        <div className={styles.title}>SERVICE DELIVERED</div>
        <div className={styles.inputContainer}>
          <div className={styles.dropdown}>
            <MaterialSelect
              id="duration"
              label="Duration"
              options={
                claimForm.format === MedicareItemFormat.OneToOne ? ONE_TO_ONE_DURATION_OPTIONS : GROUP_DURATION_OPTIONS
              }
              value={claimForm.duration}
              loading={isPrepopulateLoading}
              isSearchable={false}
              isValidNewOption={() => false}
              onChange={handleChangeDuration}
            />
            {showErrors && errors.duration && <div className={styles.error}>{errors.duration}</div>}
          </div>
          <div className={styles.dropdown}>
            <MaterialSelect
              id="format"
              label="Format"
              options={FORMAT_OPTIONS}
              value={claimForm.format}
              loading={isPrepopulateLoading}
              isSearchable={false}
              isValidNewOption={() => false}
              onChange={handleChangeFormat}
            />
            {showErrors && errors.format && <div className={styles.error}>{errors.format}</div>}
          </div>
          <div className={styles.dropdown}>
            <MaterialSelect
              id="mode"
              label="Mode"
              options={claimForm.format === MedicareItemFormat.OneToOne ? ONE_TO_ONE_MODE_OPTIONS : GROUP_MODE_OPTIONS}
              value={claimForm.mode}
              loading={isPrepopulateLoading}
              isSearchable={false}
              isValidNewOption={() => false}
              onChange={handleChangeMode}
            />
            {showErrors && errors.mode && <div className={styles.error}>{errors.mode}</div>}
          </div>
        </div>
      </div>
      {selectedClaimType === ClaimType.DVA && (
        <div className={styles.inputSection}>
          <div className={styles.inputContainer}>
            <div className={styles.dropdown}>
              <MaterialInput
                id="dvaCondition"
                label="Presenting Condition"
                value={claimForm.dvaCondition}
                onChange={(e) => handleChangeDVACondition(e.target.value)}
                autoComplete={'off'}
              />
              {showErrors && errors.duration && <div className={styles.error}>{errors.duration}</div>}
            </div>
          </div>
        </div>
      )}
      <div className={styles.mbsCodeSection}>
        <div className={styles.mbsCodeContainer}>
          <div className={styles.mbsCodeDropdown}>
            <div className={styles.title}>
              MEDICARE ITEM
              {!showManualMbsCodeSelect && (
                <Button
                  className={styles.manualOverrideButton}
                  variant="secondary"
                  onClick={() => setShowManualMbsCodeSelect(true)}
                >
                  Manual Override
                  <i className={`material-icons ${styles.icon}`}>design_services</i>
                </Button>
              )}
            </div>
            {showManualMbsCodeSelect ? (
              <MaterialSelect
                id="mbsCode"
                className={styles.dropdown}
                label="MBS Code"
                menuPlacement="auto"
                options={mbsCodeOptions}
                value={claimForm.mbsCode}
                isSearchable
                isValidNewOption={() => false}
                onChange={handleChangeMbsCode}
                components={{
                  Option: MBSOption,
                  SingleValue: MBSSingleValue
                }}
              />
            ) : selectedMbsItem ? (
              <div className={styles.mbsItem}>
                <div className={styles.mbsCode}>{selectedMbsItem.label}</div>
                <div className={styles.mbsCodeDetails}>{selectedMbsItem.description}</div>
              </div>
            ) : (
              <div className={styles.mbsCodeDetails}>No MBS code selected</div>
            )}
            {showErrors && errors.mbsCode && <div className={styles.error}>{errors.mbsCode}</div>}
          </div>
          <div className={styles.benefitSection}>
            <div className={styles.title}>PROJECTED BENEFIT</div>
            <div className={styles.benefitContainer}>
              <div className={styles.value}>
                $
                {Math.min(
                  invoice.invoiceAmount,
                  mbsCodeOptions.find(({ value }) => value === claimForm.mbsCode)?.benefit || 0
                ).toFixed(2)}
              </div>
            </div>
          </div>
          <div className={styles.submitButtonContainer}>
            <Button
              className={styles.submitButton}
              variant="primary"
              status={createButtonStatus}
              disabled={isSubmitButtonDisabled}
              onClick={onSubmit}
            >
              Submit Claim
            </Button>
            {isSubmitButtonDisabled && (
              <div className={classnames(styles.error, styles.submitButtonError)}>This claim requires a referral</div>
            )}
          </div>
        </div>
        <div className={styles.mbsCodeContainer}>
          <div className={styles.yearSelectionContainer}>
            {allowPracticePayee && (
              <>
                <div className={styles.title}>PAY BENEFIT TO:</div>
                <Radio
                  options={[
                    { label: clinicianProfile?.practice?.name || 'Service Provider', value: 'practice' },
                    {
                      label:
                        practitionersOptions.find(({ value }) => value === claimForm.clinicianId)?.label ||
                        'Practitioner',
                      value: 'practitioner'
                    }
                  ]}
                  value={claimForm.isPracticePayee ? 'practice' : 'practitioner'}
                  onChange={(e) => handleChangeIsPracticePayee(e.target.value)}
                />
              </>
            )}
          </div>
          <div>
            <Checkbox
              id="confirmSubmit"
              label="I confirm the claim data is accurate"
              labelClassName={styles.checkboxLabel}
              value={isConfirmChecked}
              onChange={(e) => onConfirmCheckedChange(e.target.checked)} // to add
            />
            {showErrors && !isConfirmChecked && (
              <div className={styles.error}>Please confirm that the claim data is accurate</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimForm;
