import classnames from 'classnames';
import { Divider, Skeleton, notification } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import Card from 'components/Card/Card';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import FlexBox from 'components/FlexBox/FlexBox';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import SelectGroupOrClientModalBox from 'components/SelectClient/SelectGroupOrClientModalBox/SelectGroupOrClientModalBox';
import ShareInfoBox from 'components/ShareInfoBox/ShareInfoBox';
import InvoiceStatusBox from 'components/StatusHistoryBox/InvoiceStatusBox';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';

import { useFetchInvoiceHistory } from 'pages/Invoices/components/InvoiceDetail/hooks/getInvoiceHistory';
import { InvoiceStatus } from 'pages/Invoices/interface';
import { useFetchNotificationSettings } from 'pages/ControlPanel/ControlPanel/hooks/getNotificationSettings';
import { useFetchInvoiceDetailWithFullClientRecordOrGroup } from 'pages/Invoices/components/InvoiceDetail/hooks/getInvoice';
import {
  useResendInvoiceMutation,
  useSendInvoiceMutation,
  useUpdateInvoiceStatusMutation
} from 'redux/endpoints/billingServices/invoices';
import { useGetAccessToken } from 'utils/hooks/token';
import { useGetInvoiceSettingsQuery } from 'redux/endpoints/billingServices/invoiceSetting';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';

import styles from './InvoiceViewWithTemplate.module.scss';
import PracticeInfoSection from './components/PracticeInfoSection/PracticeInfoSection';
import InvoiceInfoSection from './components/InvoiceInfoSection/InvoiceInfoSection';
import ServiceDetailsSection from './components/ServiceDetailsSection/ServiceDetailsSection';
import InvoiceItemSection from './components/InvoiceItemSection/InvoiceItemSection';
import PaymentDetailsSection from './components/PaymentDetailsSection/PaymentDetailsSection';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useAppSelector } from 'redux/hooks';
import { myProfileInfo } from 'redux/slice/profileDataSlice';

const InvoiceViewWithTemplate = () => {
  const { invoiceId = '' } = useParams<{ invoiceId?: string }>();
  const { token } = useGetAccessToken();
  const { accountId } = useGetAccountId();
  const { auth0ClinicianId } = useGetClinicianId();
  const { data: clinicianProfile } = useAppSelector(myProfileInfo);
  const { isEdgeUserView } = useGetAccountPackageView();
  const [sendInvoiceButtonStatus, setSendInvoiceButtonStatus] = useState<'' | 'active' | 'finished'>('');
  const [resendInvoiceButtonStatus, setResendInvoiceButtonStatus] = useState<'' | 'active' | 'finished'>('');

  const { isNotificationSettingsLoading, notificationSettings } = useFetchNotificationSettings(token, accountId);
  const { invoice, isInvoiceLoading, fetchInvoiceDetail } = useFetchInvoiceDetailWithFullClientRecordOrGroup(
    token,
    invoiceId,
    notificationSettings
  );
  const { invoiceHistory, isInvoiceHistoryLoading } = useFetchInvoiceHistory(token, invoiceId);
  const {
    data: invoiceSettings,
    isLoading: isInvoiceSettingLoading,
    isFetching: isInvoiceSettingFetching
  } = useGetInvoiceSettingsQuery({
    clinicianId: auth0ClinicianId
  });
  const isInvoiceSettingsLoading = isInvoiceSettingLoading || isInvoiceSettingFetching;

  const [updateInvoiceStatus, { isLoading: isUpdatingInvoiceStatus }] = useUpdateInvoiceStatusMutation();
  const handleChangeInvoiceStatus = async (status: InvoiceStatus) => {
    try {
      notification.warning({
        message: 'Updating invoice status...',
        duration: 2,
        closeIcon: <span className={'notify'}>OK</span>
      });

      await updateInvoiceStatus({ invoiceId: invoiceId, status: status }).unwrap();
      notification.success({
        message: 'Invoice status updated.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      fetchInvoiceDetail(token);
    } catch (ex) {
      notification.error({
        message: 'Status update failed. Please try again, if failures persist please contact us to investigate.',
        duration: 2
      });
    }
  };

  const [sendInvoice, { isLoading: isSendingInvoice }] = useSendInvoiceMutation();
  const handleSendInvoice = async (shareDetails: {
    sendAt: Date;
    sendClient: boolean;
    sendTo?: string[];
    note?: string;
  }) => {
    setSendInvoiceButtonStatus('active');
    try {
      await sendInvoice({ invoiceId: invoiceId, payload: shareDetails }).unwrap();
      notification.success({
        message: 'Invoice sent',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      setSendInvoiceButtonStatus('finished');
      setSendInvoiceButtonStatus('');
    } catch (ex) {
      setSendInvoiceButtonStatus('');
      notification.error({
        message: 'Something went wrong while trying to send invoice',
        duration: 2
      });
    }
  };

  const [resendInvoice, { isLoading: isResendingInvoice }] = useResendInvoiceMutation();
  const handleResendInvoice = async () => {
    setResendInvoiceButtonStatus('active');
    try {
      await resendInvoice({ invoiceId: invoiceId }).unwrap();
      notification.success({
        message: 'Successfully resent this invoice!',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      setResendInvoiceButtonStatus('finished');
      setResendInvoiceButtonStatus('');
    } catch (ex) {
      setResendInvoiceButtonStatus('');
      notification.error({
        message: 'Something went wrong while trying to resend invoice',
        duration: 2
      });
    }
  };

  const isLoading =
    isNotificationSettingsLoading ||
    isInvoiceLoading ||
    isInvoiceSettingsLoading ||
    isInvoiceHistoryLoading ||
    isSendingInvoice ||
    isResendingInvoice;
  const participationType = invoice?.clientRecord ? ParticipantType.OneToOne : ParticipantType.Group;
  const isInvoiceSent =
    !!invoice?.resendRecipients?.sendTo?.length ||
    !!invoice?.resendRecipients?.sendKeyContactIds?.length ||
    !!invoice?.resendRecipients?.sendClient;

  const canEditInvoice = !(isEdgeUserView && clinicianProfile?._id !== invoice?.clinician?._id);
  const paidAmount = invoice?.paid?.total || 0;
  const showServiceDetails =
    invoice?.template?.medicareClaim ||
    invoice?.template?.medicareClaimant ||
    invoice?.template?.medicareReferral ||
    invoice?.template?.medicareServiceProvider;

  return (
    <HelmetWrapper title="Invoice View With Template">
      <ContentLayout className={styles.container}>
        {!isLoading && invoice ? (
          <>
            <FlexBox direction="column">
              <SelectGroupOrClientModalBox
                clientRecord={invoice.clientRecord}
                selectedGroup={invoice.group}
                participationType={participationType}
              />
              <InvoiceStatusBox
                isUpdatingStatus={isUpdatingInvoiceStatus}
                resendInvoiceButtonStatus={resendInvoiceButtonStatus}
                onChangeStatus={handleChangeInvoiceStatus}
                onResendInvoice={handleResendInvoice}
                histories={invoiceHistory || []}
                invoiceStatus={invoice.status}
                isNotAllowResend={!isInvoiceSent}
                allowVoid={paidAmount === 0}
                allowEdit={canEditInvoice}
              />
              <ShareInfoBox
                invoice={invoice}
                label="Invoice"
                defaultNote={invoiceSettings?.note}
                sendInvoiceButtonStatus={sendInvoiceButtonStatus}
                onSendInvoice={handleSendInvoice}
                participationType={participationType}
              />
            </FlexBox>
            <Card className={styles.padding16}>
              <div className={styles.invoiceWrapper}>
                <PracticeInfoSection invoice={invoice} />
                <Divider style={{ margin: 0 }} />
                <InvoiceInfoSection invoice={invoice} />
                <Divider style={{ margin: 0 }} />
                {showServiceDetails && <ServiceDetailsSection invoice={invoice} />}
                <InvoiceItemSection invoice={invoice} />
                <Divider style={{ margin: 0 }} />
                <PaymentDetailsSection invoice={invoice} />
                {invoice.footer && (
                  <div
                    className={classnames(styles.padding20, styles.textAreaContent)}
                    dangerouslySetInnerHTML={{ __html: invoice.footer }}
                  />
                )}
              </div>
            </Card>
          </>
        ) : (
          <Skeleton active />
        )}
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default InvoiceViewWithTemplate;
