import { createSlice } from '@reduxjs/toolkit';
import { IOptionItem } from 'components/v2/DropdownSearchable/OptionItem';
import { PractitionersDetailsInterface } from 'interfaces/Practitioners/practitionersListing';
import { AppointmentStatusType } from 'interfaces/Schedule/Appointment';
import { AppointmentType, DeliveryType } from 'interfaces/Schedule/AppointmentType';
import { RoomInterface } from 'interfaces/Schedule/Room';
import { MinifiedGroup } from 'pages/Groups/Interfaces/Groups';
import { MinifiedClientRecord } from 'redux/endpoints/clinicianProfileServices/client';
import { RootState } from 'redux/store';
import { FilterCheckListItem } from 'components/FilterCheckList/FilterCheckList';

export const DEFAULT_ROOM_OPTION: RoomInterface = {
  _id: '',
  availability: {} as RoomInterface['availability'],
  isBookable: true,
  maxOccupancy: 0,
  name: 'None',
  isConflicted: false
};

export enum AppointmentHumanFactorStep {
  Practitioner,
  Client,
  AppointmentType,
  DeliveryMode
}

export enum TypeOfAppointment {
  Activity = 'activity',
  Appointment = 'appointment',
  Other = 'other'
}

export enum AppointmentViewType {
  Calendar = 'calendar',
  OpenSlot = 'openSlot',
  Customise = 'customise'
}

export enum AppointmentClientOrGroupType {
  Client = 'client',
  Group = 'group'
}

interface Appointment {
  selectedPractitioner: PractitionersDetailsInterface | undefined;
  selectedClient: MinifiedClientRecord | undefined;
  selectedGroup: MinifiedGroup | undefined; // TODO: Make it simpler with needed data only
  selectedAppointmentType: AppointmentType | undefined;
  selectedDeliveryMode: DeliveryType | undefined;
  selectedDate: string;
  selectedTime: {
    startTime: string;
    endTime: string;
  };
  recurring: {
    frequency: number;
    count: number;
  };
  selectedRoom: RoomInterface | undefined;
  originalDateTime: {
    date: string;
    startTime: string;
    endTime: string;
  };
  isActivity: boolean;
  rate?: string;
  selectedStatus?: AppointmentStatusType;
  selectedClientType: AppointmentClientOrGroupType;
  instructionNote?: string;
}

export enum FilterTimes {
  All = 'all',
  Morning = 'morning',
  Afternoon = 'afternoon',
  Evening = 'evening'
}

export const FILTER_TIME_LABELS: Record<FilterTimes, string> = {
  all: 'All',
  morning: 'Morning',
  afternoon: 'Afternoon',
  evening: 'Evening'
};

interface IAppointmentCreation {
  currentStep: AppointmentHumanFactorStep | undefined;
  isFetchingPractitioners: boolean;
  practitionerOptions: PractitionersDetailsInterface[];
  practitionerMainOptions: IOptionItem[];
  appointmentData: Appointment;
  ignoreRoomConflict: boolean;
  typeOfAppointment: TypeOfAppointment;
  isPackageAppointmentDetails?: {
    isPackageOption: boolean;
    packageId: string;
    packageAssigneeId: string;
  };
  selectedAppointmentView: AppointmentViewType;
  appointmentViewFilter?: {
    times?: FilterTimes;
    room?: FilterCheckListItem[];
  };
  startValidation?: boolean;
}

const initialState: IAppointmentCreation = {
  currentStep: undefined,
  isFetchingPractitioners: false,
  practitionerOptions: [],
  practitionerMainOptions: [],
  appointmentData: {
    selectedPractitioner: undefined,
    selectedClient: undefined,
    selectedGroup: undefined,
    selectedAppointmentType: undefined,
    selectedDeliveryMode: undefined,
    selectedDate: '',
    selectedTime: {
      startTime: '',
      endTime: ''
    },
    recurring: {
      frequency: 0,
      count: 0
    },
    selectedRoom: undefined,
    originalDateTime: {
      date: '',
      startTime: '',
      endTime: ''
    },
    isActivity: false,
    rate: '',
    selectedStatus: AppointmentStatusType.Busy,
    selectedClientType: AppointmentClientOrGroupType.Client
  },
  ignoreRoomConflict: false,
  typeOfAppointment: TypeOfAppointment.Appointment,
  selectedAppointmentView: AppointmentViewType.Customise,
  appointmentViewFilter: {
    times: FilterTimes.All
  },
  startValidation: false
};

export const appointmentCreationSlice = createSlice({
  name: 'appointmentCreationSlice',
  initialState,
  reducers: {
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },

    setIsFetchingPractitioners: (state, action) => {
      state.isFetchingPractitioners = action.payload;
    },

    setPractitionerOptions: (state, action) => {
      state.practitionerOptions = action.payload;
    },

    setPractitionerMainOptions: (state, action) => {
      state.practitionerMainOptions = action.payload;
    },

    setSelectedPractitioner: (state, action) => {
      state.appointmentData.selectedPractitioner = action.payload;
    },

    setSelectedClient: (state, action) => {
      state.appointmentData.selectedClient = action.payload;
    },

    setSelectedGroup: (state, action) => {
      state.appointmentData.selectedGroup = action.payload;
    },

    setSelectedAppointmentType: (state, action) => {
      state.appointmentData.selectedAppointmentType = action.payload;
    },

    setSelectedDeliveryMode: (state, action) => {
      state.appointmentData.selectedDeliveryMode = action.payload;
    },

    setSelectedDate: (state, action) => {
      state.appointmentData.selectedDate = action.payload;
    },

    setSelectedTime: (state, action) => {
      state.appointmentData.selectedTime = action.payload;
    },

    setRecurring: (state, action) => {
      state.appointmentData.recurring = action.payload;
    },

    setSelectedRoom: (state, action) => {
      state.appointmentData.selectedRoom = action.payload;
    },

    setIsActivity: (state, action) => {
      state.appointmentData.isActivity = action.payload;
    },

    setRate: (state, action) => {
      state.appointmentData.rate = action.payload;
    },

    setOriginalDateTime: (state, action) => {
      state.appointmentData.originalDateTime = action.payload;
    },

    setSelectedStatus: (state, action) => {
      state.appointmentData.selectedStatus = action.payload;
    },

    setSelectedClientType: (state, action) => {
      state.appointmentData.selectedClientType = action.payload;
    },

    setIgnoreRoomConflict: (state, action) => {
      state.ignoreRoomConflict = action.payload;
    },

    setTypeOfAppointment: (state, action) => {
      state.typeOfAppointment = action.payload;
    },

    resetAppointmentData: (state) => {
      state.appointmentData = initialState.appointmentData;
      state.ignoreRoomConflict = initialState.ignoreRoomConflict;
      state.typeOfAppointment = initialState.typeOfAppointment;
    },

    setIsPackageAppointment: (state, action) => {
      state.isPackageAppointmentDetails = action.payload;
    },
    setAppointmentView: (state, action) => {
      state.selectedAppointmentView = action.payload;
    },
    setAppointmentFilterView: (state, action) => {
      state.selectedAppointmentView = action.payload;
    },
    setAppointmentViewFilter: (state, action) => {
      state.appointmentViewFilter = action.payload;
    },
    setStartValidation: (state, action) => {
      state.startValidation = action.payload;
    },
    setInstructionNote: (state, action) => {
      state.appointmentData.instructionNote = action.payload;
    }
  }
});

export const selectCurrentStep = (state: RootState) => state.appointmentCreationSlice.currentStep;
export const selectPractitionerMainOptions = (state: RootState) =>
  state.appointmentCreationSlice.practitionerMainOptions;
export const selectAppointmentData = (state: RootState) => state.appointmentCreationSlice.appointmentData;
export const selectIgnoreRoomConflict = (state: RootState) => state.appointmentCreationSlice.ignoreRoomConflict;
export const selectTypeOfAppointment = (state: RootState) => state.appointmentCreationSlice.typeOfAppointment;
export const selectPackageAppointmentDetails = (state: RootState) =>
  state.appointmentCreationSlice.isPackageAppointmentDetails;
export const selectedAppointmentView = (state: RootState) => state.appointmentCreationSlice.selectedAppointmentView;
export const appointmentViewFilter = (state: RootState) => state.appointmentCreationSlice.appointmentViewFilter;
export const startInlineCalendarValidation = (state: RootState) => state.appointmentCreationSlice.startValidation;

export default appointmentCreationSlice.reducer;

export const {
  setCurrentStep,
  setPractitionerMainOptions,
  setSelectedPractitioner,
  setSelectedClient,
  setSelectedGroup,
  setSelectedAppointmentType,
  setSelectedDeliveryMode,
  setSelectedDate,
  setSelectedTime,
  setRecurring,
  setSelectedRoom,
  setIsActivity,
  setRate,
  resetAppointmentData,
  setOriginalDateTime,
  setSelectedStatus,
  setSelectedClientType,
  setIgnoreRoomConflict,
  setTypeOfAppointment,
  setIsPackageAppointment,
  setAppointmentView,
  setAppointmentViewFilter,
  setStartValidation,
  setInstructionNote
} = appointmentCreationSlice.actions;
