import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './CalendarViewConfig.module.scss';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  calendarSettings,
  setDisplayCancelledAppointment,
  setDisplayWorkingSchedule,
  setWorkingScheduleColor,
  setZoom2x
} from 'redux/calendarAppointmentList/calendarAppointmentListDataSlice';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import CheckBox from 'components/CheckBox/CheckBox';
import { SHOW_CANCELLED_APPOINTMENT_LS_VALUE, SHOW_WORKING_SCHEDULE_LS_VALUE } from '../../../../utils';
import classNames from 'classnames';
import StartTimeSettingControl from '../StartTimeSettingControl/StartTimeSettingControl';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

const CalendarViewConfig = () => {
  const dispatch = useAppDispatch();
  const configBoxRef = useRef<HTMLDivElement>(null);
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const [showConfigBox, setShowConfigBox] = useState<boolean>(false);
  const { zoom2x, displayCancelledAppointment, displayWorkingSchedule, workingScheduleColor } =
    useAppSelector(calendarSettings);
  const { CALENDAR } = useRoutesGenerator();

  const workingScheduleColorList = useMemo(
    () => [
      {
        id: '#F2F6FB',
        checked: workingScheduleColor === '#F2F6FB'
      },
      {
        id: '#C5C9D6',
        checked: workingScheduleColor === '#C5C9D6'
      },
      {
        id: '#9BA3BE',
        checked: workingScheduleColor === '#9BA3BE'
      }
    ],
    [workingScheduleColor]
  );

  const handleClickOutSide = (event: MouseEvent) => {
    if (configBoxRef.current?.contains(event.target as Node)) {
      return;
    }
    setShowConfigBox(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  return (
    <div ref={configBoxRef} className={styles.container}>
      <ButtonAlt
        variant={'outlined'}
        size={'small'}
        icon={'settings'}
        onClick={() => setShowConfigBox(!showConfigBox)}
      />
      <div className={classNames(showConfigBox ? styles.configContainer : styles.hideConfigContainer)}>
        <div className={styles.configWrapper}>
          <div>
            <StartTimeSettingControl />
          </div>
          <div className={styles.zooms}>
            <label className={classNames(styles.switchToggle, zoom2x && styles.isChecked)} htmlFor="switch-view-type">
              <span className={styles.label}>
                <i className={`material-icons-outlined ${styles.zoomIcon}`}>zoom_in</i> 1x
              </span>
              <input
                id="switch-view-type"
                name="switch-view-type"
                type="checkbox"
                checked={zoom2x}
                onChange={(e) => dispatch(setZoom2x(e.target.checked))}
              />
              <span className={styles.indicator} />
              <span className={styles.labelChecked}>
                <i className={`material-icons-outlined ${styles.zoomIcon}`}>zoom_in</i>2x
              </span>
            </label>
          </div>
          <div className={styles.eventConfigWrapper}>
            <div className={styles.showLabel}>SHOW</div>
            <div className={styles.cancelledContainer}>
              <CheckBox
                id="toggle-show-cancelled"
                value={displayCancelledAppointment}
                onChange={(ev) => {
                  dispatch(setDisplayCancelledAppointment(ev.target.checked));
                  localStorage.setItem(SHOW_CANCELLED_APPOINTMENT_LS_VALUE, JSON.stringify(ev.target.checked));
                }}
                label={'Cancelled'}
                labelClassName={styles.checkBoxLabel}
                useT23style
              />
            </div>
            <div className={styles.wsContainer}>
              <CheckBox
                id="toggle-show-ws"
                value={displayWorkingSchedule}
                disabled={false} // this might need to rethink again, if blended no need support for multi WS
                onChange={(ev) => {
                  dispatch(setDisplayWorkingSchedule(ev.target.checked));
                  localStorage.setItem(SHOW_WORKING_SCHEDULE_LS_VALUE, JSON.stringify(ev.target.checked));
                }}
                label={'Working Schedule'}
                labelClassName={styles.checkBoxLabel}
                useT23style
              />
              <div className={styles.colorCheckBoxWrapper}>
                {workingScheduleColorList.map((colorObj, index) => (
                  <input
                    key={index}
                    disabled={!displayWorkingSchedule}
                    className={classNames(
                      styles.colorCheckBox,
                      isEdgeAdminView && styles.admin,
                      !displayWorkingSchedule && styles.disabledCheckBox
                    )}
                    style={{ backgroundColor: colorObj.id }}
                    id={`${index}${colorObj.id}`}
                    type={'checkbox'}
                    checked={colorObj.checked}
                    onChange={() => dispatch(setWorkingScheduleColor(colorObj.id))}
                  />
                ))}
              </div>
            </div>
          </div>
          {!isEdgeReceptionist && (
            <div className={styles.manageSettingsWrapper}>
              <ButtonAlt
                id={'manageSettingId'}
                to={CALENDAR.SETTINGS_APT_TYPE}
                variant={'outlined'}
                icon={'settings_suggest'}
                fullWidth
              >
                Calendar settings
              </ButtonAlt>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CalendarViewConfig;
