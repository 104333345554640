import { ScaleType } from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/CollectData/CollectData.interface';

export interface Option {
  key: string;
  description: string;
  value: string | number;
}

export interface SubQuestion {
  id: string;
  stem: string;
  options: Option[];
}

export enum QuestionType {
  barSlider = 'barSlider',
  freeText = 'freeText',
  hexSlider = 'hexSlider',
  multipleAnswers = 'multipleAnswers',
  multipleChoice = 'multipleChoice',
  multipleChoiceFreeText = 'multipleChoiceFreeText',
  multipleChoiceColourFreeText = 'multipleChoiceColourFreeText',
  numeric = 'numeric',
  selectOther = 'selectOther',
  vote = 'vote',
  simpleSlider = 'simpleSlider',
  scale = 'scale',
  email = 'email',
  phone = 'phone',
  date = 'date'
}

export interface Question {
  id: string;
  questionType: QuestionType;
  stem: string;
  stimulus?: string;
  maxSelection?: number;
  options?: Option[] | SubQuestion[];
  response?: {
    value: any;
    otherValue?: string;
  };
  scales?: {
    key: string;
    value: number;
    colour: string;
  }[];
  scaleType?: ScaleType;
}

export interface Section {
  name?: string;
  description?: string;
  questions: Question[];
}

export interface CheckInQuestion {
  id: string;
  title: string;
  sections: Section[];
}

export interface ClinicalAssessment {
  id: string;
  title: string;
  description?: string;
  stimulus: string;
  questionType: 'questionSet';
  metadata: Record<string, any>;
  sections: Section[];
  scales?: {
    key?: string;
    ranges: { min: number; max: number; label: string }[];
  }[];
}

export interface MicroJournalQuestion {
  id: string;
  title: string;
  sections: Section[];
}

export interface OnboardingQuestion {
  id: string;
  title: string;
  sections: Section[];
}
export interface BackgroundResponse {
  summary: { isShow: boolean; content?: string };
  medication: {
    isShow: boolean;
    code: string;
    medications: {
      label: string;
      value: string;
    }[];
  };
  referral: {
    isShow: boolean;
    code: string;
    date?: string;
    name?: string;
    file?: {
      bucketName: string;
      fileName: string;
      fileUrl: string;
    };
  };
  therapist: {
    isShow: boolean;
    code: string;
    similarExperience?: string;
  };
}
export interface FirstAssessment {
  _id: string;
  name: string;
  clinicianId: string;
  patientId: string;
  clientProfileId: string;
  clientRecordId: string;
  status?: string;
  assessment: {
    checkInQuestions: CheckInQuestion[];
    clinicalAssessments: ClinicalAssessment[];
    microJournalQuestions: MicroJournalQuestion[];
    onboardingQuestions: {
      assessmentField: OnboardingQuestion[];
      backgroundResponse: BackgroundResponse;
    };
  };
  note?: string;
}
