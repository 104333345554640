import styles from './WorkspaceSideBar.module.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { putSwitchWorkspace } from 'utils/http/ClinicianProfileService/Workspaces/workspaces';
import { useGetAccessToken } from 'utils/hooks/token';
import { notification } from 'antd';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { Workspace } from 'interfaces/Profile/Profile';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { ButtonStatusType } from 'components/v2/Button/Button';
import Modal from 'components/ModalV2/ModalV2';
import classNames from 'classnames';
import { useAppSelector } from 'redux/hooks';
import { myProfileInfo } from 'redux/slice/profileDataSlice';

const WORKSPACE_CHANNEL = 'workspaceChannel';
const SWITCH_WORKSPACE_EVENT = 'switchWorkspace';

const WorkspaceSidebar = () => {
  const [switchingWorkspace, setSwitchingWorkspace] = useState(false);
  const [switchToAccount, setSwitchToAccount] = useState<{ accountId: string; practiceName: string }>();
  const [showSwitchAccountModal, setShowSwitchAccountModal] = useState(false);
  const [switchBackAccountBtnStatus, setSwitchBackAccountBtnStatus] = useState<ButtonStatusType>('');
  const channel = useRef<BroadcastChannel>();

  const { auth0ClinicianId: currentClinicianId } = useGetClinicianId();
  const { token } = useGetAccessToken();
  const {
    data: { workspaces }
  } = useAppSelector(myProfileInfo);

  const currentWorkspace = workspaces?.find((workspace) => workspace.clinicianId === currentClinicianId);
  const switchWorkspaceClinician = useCallback(
    async (workspace: Workspace, reload: boolean) => {
      try {
        setSwitchingWorkspace(true);
        await putSwitchWorkspace(token, workspace.clinicianId);
        channel.current?.postMessage({
          name: SWITCH_WORKSPACE_EVENT,
          clinicianId: workspace.clinicianId,
          practiceName: workspace.practice.name
        });
        if (reload) {
          window.location.replace('/');
        }
        return true;
      } catch (ex) {
        notification.error({ message: 'Failed to switch workspace' });
        return false;
      } finally {
        setSwitchingWorkspace(false);
      }
    },
    [token]
  );

  const onSwitchWorkspace = (workspace: Workspace) => {
    if (currentClinicianId !== workspace.clinicianId) {
      switchWorkspaceClinician(workspace, true);
    }
  };

  const reactivateAccount = async () => {
    if (currentWorkspace) {
      setSwitchBackAccountBtnStatus('active');
      const success = await switchWorkspaceClinician(currentWorkspace, false);
      if (success) {
        setSwitchBackAccountBtnStatus('finished');
        setShowSwitchAccountModal(false);
        setTimeout(() => setSwitchBackAccountBtnStatus(''), 500);
      } else {
        setSwitchBackAccountBtnStatus('');
      }
    }
  };

  useEffect(() => {
    channel.current?.close();
    channel.current = new BroadcastChannel(WORKSPACE_CHANNEL);
    channel.current.onmessage = (event) => {
      if (event.data.name === SWITCH_WORKSPACE_EVENT) {
        if (event.data.clinicianId !== currentClinicianId) {
          setShowSwitchAccountModal(true);
          setSwitchToAccount(event.data);
        } else {
          setShowSwitchAccountModal(false);
        }
      }
    };
    return () => {
      channel.current?.close();
    };
  }, [currentClinicianId]);

  return (
    <>
      <div className={styles.container}>
        {workspaces?.map((workspace, index) => (
          <div className={styles.logoWrapper} key={index}>
            <img
              className={classNames(
                styles.workspaceLogo,
                switchingWorkspace && styles.disabled,
                currentClinicianId === workspace.clinicianId && styles.active
              )}
              src={workspace.practice.logo}
              alt={'logo'}
              onClick={() => onSwitchWorkspace(workspace)}
              title={workspace.practice.name}
            />
          </div>
        ))}
      </div>
      <Modal
        isModalOpen={showSwitchAccountModal}
        closable={false}
        onModalClose={() => {}}
        style={{
          minWidth: '650px'
        }}
      >
        <div className={styles.modalContent}>
          <div className={styles.modalHeader}>
            <div className={`material-icons-outlined ${styles.reportIcon}`}>report</div>
            <div className={styles.modalTitle}>You have an active session in another workspace</div>
          </div>
          <div className={styles.description}>
            <div>For security we only support one active workspace per user.</div>
            <div>
              Please ensure you save any sensitive work then decide which workspace you would like to be active.
            </div>
            <div className={styles.buttonWrapper}>
              <ButtonAlt variant="outlined" size="large" autoHeight onClick={() => window.location.replace('/')}>
                Switch to {switchToAccount?.practiceName || 'workspace'}
              </ButtonAlt>
              <ButtonAlt
                variant="outlined"
                size="large"
                autoHeight
                onClick={reactivateAccount}
                status={switchBackAccountBtnStatus}
              >
                Continue in {currentWorkspace?.practice.name || 'workspace'}
              </ButtonAlt>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default WorkspaceSidebar;
