import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import styles from './RecordingPreview.module.scss';
import VideoPreviewModal from 'components/VideoPreviewModal/VideoPreviewModal';
import { useState } from 'react';
import { useLazyGetAppointmentRecordingDownloadLinkQuery } from 'redux/endpoints/scheduleServices/appointment';
import { TelehealthRecordingComposition } from 'interfaces/Schedule/Appointment';
import moment from 'moment';
import { MOMENTJS_DAY_DATE_MONTH_YEAR_FORMAT, MOMENTJS_TIME_FORMAT_DAY_PARTS } from 'utils/dateChecker';
import AppointmentRecordingsModalHeader from 'components/AppointmentRecordingsModalHeader/AppointmentRecordingsModalHeader';

interface RecordingPreviewProps {
  appointmentId: string;
  clientRecordId: string;
  recordings: TelehealthRecordingComposition[];
}

const RecordingPreview = ({ appointmentId, clientRecordId, recordings }: RecordingPreviewProps) => {
  const [fetchDownloadLink, { data, isFetching, isError }] = useLazyGetAppointmentRecordingDownloadLinkQuery();

  const [currentRecordingId, setCurrentRecordingId] = useState<string>();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const handleView = (recordingId: string) => {
    setIsPreviewOpen(true);
    setCurrentRecordingId(recordingId);
    fetchDownloadLink({ appointmentId, clientRecordId, recordingId }, true);
  };

  const handleCloseView = () => {
    setIsPreviewOpen(false);
    setCurrentRecordingId(undefined);
  };

  return (
    <>
      <VideoPreviewModal
        errorMessage={isError ? 'There was an error while fetching this video' : undefined}
        modalTitle={
          <AppointmentRecordingsModalHeader
            currentRecordingId={currentRecordingId}
            recordings={recordings}
            onClickRecording={handleView}
          />
        }
        videoSrc={data?.downloadLink}
        isOpen={isPreviewOpen}
        isLoading={isFetching}
        onClose={handleCloseView}
      />
      <div className={styles.eventInformationContainer}>
        <div className={styles.iconContainer}>
          <span className="material-icons-outlined">ondemand_video</span>
        </div>
        <div className={styles.description}>
          <div className={styles.descriptionText}>Recording available</div>
        </div>
        {recordings.map(({ _id, createdAt }) => (
          <>
            <div />
            <div className={styles.recording}>
              <span>
                {moment(createdAt).format(`${MOMENTJS_DAY_DATE_MONTH_YEAR_FORMAT} ${MOMENTJS_TIME_FORMAT_DAY_PARTS}`)}
              </span>
              <ButtonAlt size={'medium'} variant={'text'} onClick={() => handleView(_id)}>
                View
              </ButtonAlt>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default RecordingPreview;
