import { Report, ReportListInterface } from 'interfaces/Reports/report';
import { ReportTemplate } from 'interfaces/Reports/reportTemplate';
import { Signature } from 'interfaces/documentService/Signature';
import queryString from 'query-string';
import { documentServicesApiSlice } from 'redux/services/documentServicesApiSlice';
import { ReportStatus } from 'components/layout/HeaderT23/components/Notification/components/NotificationsModal/components/ReportNotification/interfaces';

export interface GetReportResponse {
  data: ReportListInterface[];
  paging: {
    page: number;
    perPage: number;
    totalItems: number;
  };
}

export interface GetReportRequestPayload {
  payload: {
    page?: number;
    perPage?: number;
    status?: ReportStatus;
    search?: string;
    clientRecordId?: string;
    episodeId?: string;
  };
  isAdmin?: boolean;
}

export const reportApiSlice = documentServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getReports: builder.query<GetReportResponse | ReportListInterface[], GetReportRequestPayload>({
      query: ({ isAdmin, payload }) => ({
        url: `${isAdmin ? '/reports' : '/clinicians/me/reports'}?${payload ? queryString.stringify(payload) : ''}`
      }),
      providesTags: ['Reports'],
      keepUnusedDataFor: 10
    }),
    getReportDetails: builder.query<Report, { isAdmin: boolean; reportId: string }>({
      query: ({ isAdmin, reportId }) => ({
        url: `${isAdmin ? '/reports' : '/clinicians/me/reports'}/${reportId}`
      }),
      providesTags: ['Report Details']
    }),
    getSignature: builder.query<Signature, void>({
      query: () => ({
        url: '/signature'
      }),
      providesTags: ['Signature']
    }),
    getReportTemplates: builder.query<ReportTemplate[], { asAdmin: boolean }>({
      query: ({ asAdmin }) => ({ url: `/reportTemplates${asAdmin ? `?asAdmin=true` : ''}` }),
      providesTags: ['Report Templates']
    })
  })
});

export const { useGetReportsQuery, useGetReportDetailsQuery, useGetSignatureQuery, useGetReportTemplatesQuery } =
  reportApiSlice;
