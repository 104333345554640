import { createRef, useContext, useEffect, useMemo, useState } from 'react';
import styles from './CalendarFilterSection.module.scss';
import {
  CalendarFilterInterface,
  CalendarHighLightInterface,
  CalendarLayout,
  CalendarRoomFilterInterface
} from 'components/v2/CalendarFilter/interfaces';
import classNames from 'classnames';
import { Datepicker } from '@mobiscroll/react';
import './MobiscrollCustom.scss';
import moment from 'moment';
import { FilterCheckListItem } from './components/CalendarFilterCheckList/CalendarFilterCheckList';
import FilterSection from './components/FilterSection/FilterSection';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import {
  CALENDAR_FILTER_COLOURS,
  getRandomColor
} from './components/CalendarFilterCheckList/components/FilterColorBox/FilterColorBox';
import AddPractitionerModal from 'components/AddPractitionerModal/AddPractitionerModal';
import CreateRoomModal from 'pages/Calendar/components/CalendarSettings/components/RoomManagement/components/CreateRoomModal/CreateRoomModal';
import { setSelectedFiltersToLocalStorage } from '../../selectedFilters/selectedFilters';
import { UserContext } from 'utils/UserContextProvider';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useFetchFilterList } from './hooks/GetFilterList';
import {
  calendarFilters,
  setSelectedClinicians,
  setSelectedRooms,
  setSelectedHighLights,
  setCalendarLayout,
  fetchFilters,
  calendarSelectedDate,
  setCalendarSelectedDate,
  calendarLayout
} from 'redux/calendarAppointmentList/calendarAppointmentListDataSlice';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import ToggleSwitchV2 from 'components/ToggleSwitchV2/ToggleSwitchV2';
import { CALENDAR_VIEW_VALUE } from '../CalendarView/utils';

const layoutViewConfig = [
  {
    id: CalendarLayout.blended,
    icon: 'view_day'
  },
  {
    id: CalendarLayout.mc,
    icon: 'view_column'
  },
  {
    id: CalendarLayout.timeline,
    icon: 'view_timeline'
  }
];

const CalendarFilterSection = () => {
  const dispatch = useAppDispatch();
  const { auth0ClinicianId } = useGetClinicianId();
  const { isNormalUserView, isEdgeAdminView, isEdgeAdminOrReceptionistView } = useGetAccountPackageView();
  const [showAddPractitioner, setShowAddPractitioner] = useState(false);
  const [showCreateRoomModal, setShowCreateRoomModal] = useState(false);
  const { clinicianProfile } = useContext(UserContext);
  const { selectedClinicians, selectedRooms, selectedHighLights } = useAppSelector(calendarFilters);
  const selectedDate = useAppSelector(calendarSelectedDate);
  const calendarViewLayout = useAppSelector(calendarLayout);

  const calendarViewToggleList = layoutViewConfig.map((toggleObj) => ({
    id: toggleObj.id,
    label: <i className={`material-icons-outlined ${styles.viewIcon}`}>{toggleObj.icon}</i>,
    isActive: calendarViewLayout === toggleObj.id
  }));

  const {
    roomList,
    isRoomListLoading,
    practitionersList,
    isPractitionersListLoading,
    highLightList,
    isFiltersLoading
  } = useFetchFilterList();

  const totalSelectedFilter = selectedClinicians.length + selectedRooms.length;

  const viewNudgeMessage = useMemo(() => {
    if ([CalendarLayout.blended, CalendarLayout.mc].includes(calendarViewLayout) && totalSelectedFilter >= 13) {
      return {
        icon: 'view_timeline',
        nudgeMessage: {
          message: 'Need to see more than 13+ staff and room?',
          button: 'Switch to Timeline',
          switchLayout: CalendarLayout.timeline
        }
      };
    }

    if (calendarViewLayout === CalendarLayout.blended && totalSelectedFilter > 4) {
      return {
        icon: 'view_column',
        nudgeMessage: {
          message: 'Need to see more than 4+ staff and room?',
          button: 'Switch to Multi column',
          switchLayout: CalendarLayout.mc
        }
      };
    }

    return { icon: '', nudgeMessage: { message: '', button: '', switchLayout: null } };
  }, [calendarViewLayout, totalSelectedFilter]);

  const onChangeSelectedClinicians = (selectedClinician: CalendarFilterInterface[]) => {
    dispatch(setSelectedClinicians(selectedClinician));

    setSelectedFiltersToLocalStorage({
      id: clinicianProfile ? clinicianProfile._id : '',
      isEdgeAdminView: isEdgeAdminView,
      selectedFilters: selectedClinician,
      selectedRooms: selectedRooms,
      selectedHighLights: selectedHighLights
    });
  };

  const onChangeSelectedRoomFilters = (selectedRoomFilter: CalendarRoomFilterInterface[]) => {
    dispatch(setSelectedRooms(selectedRoomFilter));

    setSelectedFiltersToLocalStorage({
      id: clinicianProfile ? clinicianProfile._id : '',
      isEdgeAdminView: isEdgeAdminView,
      selectedFilters: selectedClinicians,
      selectedRooms: selectedRoomFilter,
      selectedHighLights: selectedHighLights
    });
  };

  const onChangeSelectedHighLights = (selectedHighLights: CalendarHighLightInterface[]) => {
    dispatch(setSelectedHighLights(selectedHighLights));

    setSelectedFiltersToLocalStorage({
      id: clinicianProfile ? clinicianProfile._id : '',
      isEdgeAdminView: isEdgeAdminView,
      selectedFilters: selectedClinicians,
      selectedRooms: selectedRooms,
      selectedHighLights: selectedHighLights
    });
  };

  const onChangeCalendarView = (calendarView: CalendarLayout) => {
    dispatch(setCalendarLayout(calendarView));
    localStorage.setItem(CALENDAR_VIEW_VALUE, calendarView);
  };

  // transform practitioner data format
  const practitionerFilterList: CalendarFilterInterface[] = useMemo(() => {
    if (!isPractitionersListLoading && practitionersList.length > 0) {
      return practitionersList.map((practitionerObj) => ({
        _id: practitionerObj._id,
        name: practitionerObj.name,
        avatar: practitionerObj.avatar,
        isClinician: !!practitionerObj._id,
        workingSchedule: practitionerObj.workingSchedule,
        workTimeZone: practitionerObj.workTimeZone,
        color: '' // Set a default color if needed
      }));
    }
    return [];
  }, [isPractitionersListLoading, practitionersList]);

  const roomFilterList: CalendarRoomFilterInterface[] = useMemo(() => {
    if (!isRoomListLoading) {
      return roomList.map((roomObj) => ({
        _id: roomObj._id,
        name: roomObj.name,
        color: getRandomColor()
      }));
    }
    return [];
  }, [isRoomListLoading, roomList]);

  const defaultFilters = useMemo(() => {
    return isEdgeAdminOrReceptionistView
      ? [
          {
            _id: '',
            isClinician: true,
            avatar: clinicianProfile?.practice?.logo || '',
            name: clinicianProfile?.practice?.name || '',
            color: CALENDAR_FILTER_COLOURS[0]
          }
        ]
      : [
          {
            _id: auth0ClinicianId,
            isClinician: true,
            avatar: clinicianProfile?.avatar || '',
            name: clinicianProfile?.name || '',
            color: CALENDAR_FILTER_COLOURS[0],
            workingSchedule: clinicianProfile?.workingSchedule // Edge user view need working schedule
          }
        ];
  }, [isEdgeAdminOrReceptionistView, clinicianProfile, auth0ClinicianId]);

  useEffect(() => {
    if (clinicianProfile?._id) {
      dispatch(
        fetchFilters({
          clinicianProfileId: clinicianProfile._id,
          isEdgeAdminView,
          practitionersList,
          defaultFilters
        })
      );
    }
  }, [clinicianProfile?._id, isEdgeAdminView, practitionersList, defaultFilters, dispatch]);

  const onToggleFilter = <T extends FilterCheckListItem>(
    list: T[],
    selectedFilters: T[],
    setSelectedFilters: (v: T[]) => void
  ) => {
    return (value: {
      item?: CalendarRoomFilterInterface;
      toggleAllValue?: boolean;
      color?: string;
      searchValue?: string;
      isChecked?: boolean;
    }) => {
      // internal helper for create new staff item with color
      const _getStaffItemWithColor = (id: string): any => {
        const selectedItem = list.find((i) => i._id === id);
        const findItemIndex = list.findIndex((i) => i._id === id);

        return {
          ...selectedItem,
          color: selectedItem?.color
            ? selectedItem.color
            : findItemIndex < 25
            ? `color${findItemIndex + 1}`
            : getRandomColor()
        };
      };

      if (value.toggleAllValue !== undefined) {
        setSelectedFilters(
          value.toggleAllValue
            ? [
                ...selectedFilters,
                ...list
                  .filter((item) => !selectedFilters.find((i) => i._id === item._id))
                  .filter((item) => item.name.toLowerCase().includes((value.searchValue || '').toLowerCase()))
                  .map((i, index) => ({ ...i, color: index < 25 ? `color${index + 1}` : getRandomColor() }))
              ]
            : [
                ...selectedFilters.filter(
                  (item) => !item.name.toLowerCase().includes((value.searchValue || '').toLowerCase())
                )
              ]
        );
      } else if (value.item && value.color) {
        setSelectedFilters(
          selectedFilters.map((item, index) =>
            item._id !== value.item!._id
              ? item
              : {
                  ...item,
                  color: value.color === '#eee' ? (index < 25 ? `color${index + 1}` : getRandomColor()) : value.color
                }
          )
        );
      } else if (value.item) {
        // determine adding or removing a staff on the selected list
        if (value.isChecked) {
          // add in the selected list
          const addingStaff = _getStaffItemWithColor(value.item._id);
          setSelectedFilters([...selectedFilters, addingStaff]);
        } else {
          // just remove the selected from the list
          const newStaffList = [...selectedFilters.filter((staff) => staff._id !== value.item?._id)];
          setSelectedFilters(newStaffList);
        }
      }
    };
  };

  const datePickerRef = createRef<Datepicker>();

  useEffect(() => {
    if (datePickerRef.current) {
      datePickerRef.current.setVal(selectedDate);
    }
  }, [selectedDate, datePickerRef]);

  return (
    <div className={styles.container}>
      <div className="calendar-date-picker">
        <Datepicker
          firstDay={1}
          pages={1}
          onChange={(e) => dispatch(setCalendarSelectedDate(moment(e.value).toDate()))}
          calendarType={'month'}
          controls={['calendar']}
          showInput={false}
          isOpen
          theme={'ios'}
          themeVariant={'light'}
          display="inline"
          className={styles.datePicker}
          ref={datePickerRef}
        />
      </div>
      <div className={styles.layoutSection}>
        <div className={styles.layoutTitle}>LAYOUT</div>
        <div className={styles.layoutSwitcher}>
          <ToggleSwitchV2
            id={'calendarView'}
            toggleList={calendarViewToggleList}
            onChangeStatus={(value) => {
              onChangeCalendarView(value.id as CalendarLayout);
            }}
            size={'small'}
            className={styles.toggleContainer}
            activeLabelClassName={styles.activePill}
            indicatorClassName={styles.indicatorBg}
          />
        </div>
      </div>
      {isEdgeAdminOrReceptionistView && (
        <FilterSection
          title="STAFF CALENDARS"
          icon="manage_accounts"
          items={practitionerFilterList as FilterCheckListItem[]}
          selectedFilterList={selectedClinicians as FilterCheckListItem[]}
          onChangeItem={onToggleFilter(
            practitionerFilterList as FilterCheckListItem[],
            selectedClinicians as FilterCheckListItem[],
            onChangeSelectedClinicians
          )}
          loading={isFiltersLoading}
          showToggleAllButtons
          searchable
          renderNoItems={
            <div className={styles.noFilterItemsContainer}>
              <i className={classNames(styles.noFilterItemsIcon, 'material-icons-outlined')}>info</i>
              <div className={styles.noFilterItems}>
                <div>Add practitioner to allocated client records.</div>
                <div className={styles.noFilterItemsLink} onClick={() => setShowAddPractitioner(true)}>
                  Add a practitioner
                  <i className={classNames(styles.noFilterItemsIconLink, 'material-icons-outlined')}>open_in_new</i>
                </div>
              </div>
            </div>
          }
          editExtraInfo={
            viewNudgeMessage.nudgeMessage?.message ? (
              <div className={styles.extraInfoContainer}>
                <div className={`material-icons-outlined ${styles.viewIcon}`}>{viewNudgeMessage?.icon}</div>
                <div className={styles.messageContainer}>
                  <div>{viewNudgeMessage.nudgeMessage?.message}</div>
                  <div>
                    <div
                      className={styles.extraSwitchBtn}
                      onClick={() =>
                        viewNudgeMessage.nudgeMessage?.switchLayout &&
                        dispatch(setCalendarLayout(viewNudgeMessage.nudgeMessage?.switchLayout))
                      }
                    >
                      {viewNudgeMessage.nudgeMessage?.button}
                    </div>
                  </div>
                </div>
              </div>
            ) : undefined
          }
        />
      )}
      {!isNormalUserView && (
        <FilterSection
          title="ROOMS"
          icon="room_preferences"
          items={roomFilterList as FilterCheckListItem[]}
          selectedFilterList={selectedRooms as FilterCheckListItem[]}
          onChangeItem={onToggleFilter(
            roomFilterList as FilterCheckListItem[],
            selectedRooms as FilterCheckListItem[],
            onChangeSelectedRoomFilters
          )}
          loading={isFiltersLoading}
          showToggleAllButtons
          renderNoItems={
            roomFilterList.length > 0 ? (
              <div className={styles.noFilterItemsContainer}>
                <i className={classNames(styles.noFilterItemsIcon, 'material-icons-outlined')}>info</i>
                <div className={styles.noFilterItems}>
                  <div>Set up rooms to manage allocation of both physical or shared virtual rooms.</div>
                  <div className={styles.noFilterItemsLink} onClick={() => setShowCreateRoomModal(true)}>
                    Add a room
                    <i className={classNames(styles.noFilterItemsIconLink, 'material-icons-outlined')}>open_in_new</i>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )
          }
          editExtraInfo={
            viewNudgeMessage.nudgeMessage?.message ? (
              <div className={styles.extraInfoContainer}>
                <div className={`material-icons-outlined ${styles.viewIcon}`}>{viewNudgeMessage?.icon}</div>
                <div className={styles.messageContainer}>
                  <div>{viewNudgeMessage.nudgeMessage?.message}</div>
                  <div>
                    <div
                      className={styles.extraSwitchBtn}
                      onClick={() =>
                        viewNudgeMessage.nudgeMessage?.switchLayout &&
                        dispatch(setCalendarLayout(viewNudgeMessage.nudgeMessage?.switchLayout))
                      }
                    >
                      {viewNudgeMessage.nudgeMessage?.button}
                    </div>
                  </div>
                </div>
              </div>
            ) : undefined
          }
        />
      )}
      <FilterSection
        title="HIGHLIGHT"
        icon="flare"
        items={highLightList as FilterCheckListItem[]}
        selectedFilterList={selectedHighLights as FilterCheckListItem[]}
        onChangeItem={onToggleFilter(
          highLightList as FilterCheckListItem[],
          selectedHighLights as FilterCheckListItem[],
          onChangeSelectedHighLights
        )}
        loading={isFiltersLoading}
        searchable
      />
      <AddPractitionerModal
        visible={showAddPractitioner}
        onCancel={() => {
          setShowAddPractitioner(false);
        }}
      />
      <CreateRoomModal visible={showCreateRoomModal} onClose={() => setShowCreateRoomModal(false)} />
    </div>
  );
};

export default CalendarFilterSection;
