import classNames from 'classnames';
import styles from './Chip.module.scss';

interface ChipProps {
  title: string;
  active?: boolean;
  icon?: string;
  theme?: 'primary' | 'default';
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Chip = ({ title, active, icon, theme = 'default', onClick }: ChipProps) => {
  return (
    <div className={classNames(styles.container, styles[theme], active && styles.active)} onClick={onClick}>
      <div className={styles.title}>{title}</div>
      {icon && (
        <div className={styles.iconContainer}>
          <i className={`material-icons-outlined ${styles.icon}`}>{icon}</i>
        </div>
      )}
    </div>
  );
};

export default Chip;
