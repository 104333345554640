import React, { useEffect, useRef, useCallback } from 'react';
import styles from './AudioVisualizer.module.scss';

interface AudioVisualizerProps {
  mediaRecorder: MediaRecorder | null;
  audioContext: AudioContext | null;
}

const generateReorderedDataMap = (count: number) => {
  const map: { [key: number]: number } = {};
  const halfCount = Math.floor(count / 2);

  for (let i = 0; i < count; i++) {
    if (i < halfCount) {
      map[i] = halfCount - 1 - i;
    } else if (i >= halfCount && i < count - halfCount) {
      map[i] = i;
    } else {
      map[i] = count - 1 - (i - halfCount);
    }
  }

  return map;
};

const visualValueCount = 32;
const reorderedDataMap = generateReorderedDataMap(visualValueCount);

const AudioVisualizer: React.FC<AudioVisualizerProps> = ({ mediaRecorder, audioContext }) => {
  const visualMainRef = useRef<HTMLDivElement | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const barsRef = useRef<HTMLDivElement[]>(
    Array.from({ length: visualValueCount }, () => document.createElement('div'))
  );

  // Function to process the audio data and update visualizer
  const processFrame = useCallback(() => {
    if (analyserRef.current) {
      const frequencyData = new Uint8Array(analyserRef.current.frequencyBinCount);
      analyserRef.current.getByteFrequencyData(frequencyData);

      frequencyData.slice(0, visualValueCount).forEach((_, index) => {
        const mappedIndex = reorderedDataMap[index as keyof typeof reorderedDataMap];
        const value = frequencyData[mappedIndex] / 255; // Scale the value
        const bar = barsRef.current[index];
        if (bar) {
          bar.style.transform = `scaleY(${value})`;
          bar.style.opacity = Math.max(0.25, value).toString();
        }
      });
    }
    requestAnimationFrame(processFrame);
  }, [analyserRef]);

  // Connect the media stream to the audio context and analyser
  const connectStream = useCallback(
    (stream: MediaStream) => {
      if (!audioContext) return;

      const analyser = audioContext.createAnalyser();
      analyser.smoothingTimeConstant = 0.5;
      analyser.fftSize = 512;

      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);
      analyserRef.current = analyser;

      processFrame();
    },
    [audioContext, processFrame]
  );

  useEffect(() => {
    // Append the DOM elements for visualization
    if (visualMainRef.current) {
      visualMainRef.current.innerHTML = ''; // Clear existing content
      barsRef.current.forEach((bar) => {
        bar.className = styles.bar; // Assign styles to bars
        visualMainRef.current?.appendChild(bar);
      });
    }

    if (mediaRecorder && audioContext) {
      const stream = mediaRecorder.stream;
      connectStream(stream);
    } else {
      barsRef.current.forEach((bar) => {
        bar.style.transform = `scaleY(0)`;
      });
    }

    return () => {
      // Cleanup on unmount
      if (analyserRef.current) {
        analyserRef.current.disconnect();
        analyserRef.current = null;
      }
    };
  }, [mediaRecorder, audioContext, connectStream]);

  return <div className={styles.container} ref={visualMainRef} />;
};

export default AudioVisualizer;
