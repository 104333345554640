import moment from 'moment';

import { CaseNote } from 'interfaces/caseNote';

import ReadMore from 'components/ReadMore/ReadMore';
import FieldTab from './components/FieldTab/FieldTab';

import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import styles from './CaseNoteDetail.module.scss';

interface CaseNoteDetailProps {
  caseNote: CaseNote;
}

const getFieldValue = ({ type, value }: { type: string; value: string | { label: string; value: string } }) => {
  const extractedValue = typeof value === 'string' ? value : value.value;
  switch (type) {
    case 'date':
      return moment(extractedValue, MOMENTJS_DATE_FORMAT).format('MMMM DD YYYY');
    default:
      return extractedValue;
  }
};

const CaseNoteDetail = ({ caseNote }: CaseNoteDetailProps) => (
  <div className={styles.container}>
    <div className={styles.caseNoteFields}>
      {/* Mandatory fields */}
      {caseNote.fields.map(
        (field, index) => field.value && <FieldTab key={index} label={field.name} value={getFieldValue(field)} />
      )}
      {/* Safeguarding Alert */}
      {caseNote.safeguardingAlert && (
        <>
          <FieldTab label={'Was there a youth safety disclosure made?'} value={'Yes'} />
          <FieldTab label={'Youth safety reason'} value={caseNote.safeguardingReason || ''} />
        </>
      )}
    </div>
    <ReadMore bodyClass={styles.bodyClass} text={caseNote.body} maxHeight={140} />
  </div>
);

export default CaseNoteDetail;
