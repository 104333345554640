import { isEqual } from 'lodash';
import SubTab, { SubTabOption } from 'components/v2/SubTab/SubTab';
import ClientRecentlyViewed from './ClientRecentlyViewed/ClientRecentlyViewed';
import ClientProfileSubTab from './ClientProfileSubTab/ClientProfileSubTab';
import styles from '../SubTabLayout.module.scss';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetAccountPackage } from 'utils/hooks/GetAccountInfo/accountPackage';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetRecentlyViewed } from 'utils/hooks/SubTab/recentlyViewed';
import { ModalVisibilityContext } from 'components/SideDashboardT23/ModalVisibilityContext';
import { useAppDispatch } from 'redux/hooks';
import { resetPagingAndSorting } from 'redux/clients/clientListSlice';
import { useAppSelector } from 'redux/hooks';
import { selectSetting } from 'redux/clients/clientListColumnsSettingsSlice';
import { initialState, selectFilters, selectPaging, selectSearch, selectSorting } from 'redux/clients/clientListSlice';
import {
  useGetClientRecordsListQuery,
  useGetClientRecordForInlineAppointmentQuery
} from 'redux/endpoints/clinicianProfileServices/client';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { getClientRecordRequestPayload } from 'pages/Client/components/ClientListContent/components/ClientList/constants';
import { useGetClientFilterValue } from 'pages/Client/components/ClientListContent/hooks/GetClientListInfo';
import { myProfileInfo } from 'redux/slice/profileDataSlice';

export enum ClientTabId {
  Active = 'active',
  Closed = 'closed',
  Waitlist = 'waitlist'
}

const ClientSubTab = ({ pinned, setIsSecondTab }: { pinned?: boolean; setIsSecondTab: Function }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { CLIENTS } = useRoutesGenerator();
  const { isEdgePlanUser, isEdgeReceptionist } = useGetAccountPackage();
  const { isEdgeAdminView, isEdgeUserView, isNormalUserView } = useGetAccountPackageView();

  const { data: clinicianProfile } = useAppSelector(myProfileInfo);

  const isClientPage = isEdgePlanUser
    ? location.pathname.split('/')[2] === 'client'
    : location.pathname.split('/')[1] === 'client';
  const isProfilePage = isEdgePlanUser ? location.pathname.split('/')[4] : location.pathname.split('/')[3];

  const getFilterType: ClientTabId | string = isClientPage
    ? isProfilePage
      ? isEdgePlanUser
        ? location.pathname.split('/')[3]
        : location.pathname.split('/')[2]
      : isEdgePlanUser
      ? (location.pathname.split('/')[3] as ClientTabId) ?? ClientTabId.Active
      : (location.pathname.split('/')[2] as ClientTabId) ?? ClientTabId.Active
    : '';

  const [filterType, setFilterType] = useState<ClientTabId | string>(getFilterType);

  const clinicianId = clinicianProfile ? clinicianProfile._id : '';
  const recentlyViewed = useGetRecentlyViewed({
    storageName: 'client_viewed',
    clinicianProfileId: clinicianId,
    isEdgeAdminView,
    max: 8
  });

  useEffect(() => {
    setIsSecondTab(!pinned && isProfilePage);
  }, [pinned, isProfilePage, setIsSecondTab]);

  useEffect(() => {
    setFilterType(getFilterType);
  }, [getFilterType]);

  const clientClickHandler = () => dispatch(resetPagingAndSorting());

  const modalVisibility = useContext(ModalVisibilityContext);

  const { accountId } = useGetAccountId();
  const columnSetting = useAppSelector(selectSetting);
  const search = useAppSelector(selectSearch);
  const paging = useAppSelector(selectPaging);
  const sorting = useAppSelector(selectSorting);
  const filters = useAppSelector(selectFilters);

  const { filterValue } = useGetClientFilterValue();
  const { data: clientRecordData } = useGetClientRecordForInlineAppointmentQuery({
    accountId,
    params: {
      page: filterValue.page as number,
      perPage: filterValue.perPage,
      recordStatus: filterValue.prospectStatus
    },
    asAdmin: (isEdgeAdminView || isEdgeReceptionist) && accountId
  });

  const { data: activeClientRecordListResponse } = useGetClientRecordsListQuery(
    {
      accountId,
      payload: {
        ...getClientRecordRequestPayload({
          tabId: ClientTabId.Active,
          paging,
          sorting,
          filters,
          isEdgeUserView,
          columnSetting,
          search
        })
      }
    },
    { skip: !columnSetting }
  );

  const { data: closedClientRecordListResponse } = useGetClientRecordsListQuery(
    {
      accountId,
      payload: {
        ...getClientRecordRequestPayload({
          tabId: ClientTabId.Closed,
          paging,
          sorting,
          filters,
          isEdgeUserView,
          columnSetting,
          search
        })
      }
    },
    { skip: !columnSetting }
  );

  const { data: waitlistClientRecordListResponse } = useGetClientRecordsListQuery(
    {
      accountId,
      payload: {
        ...getClientRecordRequestPayload({
          tabId: ClientTabId.Waitlist,
          paging,
          sorting,
          filters,
          isEdgeUserView,
          columnSetting,
          search
        })
      }
    },
    { skip: !columnSetting }
  );

  const hideMatchingRecordCount =
    clientRecordData?.recordStatusCounts.active === activeClientRecordListResponse?.paging.totalItems ||
    (isEqual(search, initialState.search) && isEqual(filters, initialState.filters));

  const baseHref = `${CLIENTS.BASE}`;

  return (
    <div className={styles.container}>
      <div className={pinned || (!pinned && !isProfilePage) ? styles.displaySideMenu : styles.hideSideMenu}>
        <ButtonAlt
          icon="add_circle_outline"
          color="white"
          variant={'contained'}
          fullWidth
          onClick={() => modalVisibility?.setAddClientModalVisible(true)}
        >
          Add New Client
        </ButtonAlt>
        <SubTab
          title="VIEW BY STATUS"
          options={[
            {
              label: 'Active',
              number: clientRecordData?.recordStatusCounts.active || 0,
              value: ClientTabId.Active,
              matchingRecordCount: activeClientRecordListResponse?.paging.totalItems,
              hideMatchingRecordCount: hideMatchingRecordCount,
              href: `${baseHref}/${ClientTabId.Active}`
            },
            {
              label: 'Closed',
              number: clientRecordData?.recordStatusCounts.closed || 0,
              value: ClientTabId.Closed,
              matchingRecordCount: closedClientRecordListResponse?.paging.totalItems,
              hideMatchingRecordCount: hideMatchingRecordCount,
              href: `${baseHref}/${ClientTabId.Closed}`
            },
            ...(!isNormalUserView
              ? [
                  {
                    label: 'Waitlist',
                    number: clientRecordData?.recordStatusCounts.waitlist || 0,
                    value: ClientTabId.Waitlist,
                    matchingRecordCount: waitlistClientRecordListResponse?.paging.totalItems,
                    hideMatchingRecordCount: hideMatchingRecordCount,
                    href: `${baseHref}/${ClientTabId.Waitlist}`
                  }
                ]
              : [])
          ]}
          onChange={clientClickHandler}
          activeOption={filterType}
        />
        <ClientRecentlyViewed
          title="RECENTLY VIEWED"
          recentlyViewed={recentlyViewed.map(
            (item) => ({ ...item, href: `${baseHref}/${item.value}/profile` } as SubTabOption)
          )}
          activeOption={filterType}
        />
      </div>
      {isClientPage && !pinned && isProfilePage && <ClientProfileSubTab />}
    </div>
  );
};

export default ClientSubTab;
