import { Modal } from 'antd';
import { ReactNode, useEffect, useState } from 'react';

import styles from './ToggleSwitchV2.module.scss';
import classNames from 'classnames';

export interface ToggleList {
  id: string;
  label: string | ReactNode;
  count?: number;
  isActive: boolean;
  color?: string;
}

interface ToggleSwitchV2Props {
  id: string;
  toggleList: ToggleList[];
  onChangeStatus: (value: ToggleList) => void;
  isLoading?: boolean;
  alertOnClick?: string;
  className?: string;
  wrapperClassName?: string;
  labelClassName?: string;
  activeLabelClassName?: string;
  indicatorClassName?: string;
  size?: 'small' | 'medium' | 'large';
  t23Theme?: boolean;
}

const ToggleSwitchV2 = ({
  id,
  toggleList,
  onChangeStatus,
  isLoading,
  alertOnClick,
  className,
  wrapperClassName,
  labelClassName,
  activeLabelClassName,
  indicatorClassName,
  size = 'large',
  t23Theme
}: ToggleSwitchV2Props) => {
  const [indicatorLocation, setIndicatorLocation] = useState(0);
  const [switchList, setSwitchList] = useState(toggleList);

  // need to do this as Modal from 'antd' called multiple times
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setSwitchList(toggleList);
    const getCurrentActiveValue = toggleList.find((listObj) => listObj.isActive) as ToggleList;
    const indicatorLocation = parseFloat(`${(toggleList.indexOf(getCurrentActiveValue) / toggleList.length) * 100}`);
    setIndicatorLocation(indicatorLocation);
  }, [toggleList]);

  const pillWidth = 100 / toggleList.length;

  const mapActiveToggle = (selectedValue: ToggleList) => {
    return toggleList.map((obj) => {
      return {
        id: obj.id,
        label: obj.label,
        count: obj.count,
        isActive: selectedValue.id === obj.id,
        color: obj.color
      };
    });
  };

  const handleConfirmClick = (selectedValue: ToggleList) => {
    // need to do this as Modal from 'antd' called multiple times
    if (isModalOpen) {
      return;
    }
    const getCurrentActiveValue = switchList.find((listObj) => listObj.isActive);
    if (getCurrentActiveValue && getCurrentActiveValue.id === selectedValue.id) {
      return;
    }

    if (getCurrentActiveValue?.id === 'closed' || selectedValue.id === 'closed') {
      setIsModalOpen(true);
      Modal.confirm({
        title: alertOnClick,
        cancelText: 'Cancel',
        okText: 'Yes',
        onOk: () => {
          handleChange(selectedValue);
        },
        afterClose: () => {
          setIsModalOpen(false);
        }
      });
    } else {
      handleChange(selectedValue);
    }
  };

  const handleChange = (selectedValue: ToggleList) => {
    const getCurrentActiveValue = switchList.find((listObj) => listObj.isActive);
    if ((getCurrentActiveValue && getCurrentActiveValue.id) !== selectedValue.id) {
      const indicatorLocation = parseFloat(`${(switchList.indexOf(selectedValue) / toggleList.length) * 100}`);
      setIndicatorLocation(indicatorLocation);
      const newData = mapActiveToggle(selectedValue);
      setSwitchList(newData);
      onChangeStatus(selectedValue);
    }
  };

  return (
    <div className={classNames(styles.container, className)} id={id}>
      <div className={classNames(styles.wrapper, styles[`${size}Height`], wrapperClassName)}>
        {switchList.map((item, index) => (
          <span
            onClick={() => (!isLoading ? (alertOnClick ? handleConfirmClick(item) : handleChange(item)) : false)}
            key={index}
            className={isLoading ? styles.pillWrapperDisable : styles.pillWrapper}
            style={{ width: `${pillWidth}%` }}
          >
            <input type={'radio'} name={'option'} value={item.id} id={`toggleSwitch-${item.id}-${index}`} />
            <label
              htmlFor={`toggleSwitch-${item.id}-${index}`}
              className={
                item.isActive
                  ? classNames(
                      styles.labelActive,
                      styles[`${size}Label`],
                      t23Theme && styles.t23Label,
                      activeLabelClassName
                    )
                  : classNames(styles.label, styles[`${size}Label`], t23Theme && styles.t23Label, labelClassName)
              }
              style={{
                color: item.isActive && item.color ? 'white' : undefined
              }}
            >
              {item.label}
              {item.count !== undefined && <div className={styles.count}>{item.count}</div>}
            </label>
          </span>
        ))}
        <div
          style={{
            width: `${pillWidth}%`,
            left: `${indicatorLocation}%`,
            background: switchList.filter((list) => list.isActive)?.[0]?.color
          }}
          className={classNames(
            styles.indicator,
            styles[`${size}Indicator`],
            t23Theme && styles.t23Pill,
            indicatorClassName
          )}
        />
      </div>
    </div>
  );
};

export default ToggleSwitchV2;
