import { defaultTimezone } from 'utils/hooks/GetTimezones/getTimezones';
import { useGetAccountPackageView } from './GetAccountInfo/accountPackageView';
import { useAppSelector } from 'redux/hooks';
import { myProfileInfo } from 'redux/slice/profileDataSlice';

export const useTimeZone = () => {
  const { isEdgeAdminView, isEdgeReceptionistView } = useGetAccountPackageView();
  const { data: clinicianProfile } = useAppSelector(myProfileInfo);

  const timeZoneByView =
    isEdgeAdminView || isEdgeReceptionistView
      ? clinicianProfile.accountSettings?.timezone || defaultTimezone
      : clinicianProfile?.workTimeZone || clinicianProfile.accountSettings?.timezone || defaultTimezone;

  return {
    accountTimeZone: clinicianProfile.accountSettings?.timezone || defaultTimezone,
    clinicianWorkTimeZone: clinicianProfile?.workTimeZone || defaultTimezone,
    timeZoneByView: timeZoneByView || defaultTimezone
  };
};
