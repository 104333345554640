import { CalendarPractitionerList, WorkingScheduleInterface } from 'interfaces/Practitioners/practitionersListing';
import { AppointmentType, DeliveryType, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { RoomType } from 'interfaces/Schedule/Room';
import { AppointmentStatusOption } from 'interfaces/Schedule/Appointment';
import { MbscResource } from '@mobiscroll/react';

export type CalendarFilterSectionPropsT23 = {
  selectedFilters: CalendarFilterInterface[];
  setSelectedFilters: (selection: CalendarFilterInterface[]) => void;
  selectedDate?: Date;
  onSelectDate: (v?: Date) => void;
  selectedAppointmentFilters: CalendarAppointmentFilterInterface[];
  setSelectedAppointmentFilters: (v: CalendarAppointmentFilterInterface[]) => void;
  selectedHighLights: CalendarHighLightInterface[];
  setSelectedHighLights: (v: CalendarHighLightInterface[]) => void;
  filterList: CalendarFilter;
};

export interface CalendarFilter {
  practitionersList: CalendarPractitionerList[];
  isPractitionersListLoading: boolean;
  roomList: RoomType[];
  isRoomListLoading: boolean;
  highLightList: CalendarHighLightInterface[];
  isHighLightListLoading: boolean;
}
export interface CalendarFilterInterface {
  _id: string;
  name: string;
  avatar?: string;
  isClinician?: boolean;
  color?: string;
  workingSchedule?: WorkingScheduleInterface;
  workTimeZone?: string;
}

export interface CalendarRoomFilterInterface extends CalendarFilterInterface {}

export interface CalendarAppointmentFilterInterface {
  _id: string;
  name: string;
  color: string;
}

export interface CalendarHighLightInterface {
  _id: string;
  name: string;
  color: string;
  appointmentType?: ParticipantType | string | DeliveryType.Bespoke;
  deliveryMode?: DeliveryType;
  status?: AppointmentStatusOption;
  sessionType?: AppointmentType;
}

export interface CalendarFilterProps {
  selectedId?: string;
  allowEmpty?: boolean;
  backgroundColor?: string;
  includePractice?: boolean;
  excludedFilterIds: string[];
  isDataFetching: boolean;
  filterList: CalendarFilterInterface[];
  onRemove?: () => void;
  onSelectFilter: (filter?: CalendarFilterInterface) => void;
}

export enum CalendarLayout {
  blended = 'blended',
  timeline = 'timeline',
  mc = 'mc'
}

interface CalendarViewSettings {
  renderCustomResource?: any;
  resources?: MbscResource[] | null | undefined;
  renderDay?: any;
  groupBy?: 'resource' | 'date';
}

export type CalendarViewConfig = Record<CalendarLayout, CalendarViewSettings>;
