import { notification } from 'antd';
import { ProfileInterface } from 'interfaces/Profile/Profile';
import { SALUTATION_OPTIONS } from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/components/ControlPanelContentDisplay/components/ProfileAboutYou/ProfileAboutYou';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getClinicianProfile, getClinicianProfileById } from '../../http/ClinicianProfileService/Profile/profile';
import { useGetAccountId } from '../GetAccountInfo/getAccountId';
import { security } from 'utils/security';
import { useNavigate } from 'react-router-dom';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';
import { IS_PROCARE_APP } from '../../brand';
import { useDispatch } from 'react-redux';
import {
  useGetClinicianProfileQuery,
  useGetPracticeInfoQuery
} from 'redux/endpoints/clinicianProfileServices/clinicianProfile';
import { setPracticeData, setPracticeDataLoading } from 'redux/slice/practiceDataSlice';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import {
  defaultCaseLoad,
  defaultWorkingSchedule,
  setProfileData,
  setProfileDataLoading
} from 'redux/slice/profileDataSlice';

export const useFetchProfile = () => {
  const navigate = useNavigate();

  const [profile, setProfile] = useState<ProfileInterface>({} as ProfileInterface);
  const [isProfileLoading, setIsProfileLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const token = await security.getAccessTokenSilently().catch(() => undefined);

      if (token) {
        try {
          const response = await getClinicianProfile(token);
          const data = await response.json();

          const massageData = {
            ...data,
            consultantLocations:
              data.consultantLocations && data.consultantLocations.length > 0 ? data.consultantLocations : [''],
            caseLoad: data.caseLoad ? data.caseLoad : defaultCaseLoad,
            workingSchedule: data.workingSchedule ? data.workingSchedule : defaultWorkingSchedule,
            title: data?.title
              ? SALUTATION_OPTIONS.find((obj) => obj.value === data.title)?.value !== undefined
                ? data.title
                : 'Other'
              : 'None',
            titleValue: data.title
          };

          setProfile(massageData);
        } catch (ex) {
          // is 403 and is procare, check if token expired
          if (isErrorBentStatusError(ex) && ex.statusCode === 403 && IS_PROCARE_APP) {
            const { exp } = jwtDecode<JwtPayload>(token);

            if (new Date((exp || 0) * 1000) > new Date()) {
              // token isn't expired, probably orphan Auth0 account
              navigate('/signup/claim');
              return;
            }
          }

          throw ex;
        }
        setIsProfileLoading(false);
      }
    })();
  }, [navigate]);

  return { profile, isProfileLoading, setProfile };
};

export const useFetchProfileById = (clinicianId: string) => {
  const [t] = useTranslation();

  const [profile, setProfile] = useState<ProfileInterface>();
  const [isProfileLoading, setIsProfileLoading] = useState(true);

  const fetchProfile = async (clinicianId: string) => {
    try {
      const token = await security.getAccessTokenSilently();

      const response = await getClinicianProfileById(token, clinicianId);
      const data = await response.json();

      const massageData = {
        ...data,
        consultantLocations: data.consultantLocations.length > 0 ? data.consultantLocations : [''],
        caseLoad: data.caseLoad ? data.caseLoad : defaultCaseLoad,
        workingSchedule: data.workingSchedule ? data.workingSchedule : defaultWorkingSchedule,
        title: data?.title
          ? SALUTATION_OPTIONS.find((obj) => obj.value === data.title)?.value !== undefined
            ? data.title
            : 'Other'
          : 'None',
        titleValue: data.title
      };

      setProfile(massageData);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: t('form.error.fetch_practitioner_profile') });
    }

    setIsProfileLoading(false);
  };

  useEffect(() => {
    if (clinicianId) {
      fetchProfile(clinicianId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicianId]);

  return { profile, isProfileLoading, setProfile, fetchProfile };
};

export const useFetchPracticeData = () => {
  const { accountId } = useGetAccountId();

  const dispatch = useDispatch();
  const { data: practiceData, isLoading: isPracticeDataLoading } = useGetPracticeInfoQuery(
    { accountId },
    { skip: !accountId }
  );

  useEffect(() => {
    if (isPracticeDataLoading) {
      dispatch(setPracticeDataLoading());
    }

    if (practiceData) {
      dispatch(setPracticeData(practiceData));
    }
  }, [practiceData, isPracticeDataLoading, dispatch]);

  return { practiceData, isPracticeDataLoading };
};

export const useFetchProfileData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: clinicianProfileData, isLoading: isClinicianProfileDataLoading, error } = useGetClinicianProfileQuery();

  useEffect(() => {
    if (isClinicianProfileDataLoading) {
      dispatch(setProfileDataLoading());
    }

    if (clinicianProfileData) {
      dispatch(setProfileData(clinicianProfileData));
    }

    if (error && 'status' in error && error.status === 403 && IS_PROCARE_APP) {
      navigate('/signup/claim');
      return;
    }
  }, [clinicianProfileData, isClinicianProfileDataLoading, dispatch, error, navigate]);

  return { clinicianProfileData, isClinicianProfileDataLoading };
};
