import { Dropdown, MenuProps, notification } from 'antd';
import IconButton from 'components/IconButton/IconButton';
import {
  AppointmentSlots,
  AppointmentStatusOption,
  AppointmentStatusType,
  ServiceDeliveredNameMapping,
  TelehealthRecordingComposition
} from 'interfaces/Schedule/Appointment';
import { debounce } from 'lodash';
import { getAppointmentMarkOption } from 'pages/Calendar/components/Modals/EventInformationModal/components/MarkStatus/MarkStatus';
import InvoiceModal from 'pages/Invoices/components/InvoiceListing/components/InvoiceModal/InvoiceModal';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAccessToken } from 'utils/hooks/token';
import { useToggle } from 'utils/hooks/useToggle';
import { updateAppointmentStatus } from 'utils/http/appointment';
import AppointmentsListItem from '../../../AppointmentsListItem/AppointmentsListItem';
import styles from './CompletedCardItem.module.scss';
import AppointmentActionsDropdown from 'pages/Calendar/components/Modals/EventInformationModal/components/EventDetails/components/AppointmentActionsDropdown/AppointmentActionsDropdown';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import EventInformationModal from 'pages/Calendar/components/Modals/EventInformationModal/EventInformationModal';
import IconTag from 'components/IconTag/IconTag';
import { resetProcessAppointment } from 'redux/processAppointment/processAppointmentSlice';
import { useAppDispatch } from 'redux/hooks';
import classnames from 'classnames';
import { useGetPackageBookableAppointmentQuery } from 'redux/endpoints/scheduleServices/package';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import moment from 'moment';
import { MOMENTJS_DAY_DATE_MONTH_YEAR_FORMAT, MOMENTJS_TIME_FORMAT_DAY_PARTS } from 'utils/dateChecker';

interface CompletedCardItemProps {
  appointment: AppointmentSlots;
  practiceName: string;
  refetchAppointment: () => void;
  onViewAppointmentRecording: (params: {
    appointmentId: string;
    recordingId: string;
    recordings: TelehealthRecordingComposition[];
  }) => void;
}

const CompletedCardItem = ({
  appointment,
  practiceName,
  refetchAppointment,
  onViewAppointmentRecording
}: CompletedCardItemProps) => {
  const { token } = useGetAccessToken();
  const dispatch = useAppDispatch();
  const { accountId } = useGetAccountId();
  const [appointmentTags, setAppointmentTags] = useState(appointment.markedStatus || []);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | undefined>();
  const [showInvoiceModal, toggleShowInvoiceModal] = useToggle();
  const [showRecordingMenu, toggleShowRecordingMenu] = useToggle();
  const [showInvoiceMenu, toggleShowInvoiceMenu] = useToggle();
  const [showExpandedView, toggleExpandedView] = useToggle();
  const [showAddTagMenu, toggleShowAddTagMenu] = useToggle();
  const [t] = useTranslation();
  const { isChargeAppointmentTagEnabled, isProcessAppointmentEnabled } = useGetFeatureToggle();

  const [showRescheduleModal, toggleShowRescheduleModal] = useToggle();
  const [showProcessAppointment, toggleShowProcessAppointment] = useToggle();
  const isReservedAppointment = appointment.type === AppointmentStatusType.Reserved;

  const debouncedSubmitTags = useMemo(
    () =>
      debounce(async (newAppointmentTags) => {
        try {
          await updateAppointmentStatus(token, appointment._id, newAppointmentTags);
          refetchAppointment();
          notification.success({
            message: 'Tags updated',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
        } catch (ex) {
          console.error(ex);
          notification.error({
            message: 'Tags update fail',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
        }
      }, 1000),
    [token, refetchAppointment, appointment._id]
  );

  useEffect(() => {
    setAppointmentTags(appointment.markedStatus || []);
  }, [appointment.markedStatus, setAppointmentTags]);

  const { data: assignmentPackageList } = useGetPackageBookableAppointmentQuery(
    {
      accountId: accountId,
      clientRecordId: appointment.clientRecord?._id || ''
    },
    { skip: !appointment.clientRecord?._id }
  );

  const getPackageDetails = useMemo(
    () => assignmentPackageList?.find((aPackageObj) => aPackageObj.assignee._id === appointment.packageAssigneeId),
    [assignmentPackageList, appointment.packageAssigneeId]
  );

  const handleAddTags = async ({ key }: { key: string }) => {
    toggleShowAddTagMenu();
    const newAppointmentTags = [...appointmentTags, key] as AppointmentStatusOption[];
    await debouncedSubmitTags(newAppointmentTags);
    setAppointmentTags(newAppointmentTags);
  };

  const handleRemoveStatus = async (status: string) => {
    if (status) {
      const cache = [...appointmentTags];
      try {
        const newTags = appointmentTags.filter((tag) => tag !== status);
        setAppointmentTags(newTags);
        await updateAppointmentStatus(token, appointment._id, newTags);
        refetchAppointment();
      } catch (err) {
        setAppointmentTags([...cache]);
        console.error(err);
        notification.error({
          message: t('client_appointment.event_tag_remove.error_notification')
        });
      }
    }
  };

  // const getStatusTag = (statuses?: AppointmentStatusOption[]) => {
  //   if (isAppointmentTagsCancelled(statuses)) {
  //     return (
  //       <Tag color="red" className={styles.statusTag}>
  //         Cancelled
  //       </Tag>
  //     );
  //   } else if (statuses?.some((status) => status === AppointmentStatusOption.Attended)) {
  //     return (
  //       <Tag color="green" className={styles.statusTag}>
  //         Attended
  //       </Tag>
  //     );
  //   }
  // };

  const recordingDropdownMenuItems: MenuProps['items'] = useMemo(
    () =>
      appointment.telehealthRecordingCompositions?.map((recording) => ({
        label: moment(recording.createdAt).format(
          `${MOMENTJS_DAY_DATE_MONTH_YEAR_FORMAT} ${MOMENTJS_TIME_FORMAT_DAY_PARTS}`
        ),
        key: recording._id
      })),
    [appointment.telehealthRecordingCompositions]
  );

  const invoiceDropdownMenuItems: MenuProps['items'] = useMemo(
    () =>
      appointment.invoices?.map((option) => ({
        label: option._id,
        key: option._id
      })),
    [appointment.invoices]
  );

  const tagDropdownMenuItems: MenuProps['items'] = useMemo(
    () =>
      getAppointmentMarkOption(isChargeAppointmentTagEnabled)
        .filter((mark) => appointmentTags?.indexOf(mark.value) === -1)
        .map((option) => ({
          label: option.label,
          key: option.value
        })),
    [appointmentTags, isChargeAppointmentTagEnabled]
  );

  const handleRecordingMenuItemClick = ({ key }: { key: string }) => {
    toggleShowRecordingMenu();
    onViewAppointmentRecording({
      appointmentId: appointment._id,
      recordingId: key,
      recordings: appointment.telehealthRecordingCompositions || []
    });
  };

  const handleInvoiceMenuItemClick = ({ key }: { key: string }) => {
    toggleShowInvoiceMenu();
    setSelectedInvoiceId(key);
    toggleShowInvoiceModal();
  };

  const handleCloseProcessAppointmentModal = () => {
    dispatch(resetProcessAppointment());
    toggleShowProcessAppointment();
  };

  return (
    <AppointmentsListItem appointment={appointment} practiceName={practiceName} showExpandedView={showExpandedView}>
      <div className={styles.appointmentInfo}>
        <div className={styles.sessionDetails}>{appointment.sessionTypeName}</div>
        {getPackageDetails && (
          <div className={styles.packageItemDetails}>
            <div className={styles.packageTitle}>{getPackageDetails.name}</div>
            <div className={styles.funderDetails}>{getPackageDetails.assignee.funder.name}</div>
          </div>
        )}
      </div>
      {/*
        Chris say the status tag will be needed in the future
        https://www.figma.com/file/gmRpt15qzGr88bhYW2QoPq?node-id=7586:224733#429369661
      */}
      {/*{false && <div className={styles.status}>{getStatusTag(appointment.markedStatus)}</div>}*/}
      <div className={styles.tagContainer}>
        {appointmentTags.map((status, index) => (
          <div
            className={classnames(styles.markedStatus, isProcessAppointmentEnabled && styles.markStatusWithoutClose)}
            key={index}
          >
            {status}
            {!isProcessAppointmentEnabled && (
              <div className={styles.iconContainer} onClick={() => handleRemoveStatus(status)}>
                <i className={`material-icons-outlined ${styles.icon}`}>close</i>
              </div>
            )}
          </div>
        ))}
        {appointment.isProcessed && (
          <IconTag
            iconName="playlist_add_check"
            text={
              (appointment.serviceDelivered && ServiceDeliveredNameMapping[appointment.serviceDelivered]) || 'Processed'
            }
          />
        )}
        {!appointment.isProcessed && appointment.requestedChanges && (
          <IconTag
            tagContainerClass={styles.reviewingTag}
            iconClass={styles.reviewingIcon}
            iconName="flag_circle"
            text="Reviewing"
          />
        )}
      </div>
      {Boolean(appointment.telehealthRecordingCompositions?.length) &&
        (appointment.telehealthRecordingCompositions!.length === 1 ? (
          <IconButton
            iconName="ondemand_video"
            onClick={() => {
              handleRecordingMenuItemClick({ key: appointment.telehealthRecordingCompositions![0]._id });
            }}
          />
        ) : (
          <Dropdown
            arrow
            menu={{ items: recordingDropdownMenuItems, onClick: handleRecordingMenuItemClick }}
            onOpenChange={toggleShowRecordingMenu}
            placement="bottomRight"
            trigger={['click']}
          >
            <IconButton iconName="ondemand_video" active={showRecordingMenu} />
          </Dropdown>
        ))}
      {Boolean(appointment.invoices?.length) &&
        (appointment.invoices?.length === 1 ? (
          <IconButton
            iconName="receipt"
            active={showInvoiceMenu || showInvoiceModal}
            onClick={() => {
              toggleShowInvoiceModal();
              setSelectedInvoiceId(appointment.invoices![0]._id);
            }}
          />
        ) : (
          <Dropdown
            arrow
            menu={{ items: invoiceDropdownMenuItems, onClick: handleInvoiceMenuItemClick }}
            onOpenChange={toggleShowInvoiceMenu}
            placement="bottomRight"
            trigger={['click']}
          >
            <IconButton iconName="receipt" active={showInvoiceMenu} />
          </Dropdown>
        ))}
      <IconButton active={showExpandedView} iconName="history" onClick={toggleExpandedView} />
      {!isProcessAppointmentEnabled && (
        <Dropdown
          arrow
          menu={{ items: tagDropdownMenuItems, onClick: handleAddTags }}
          onOpenChange={toggleShowAddTagMenu}
          placement="bottomRight"
          trigger={['click']}
        >
          <IconButton iconName="label" active={showAddTagMenu} />
        </Dropdown>
      )}
      <InvoiceModal visible={showInvoiceModal} onClose={toggleShowInvoiceModal} invoiceId={selectedInvoiceId} />
      {!isReservedAppointment &&
        !appointment.isProcessed &&
        isProcessAppointmentEnabled &&
        !appointment.requestedChanges && (
          <AppointmentActionsDropdown
            isFromCalendarView={false}
            handleReschedule={() => toggleShowRescheduleModal()}
            appointment={appointment}
            token={token}
            handleShowProcessAppointment={() => toggleShowProcessAppointment()}
            onFinishMarkStatus={() => refetchAppointment()}
          />
        )}
      {(showProcessAppointment || showRescheduleModal) && (
        <EventInformationModal
          isEditing
          visible={showProcessAppointment || showRescheduleModal}
          onClose={showProcessAppointment ? toggleShowProcessAppointment : toggleShowRescheduleModal}
          appointmentId={appointment._id}
          appointment={appointment}
          onEditComplete={refetchAppointment}
          hideEditGroupBtn
          group={0}
          onCancelAppointmentComplete={refetchAppointment}
          isProcessAppointmentModalShow={showProcessAppointment}
          onCloseProcessAppointmentModal={handleCloseProcessAppointmentModal}
        />
      )}
    </AppointmentsListItem>
  );
};

export default CompletedCardItem;
