import { useAppSelector } from 'redux/hooks';
import { myProfileInfo } from 'redux/slice/profileDataSlice';

export const useGetAccountSettings = () => {
  const {
    data: { accountSettings, profileSettings }
  } = useAppSelector(myProfileInfo);

  return {
    accountSettings,
    profileSettings
  };
};
