import { useMemo, useState } from 'react';
import styles from './UpComingCardItem.module.scss';
import { AppointmentSlots, TelehealthRoomType } from 'interfaces/Schedule/Appointment';
import EventInformationModal from 'pages/Calendar/components/Modals/EventInformationModal/EventInformationModal';
import ButtonAlt from 'components/v2/ButtonAlt/ButtonAlt';
import { DeliveryType } from 'interfaces/Schedule/AppointmentType';
import { useToggle } from 'utils/hooks/useToggle';
import IconButton from 'components/IconButton/IconButton';
import AppointmentsListItem from '../../AppointmentsListItem/AppointmentsListItem';
import { getDeliveryTypeLabel } from 'utils/appointment';
import { useAppDispatch } from 'redux/hooks';
import { resetProcessAppointment, setIsCancel } from 'redux/processAppointment/processAppointmentSlice';
import { useGetPackageBookableAppointmentQuery } from 'redux/endpoints/scheduleServices/package';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';

interface UpComingCardItemProps {
  appointment: AppointmentSlots;
  practiceName: string;
  refetchAppointment: () => void;
}

const UpComingCardItem = ({ appointment, practiceName, refetchAppointment }: UpComingCardItemProps) => {
  const { accountId } = useGetAccountId();
  const TELE_UI_URL = process.env.REACT_APP_TELE_UI_URL!;
  const dispatch = useAppDispatch();

  const deliveryType = getDeliveryTypeLabel(appointment.deliveryType, appointment.otherInstructions);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showExpandedView, toggleExpandedView] = useToggle();
  const [showProcessAppointment, toggleShowProcessAppointment] = useToggle();

  const { data: assignmentPackageList } = useGetPackageBookableAppointmentQuery(
    {
      accountId: accountId,
      clientRecordId: appointment.clientRecord?._id || ''
    },
    { skip: !appointment.clientRecord?._id }
  );

  const getPackageDetails = useMemo(
    () => assignmentPackageList?.find((aPackageObj) => aPackageObj.assignee._id === appointment.packageAssigneeId),
    [assignmentPackageList, appointment.packageAssigneeId]
  );

  const handleCloseProcessAppointmentModal = () => {
    dispatch(resetProcessAppointment());
    toggleShowProcessAppointment();
  };

  const handleCancelAppointment = () => {
    dispatch(setIsCancel(true));
    toggleShowProcessAppointment();
  };

  const telehealthUrl = `${TELE_UI_URL}/room/${appointment.telehealthRoomId}?type=${
    deliveryType === DeliveryType.PhoneCall
      ? TelehealthRoomType.Voice
      : deliveryType === DeliveryType.VideoCall
      ? TelehealthRoomType.Video
      : ''
  }`;
  const voiceCallUrl = `${TELE_UI_URL}/calls/${appointment._id}`;

  return (
    <AppointmentsListItem appointment={appointment} practiceName={practiceName} showExpandedView={showExpandedView}>
      <div className={styles.appointmentDetails}>
        <div className={styles.appointmentTypeAndBookedBy}>
          <div className={styles.appointmentType}>
            {appointment.sessionTypeName} | {deliveryType}
          </div>
          {getPackageDetails && (
            <div className={styles.packageItemDetails}>
              <div className={styles.packageTitle}>{getPackageDetails.name}</div>
              <div className={styles.funderDetails}>{getPackageDetails.assignee.funder.name}</div>
            </div>
          )}
        </div>
        <div className={styles.videoLink}>
          {appointment.telehealthRoomId &&
            appointment.deliveryType &&
            ([DeliveryType.VideoCall, DeliveryType.PhoneCall].includes(appointment.deliveryType) ? (
              <a href={telehealthUrl} target="_blank" rel="noopener noreferrer">
                <ButtonAlt size={'small'} variant={'text'}>
                  <div className={styles.overflowWrap}>{telehealthUrl}</div>
                </ButtonAlt>
              </a>
            ) : (
              appointment.deliveryType === DeliveryType.PhoneCallDialClient && (
                <a href={voiceCallUrl} target="_blank" rel="noopener noreferrer">
                  <ButtonAlt size={'small'} variant={'text'}>
                    <div className={styles.overflowWrap}>{voiceCallUrl}</div>
                  </ButtonAlt>
                </a>
              )
            ))}
        </div>
      </div>
      <div className={styles.actionContainer}>
        <IconButton active={showExpandedView} iconName="history" onClick={toggleExpandedView} />
        <IconButton
          iconName="edit"
          onClick={() => {
            setShowEditModal(true);
          }}
        />
        {showEditModal && (
          <EventInformationModal
            visible={showEditModal}
            onClose={() => {
              setShowEditModal(false);
            }}
            appointmentId={appointment._id}
            appointment={appointment}
            hideEditGroupBtn
            onEditComplete={() => refetchAppointment()}
            onCancelAppointmentComplete={() => refetchAppointment()}
            group={0}
            handleShowProcessAppointment={handleCancelAppointment}
            isProcessAppointmentModalShow={showProcessAppointment}
            onCloseProcessAppointmentModal={handleCloseProcessAppointmentModal}
          />
        )}
      </div>
    </AppointmentsListItem>
  );
};

export default UpComingCardItem;
