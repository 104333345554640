import {
  Eventcalendar,
  MbscEventcalendarView,
  MbscPageLoadingEvent,
  MbscCellClickEvent,
  momentTimezone,
  setOptions,
  MbscResource,
  MbscCalendarColor
} from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.scss';
import { notification } from 'antd';
import RoomLabel from 'components/v2/CalendarFilter/components/RoomLabel/RoomLabel';
import { CalendarFilterInterface, CalendarLayout, CalendarViewConfig } from 'components/v2/CalendarFilter/interfaces';
import ProfileBadge from 'components/v2/ProfileBadge/ProfileBadge';
import { AppointmentSlots, AppointmentStatusType, ServiceDelivered } from 'interfaces/Schedule/Appointment';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import EventInformationModal from 'pages/Calendar/components/Modals/EventInformationModal/EventInformationModal';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState, useRef, CSSProperties, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { getTimeZoneDateTime } from 'utils/hooks/GetTimezones/getTimezones';
import { useGetAccessToken } from 'utils/hooks/token';
import { getAppointmentById } from 'utils/http/ScheduleService/Appointments/Appointments';
import { CALENDAR_FILTER_COLOURS_VALUE } from '../CalendarFilterSection/components/CalendarFilterCheckList/components/FilterColorBox/FilterColorBox';
import styles from './CalendarView.module.scss';
import CalendarEvent, { CustomCalendarEvent } from './components/CalendarEvent/CalendarEvent';
import CalendarHeader from './components/CalendarHeader/CalendarHeader';
import classNames from 'classnames';
import { convertUtcTimeToClinicianTime } from 'utils/helpers/timezone';
import {
  AppointmentHumanFactorStep,
  AppointmentViewType,
  resetAppointmentData,
  selectAppointmentData,
  setAppointmentView,
  setCurrentStep,
  setOriginalDateTime,
  setPractitionerMainOptions,
  setSelectedDate,
  setSelectedPractitioner,
  setSelectedTime,
  setStartValidation
} from 'redux/features/appointmentCreation/appointmentCreationSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { IOptionItem } from 'components/v2/DropdownSearchable/OptionItem';
import InlineBookingModal from './components/InlineBookingModal/InlineBookingModal';
import {
  SCHEDULE_ITEM_CLASS,
  FIRST_HALF_HOUR_CLASS,
  HALF_PAST_HOUR_CLASS,
  MARK_TO_APPT_CREATION_CLASS,
  DEFAULT_APPT_RANGE,
  AUTO_START_VALUE,
  convertDateWithTimezone,
  calculateInlineModalPosition
} from './utils';
import { useWindowSize } from 'utils/useWindowSize';
import { resetProcessAppointment } from 'redux/processAppointment/processAppointmentSlice';
import { useFetchFilterList } from '../CalendarFilterSection/hooks/GetFilterList';
import { scheduleServicesApiSlice, SSTagTypes } from 'redux/services/scheduleServicesApiSlice';
import {
  calendarFilters,
  calendarLayout,
  calendarSelectedDate,
  calendarSettings,
  setCalendarSelectedDate
} from 'redux/calendarAppointmentList/calendarAppointmentListDataSlice';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { SubTabContext } from 'layouts/AuthLayoutT23/context/SubTabContextProvider';
import { myProfileInfo } from 'redux/slice/profileDataSlice';

const TIME_CELL_STEP = 60;

setOptions({
  theme: 'material',
  themeVariant: 'light'
});

interface CalendarViewProps {
  calendarDate?: MbscPageLoadingEvent;
  setCalendarDate: (v: MbscPageLoadingEvent) => void;
  events: CustomCalendarEvent[];
  isLoading?: boolean;
  appointments: AppointmentSlots[];
  isShowInlineAppointment: {
    position: 'default' | 'custom';
    showModal: boolean;
  };
  setShowInlineAppointment: (value: boolean) => void;
  setShowOtherAppointmentBooking: () => void;
}

export const FREE_BUSY_APPOINTMENT_TYPES = [AppointmentStatusType.Free, AppointmentStatusType.Busy];

type WeekDays = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

const CalendarView = ({
  setCalendarDate,
  events,
  appointments,
  isLoading,
  isShowInlineAppointment,
  setShowInlineAppointment,
  setShowOtherAppointmentBooking
}: CalendarViewProps) => {
  const calendarRef = useRef<Eventcalendar>(null);
  const calendarContentRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { timeZoneByView: timezone } = useTimeZone();
  const { showSubTab } = useContext(SubTabContext);
  const { accountId } = useGetAccountId();
  const { token } = useGetAccessToken();
  const [selectedAppointmentId, setSelectedAppointmentId] = useState<string>();
  const [selectedAppointment, setSelectedAppointment] = useState<AppointmentSlots | undefined>();
  const [targetAppointment, setTargetAppointment] = useState<AppointmentSlots>();
  const [calendarViewSize, setCalendarViewSize] = useState({
    width: `${calendarContentRef?.current?.offsetWidth}px` || '75vw',
    height: `${calendarContentRef?.current?.offsetHeight}px` || '400px'
  });

  const { selectedClinicians, selectedRooms } = useAppSelector(calendarFilters);
  const calendarViewLayout = useAppSelector(calendarLayout);
  const {
    zoom2x,
    dayWeekView,
    displayCancelledAppointment,
    displayWorkingSchedule,
    workingScheduleColor,
    startTimeValue
  } = useAppSelector(calendarSettings);

  const { isEdgeAdminOrReceptionistView } = useGetAccountPackageView();
  const { data: clinicianProfile } = useAppSelector(myProfileInfo);

  const [selectedAppointmentGroup, setSelectedAppointmentGroup] = useState<number>(0);
  const [isRefetchingAppointmentAfterProcess, setIsRefetchingAppointmentAfterProcess] = useState<boolean>(false);

  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const { appointmentId, serviceDelivered }: { appointmentId?: string; serviceDelivered?: string } =
    queryString.parse(search);

  const { selectedDate, selectedTime, selectedPractitioner } = useAppSelector(selectAppointmentData);
  const selectedCalendarDate = useAppSelector(calendarSelectedDate);
  const { practitionersList, highLightList } = useFetchFilterList();

  const [width, height] = useWindowSize();

  useEffect(() => {
    setCalendarViewSize({
      width: `${width - (showSubTab ? 360 : 115)}px` || '75vw',
      height: `${height - 90}px` || '400px'
    });
  }, [width, height, showSubTab]);

  const isTimelineCalendarView = useMemo(() => {
    return calendarViewLayout === CalendarLayout.timeline;
  }, [calendarViewLayout]);

  const isMultipleClinicianView = useMemo(() => {
    return [CalendarLayout.mc, CalendarLayout.timeline].includes(calendarViewLayout);
  }, [calendarViewLayout]);

  const currentDateTime = useMemo(() => {
    return calendarRef.current !== null
      ? selectedCalendarDate && startTimeValue !== AUTO_START_VALUE
        ? moment(selectedCalendarDate).set({
            hour: parseInt(startTimeValue, 10),
            minute: 0,
            second: 0,
            millisecond: 0
          })
        : moment(selectedCalendarDate)
      : undefined;

    // It’s necessary to listen to calendarRef for reruns after the calendar is rendered.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCalendarDate, startTimeValue, calendarRef.current]);

  const [hoverStateEvent, setHoverStateEvent] = useState<CustomCalendarEvent>();
  const [inlineBookingFormPositions, setInlineBookingFormPositions] = useState<CSSProperties>();

  const [isProcessAppointmentModalShow, setIsProcessAppointmentModalShow] = useState<boolean>(false);

  useEffect(() => {
    if (selectedClinicians?.length) {
      const practitionerMainOptions: IOptionItem[] = selectedClinicians.map(
        (practitioner: CalendarFilterInterface) => ({
          value: practitioner._id,
          label: practitioner.name
        })
      );
      dispatch(setPractitionerMainOptions(practitionerMainOptions));
    }
  }, [selectedClinicians, dispatch]);

  /**
   * Listen date and time change to update hover-state
   */
  useEffect(() => {
    if (selectedDate && selectedTime) {
      const data = {
        start: convertDateWithTimezone(new Date(`${selectedDate} ${selectedTime.startTime}`), timezone),
        end: convertDateWithTimezone(new Date(`${selectedDate} ${selectedTime.endTime}`), timezone),
        type: 'hover-state',
        isActivity: false,
        ...(selectedPractitioner && {
          resource: selectedPractitioner._id
        })
      };
      dispatch(setCalendarSelectedDate(new Date(selectedDate)));
      setHoverStateEvent(data);
    }
  }, [selectedDate, selectedTime, timezone, selectedPractitioner, dispatch]);

  // To be the assigned to data property of the calendar component
  const calendarEvents = useMemo(() => {
    const filteredEvents = isMultipleClinicianView
      ? events.filter(
          (eventObj, index) =>
            index === events.findIndex((e) => e.id === eventObj.id && e.isRoomFilter === eventObj.isRoomFilter)
        )
      : events.filter((event) => !event.isRoomFilter);

    // Include hover state event if it exists
    const eventsWithHoverState = hoverStateEvent ? [...filteredEvents, hoverStateEvent] : filteredEvents;

    // Filter out cancelled events if needed
    return displayCancelledAppointment
      ? eventsWithHoverState
      : eventsWithHoverState.filter((event) => !event.isCancelled);
  }, [events, isMultipleClinicianView, displayCancelledAppointment, hoverStateEvent]);

  // set calendar cells background color
  const calendarCellsColors = useMemo(() => {
    const localTimeZone = moment.tz.guess();
    const todayFormatted = moment().format('DD-MM-YYYY');

    const todayStart = momentTz.tz(`${todayFormatted} 00:00:00`, 'DD-MM-YYYY HH:mm', timezone).tz(localTimeZone);
    const todayEnd = momentTz.tz(`${todayFormatted} 23:59:59`, 'DD-MM-YYYY HH:mm', timezone).tz(localTimeZone);

    // Highlight today
    const todayColor: MbscCalendarColor[] = [
      {
        start: todayStart,
        end: todayEnd,
        background: '#3f52ff0d'
      }
    ];

    if (!displayWorkingSchedule) {
      return todayColor;
    }

    if (calendarRef.current) {
      const { _firstDay: firstDay } = calendarRef.current;
      const startDate = moment(firstDay).startOf('day');

      const CURRENT_WEEK_DATE = {
        monday: startDate.clone().day(1).toDate(), // Monday of the week
        tuesday: startDate.clone().day(2).toDate(), // Tuesday of the week
        wednesday: startDate.clone().day(3).toDate(), // Wednesday of the week
        thursday: startDate.clone().day(4).toDate(), // Thursday of the week
        friday: startDate.clone().day(5).toDate(), // Friday of the week
        saturday: startDate.clone().day(6).toDate(), // Saturday of the week
        sunday: startDate.clone().day(7).toDate() // Sunday of the week
      };

      const workingScheduleCell: MbscCalendarColor[] = [
        {
          start: '00:00',
          end: '00:00',
          background: workingScheduleColor,
          recurring: {
            repeat: 'daily'
          }
        }
      ];

      // loop creating color config for available time slots
      selectedClinicians.forEach((practitionerObj) => {
        const { workingSchedule, workTimeZone, _id } = practitionerObj;

        if (_id === '') {
          workingScheduleCell.push({
            start: '00:00',
            end: '00:00',
            background: '#fff',
            resource: 'practiceId',
            recurring: {
              repeat: 'daily'
            }
          });
        }

        if (workingSchedule) {
          Object.entries(workingSchedule).forEach(([day, { isActive, timeSlots }]) => {
            if (isActive) {
              timeSlots.forEach((slot: any) => {
                const start = moment(CURRENT_WEEK_DATE[day as WeekDays]).startOf('day');
                const startTimeFormat = momentTz
                  .tz(`${start.format('DD-MM-YYYY')} ${slot.startTime}`, 'DD-MM-YYYY HH:mm', workTimeZone || timezone)
                  .tz(localTimeZone);

                const endTimeFormat = momentTz
                  .tz(`${start.format('DD-MM-YYYY')} ${slot.endTime}`, 'DD-MM-YYYY HH:mm', workTimeZone || timezone)
                  .tz(localTimeZone);

                workingScheduleCell.push({
                  start: startTimeFormat,
                  end: endTimeFormat,
                  background: '#fff',
                  resource: _id
                });
              });
            }
          });
        }
      });

      selectedRooms.forEach((roomObj) => {
        workingScheduleCell.push({
          start: '00:00',
          end: '00:00',
          ...(calendarViewLayout !== CalendarLayout.blended && {
            background: '#fff'
          }),
          resource: roomObj._id,
          recurring: {
            repeat: 'daily'
          }
        });
      });

      return workingScheduleCell;
    }

    return todayColor;

    // This is necessary to listen changes of calendarRef.current?._firstDay else only one week will show working schedule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    calendarViewLayout,
    displayWorkingSchedule,
    selectedClinicians,
    selectedRooms,
    calendarRef.current?._firstDay,
    workingScheduleColor,
    timezone
  ]);

  const getSelectedAppointment = async () => {
    if (selectedAppointmentId) {
      const [foundAppointment] = await Promise.all([appointments.find(({ _id }) => _id === selectedAppointmentId)]);

      const newAppointment = {
        ...foundAppointment,
        ...(foundAppointment?.startDateTime &&
          foundAppointment?.endDateTime &&
          convertUtcTimeToClinicianTime({
            startDateTime: moment(foundAppointment.startDateTime).toDate(),
            endDateTime: moment(foundAppointment.endDateTime).toDate(),
            timezone
          }))
      } as AppointmentSlots;

      setTimeout(() => {
        setSelectedAppointment(newAppointment);
      }, 10);
    }
    setSelectedAppointment(undefined);
  };

  useEffect(() => {
    getSelectedAppointment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAppointmentId, selectedClinicians, selectedRooms, timezone]);

  momentTimezone.moment = momentTz;

  const queryAppointment = useCallback(
    async (appointmentId: string) => {
      try {
        const appointmentResponse = await getAppointmentById(token, accountId, appointmentId);
        const appointment = await appointmentResponse.json();
        setTargetAppointment(appointment);
        dispatch(setCalendarSelectedDate(new Date(appointment.date)));
        setSelectedAppointmentId(appointmentId);
        notification.success({
          message: 'Appointment info fetched.',
          closeIcon: <span className={'success'}>OK</span>
        });
      } catch (ex) {
        console.error(ex);
        notification.error({
          message: 'Failed to fetch appointment info.',
          duration: 2,
          closeIcon: <span className={'success'}>OK</span>
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, token]
  );

  // docs -> https://docs.mobiscroll.com/5-25-1/react/eventcalendar#event-onPageLoading
  // This is the part causing warning in the console log is caused `while rendering a different component (`t`)`. It's necessary because we need to determine which page of Mobiscroll is being displayed, so the correct endpoint can be called.
  const onPageLoading = useCallback(async (event: any) => {
    setCalendarDate(event);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEventClick = useCallback((event: any) => {
    // prevent clicking if the item is in cancelled state
    const { isCancelled, type, isRoomBookedByOthers } = event?.event || {};
    if (isCancelled || isRoomBookedByOthers || type === 'hover-state') {
      return false;
    }

    if (event?.event?.id) {
      const id = event?.event?.id.split('_')[0];
      setSelectedAppointmentId(id);
      setSelectedAppointmentGroup(event?.event?.group);
    }
  }, []);

  // rendering appointment data and the gap after the session
  // docs -> https://docs.mobiscroll.com/react/eventcalendar#opt-renderScheduleEvent
  const renderScheduleEvent = useCallback(
    (data: any) => {
      const renderGapEvent = (data: any) => {
        const eventPeriod = moment
          .duration(
            getTimeZoneDateTime(data.original.end, timezone).diff(getTimeZoneDateTime(data.original.start, timezone))
          )
          .asMinutes();
        data = {
          ...data,
          ...(!data.end && { end: getTimeZoneDateTime(data.original.end, timezone).format('hh:mm A') }),
          ...(!data.start && { start: getTimeZoneDateTime(data.original.start, timezone).format('hh:mm A') })
        };

        const scale = zoom2x ? 2 : 1;
        const eventLong = (scale * (eventPeriod * (!isTimelineCalendarView ? 2400 : 3072))) / 1440;
        const gapLong = (scale * (data.original.gap * (!isTimelineCalendarView ? 2400 : 3072))) / 1440;
        const mainLong = eventLong + gapLong;

        // finding the gap height
        const MIN_IN_HOUR = 60;
        const OFFSET_PIXEL = 6; // offset value to avoid overlapping border lines
        const gapHeight =
          (scale * (data.original.gap * MIN_IN_HOUR)) / TIME_CELL_STEP -
          (MIN_IN_HOUR / TIME_CELL_STEP) * scale * OFFSET_PIXEL;

        return (
          <div style={!isTimelineCalendarView ? { height: '100%' } : { width: mainLong }}>
            <div
              style={!isTimelineCalendarView ? { height: '100%' } : { height: 50, width: eventLong }}
              className={styles.eventContainer}
            >
              <CalendarEvent
                data={{
                  ...data,
                  appointmentTypes: highLightList.filter((i) => i.sessionType),
                  practitionersList: practitionersList.map((practiceObj) => ({
                    ...practiceObj,
                    _id: practiceObj._id || 'practiceId'
                  }))
                }}
                is2xZoom={zoom2x}
                isHorizontalCalendar={isTimelineCalendarView}
                onEditEvent={() => onEventClick({ event: data })}
              />
            </div>
            <div
              style={
                !isTimelineCalendarView
                  ? { height: gapHeight }
                  : { width: gapLong, top: 0, height: 50, left: eventLong }
              }
              className={classNames(styles.gapContainer, data.original.type === 'reserved' && styles.reservedGap)}
            >
              <div
                className={classNames(styles.gapMakeup, data.original.type === 'reserved' && styles.reservedGapBorder)}
              />
              <div className={styles.gapText}>GAP</div>
            </div>
          </div>
        );
      };

      return (
        data &&
        (data.end && data.original?.gap > 0 && !data.original.isCancelled ? (
          renderGapEvent(data)
        ) : (
          <CalendarEvent
            data={{
              ...data,
              appointmentTypes: highLightList.filter((i) => i.sessionType),
              practitionersList: practitionersList.map((practiceObj) => ({
                ...practiceObj,
                _id: practiceObj._id || 'practiceId'
              }))
            }}
            is2xZoom={zoom2x}
            isHorizontalCalendar={isTimelineCalendarView}
            onEditEvent={() => onEventClick({ event: data })}
          />
        ))
      );
    },
    [timezone, zoom2x, isTimelineCalendarView, onEventClick, highLightList, practitionersList]
  );

  const handleCloseAppointmentInformationModal = () => {
    setSelectedAppointmentId('');
    setSelectedAppointment(undefined);
  };

  // sort logic, practice first, clinician second, others or rooms third
  const resources = useMemo(() => {
    const isClinician = (item: CalendarFilterInterface) => item.isClinician;

    return selectedClinicians
      .concat(selectedRooms)
      .sort((a, b) => {
        const aIsClinician = isClinician(a);
        const bIsClinician = isClinician(b);

        // First, prioritize items with an empty _id (Practice)
        if (a._id === '' && !aIsClinician) return -1;
        if (b._id === '' && !bIsClinician) return 1;

        // If both items are practices, keep their order
        if (!a._id && !b._id) return 0;

        // Finally, sort by clinician status (clinicians first then room)
        if (aIsClinician && !bIsClinician) return -1;
        if (!aIsClinician && bIsClinician) return 1;

        // Finally, sort alphabetically by name if both are the same type
        return a.name.localeCompare(b.name);
      })
      .map((item) => ({
        id: item._id || 'practiceId',
        name: item.name,
        img: item.avatar,
        isClinician: item.isClinician,
        color: CALENDAR_FILTER_COLOURS_VALUE[item.color || ''] || 'gray'
      }));
  }, [selectedClinicians, selectedRooms]);

  const handleInlineFormSubmitSuccess = async () => {
    setShowInlineAppointment(false);
    setHoverStateEvent(undefined);
  };

  const handleCloseInlineBookingForm = useCallback(() => {
    setShowInlineAppointment(false);
    setHoverStateEvent(undefined);
    dispatch(resetAppointmentData());
    dispatch(setStartValidation(false));
    dispatch(setAppointmentView(AppointmentViewType.Customise));
  }, [dispatch, setShowInlineAppointment]);

  // docs -> https://docs.mobiscroll.com/react/eventcalendar#event-onCellClick
  const handleOnScheduleCellClick = useCallback(
    ({ date, domEvent, target, resource }: MbscCellClickEvent) => {
      const initialDate = moment(date);
      const targetPortionEl = target.querySelector('.' + MARK_TO_APPT_CREATION_CLASS);
      if (targetPortionEl) {
        if (targetPortionEl.classList.contains(HALF_PAST_HOUR_CLASS)) {
          initialDate.set('minute', DEFAULT_APPT_RANGE);
        }
      }

      // calculate dynamic position
      setInlineBookingFormPositions(
        calculateInlineModalPosition(domEvent, target.offsetWidth, 800, isMultipleClinicianView)
      );

      // Reset all selected data
      dispatch(resetAppointmentData());
      dispatch(setStartValidation(false));
      dispatch(setAppointmentView(AppointmentViewType.Customise));
      // Set date and time data to inline form
      const selectedDate = moment(initialDate).format('YYYY-MM-DD');
      const startTime = moment(initialDate).format('HH:mm');
      const endTime = moment(initialDate).add(50, 'minutes').format('HH:mm');
      const getPractitionerInfo = selectedClinicians.find(
        (practitionerObj) => practitionerObj._id === (resource !== 'practiceId' ? resource : '')
      );

      dispatch(setSelectedDate(selectedDate));
      dispatch(setSelectedTime({ startTime, endTime }));
      dispatch(setOriginalDateTime({ date: selectedDate, startTime, endTime }));

      if (isMultipleClinicianView && getPractitionerInfo) {
        dispatch(
          setSelectedPractitioner({
            _id: getPractitionerInfo._id || 'practiceId',
            name: getPractitionerInfo.name,
            avatar: getPractitionerInfo.avatar
          })
        );
        dispatch(setCurrentStep(AppointmentHumanFactorStep.Client));
      } else {
        dispatch(setCurrentStep(AppointmentHumanFactorStep.Practitioner));
      }

      if (!isEdgeAdminOrReceptionistView) {
        dispatch(
          setSelectedPractitioner({
            _id: clinicianProfile?._id || 'practiceId',
            name: clinicianProfile?.name,
            avatar: clinicianProfile?.avatar
          })
        );
        dispatch(setCurrentStep(AppointmentHumanFactorStep.Client));
      }

      // Show Inline appointment modal
      setShowInlineAppointment(true);
    },
    [
      clinicianProfile,
      isEdgeAdminOrReceptionistView,
      dispatch,
      selectedClinicians,
      setShowInlineAppointment,
      isMultipleClinicianView
    ]
  );

  /* Effects --------------------------------------------------------------------- */
  useEffect(() => {
    if (token && appointmentId) {
      queryAppointment(appointmentId);
    }
  }, [appointmentId, queryAppointment, token]);

  // -------------------------------------------------------------------------------------------------
  // To support hover state feature this block of code
  // injects 2 html div tags into each time slot in the calendar view
  // in order to detecting hovering event
  // -------------------------------------------------------------------------------------------------
  let activeSlot: EventTarget | null = null;
  useEffect(() => {
    if (calendarRef.current) {
      const sItems = document.querySelectorAll(`${SCHEDULE_ITEM_CLASS}:empty`);
      const toggleActiveState = (ev: MouseEvent) => {
        // reset others
        if (activeSlot) {
          (activeSlot as HTMLDivElement).classList.remove(MARK_TO_APPT_CREATION_CLASS);
        }

        (ev.target as HTMLDivElement).classList.add(MARK_TO_APPT_CREATION_CLASS);
        // eslint-disable-next-line
        activeSlot = ev.target;
      };

      sItems.forEach((item) => {
        const firstDiv = document.createElement('div');
        const lastDiv = document.createElement('div');
        firstDiv.classList.add(FIRST_HALF_HOUR_CLASS);
        lastDiv.classList.add(HALF_PAST_HOUR_CLASS);

        item.append(firstDiv, lastDiv);

        firstDiv.addEventListener('click', toggleActiveState, false);
        lastDiv.addEventListener('click', toggleActiveState, false);
      });
    }
  }, [calendarRef.current, calendarRef.current?._selected]);

  const openOldAppointmentModal = () => {
    handleCloseInlineBookingForm();
    setShowOtherAppointmentBooking();
  };

  const isSelectedAppointmentLoaded = useMemo(() => {
    return (!!selectedAppointment && Object.keys(selectedAppointment).length > 0) || !!targetAppointment;
  }, [selectedAppointment, targetAppointment]);

  useEffect(() => {
    if (
      isSelectedAppointmentLoaded &&
      serviceDelivered &&
      (serviceDelivered === ServiceDelivered.Attended ||
        serviceDelivered === ServiceDelivered.ClientDidNotAttend ||
        serviceDelivered === ServiceDelivered.CancelShortNotice)
    ) {
      setIsProcessAppointmentModalShow(true);
    }
  }, [isSelectedAppointmentLoaded, serviceDelivered]);

  const handleCloseProcessAppointmentModal = () => {
    dispatch(resetProcessAppointment());
    if (appointmentId || serviceDelivered) {
      navigate(pathname, { replace: true });
      setTargetAppointment(undefined);
      setSelectedAppointmentId(undefined);
    }
    setIsProcessAppointmentModalShow(!isProcessAppointmentModalShow);
  };

  const onSubmitProcessSuccessful = async (appointmentId: string, withoutRefetch?: boolean) => {
    dispatch(resetProcessAppointment());
    setIsProcessAppointmentModalShow(!isProcessAppointmentModalShow);

    if (!withoutRefetch) {
      setIsRefetchingAppointmentAfterProcess(true);
      try {
        const appointmentResponse = await getAppointmentById(token, accountId, appointmentId);
        const appointment = await appointmentResponse.json();
        setSelectedAppointment(appointment);
      } catch (ex) {
        console.error(ex);
      } finally {
        setIsRefetchingAppointmentAfterProcess(false);
      }
    } else {
      handleCloseAppointmentInformationModal();
    }
  };

  const renderCustomResource = useCallback(
    (item: MbscResource) =>
      item.isClinician || (item.id === 'practiceId' && !item.isClinician) ? (
        <ProfileBadge
          nameClassName={styles.horizontalClinicianName}
          noEllipsisName
          name={item.name}
          avatar={item.img}
        />
      ) : (
        <RoomLabel name={item.name || ''} />
      ),
    []
  );

  const viewData = useMemo<MbscEventcalendarView>(() => {
    const commonConfig = {
      type: dayWeekView, // day or week
      allDay: false, // hiding allDay events
      startDay: 1, // start at Monday
      endDay: 0 // end at Sunday
    };

    const viewConfig = {
      [CalendarLayout.blended]: {
        schedule: {
          ...commonConfig,
          timeCellStep: TIME_CELL_STEP
        }
      },
      [CalendarLayout.timeline]: {
        timeline: {
          ...commonConfig,
          timeCellStep: 30
        }
      },
      [CalendarLayout.mc]: {
        schedule: {
          ...commonConfig,
          timeCellStep: TIME_CELL_STEP
        }
      }
    };

    return viewConfig[calendarViewLayout];
  }, [dayWeekView, calendarViewLayout]);

  const calendarViewSettings = useMemo<CalendarViewConfig>(
    () => ({
      [CalendarLayout.blended]: {
        renderCustomResource: undefined,
        resources: undefined,
        renderDay: undefined,
        groupBy: undefined
      },
      [CalendarLayout.timeline]: {
        renderCustomResource: renderCustomResource,
        resources: resources,
        renderDay: (day: any) => (
          <div className={styles.horizontalCustomDay}>{moment(day.date).format('ddd Do MMM YYYY')}</div>
        ),
        groupBy: undefined
      },
      [CalendarLayout.mc]: {
        renderCustomResource: renderCustomResource,
        resources: resources,
        renderDay: undefined,
        groupBy: 'date'
      }
    }),
    [resources, renderCustomResource]
  );
  const calendarView = calendarViewSettings[calendarViewLayout];

  /* render main component -------------------------------------------------------------- */
  return (
    <div ref={calendarContentRef}>
      <Eventcalendar
        firstDay={1}
        ref={calendarRef}
        height={calendarViewSize.height}
        width={calendarViewSize.width}
        renderHeader={() => <CalendarHeader />}
        renderScheduleEvent={renderScheduleEvent}
        selectedDate={currentDateTime}
        onSelectedDateChange={(e) => dispatch(setCalendarSelectedDate(moment(e.date).toDate()))}
        colors={calendarCellsColors}
        data={calendarEvents}
        view={viewData}
        onEventClick={onEventClick}
        onCellClick={handleOnScheduleCellClick}
        onPageLoading={onPageLoading}
        dataTimezone={'utc'}
        displayTimezone={timezone}
        timezonePlugin={momentTimezone}
        className={classNames(zoom2x ? styles.zoom2x : '', isTimelineCalendarView && styles.horizontal)}
        showEventTooltip={false}
        renderDay={calendarView.renderDay}
        resources={calendarView.resources}
        renderResource={calendarView.renderCustomResource}
        groupBy={calendarView.groupBy}
      />
      <EventInformationModal
        visible={isSelectedAppointmentLoaded}
        onClose={handleCloseAppointmentInformationModal}
        appointmentId={selectedAppointmentId}
        appointment={
          selectedAppointment && Object.keys(selectedAppointment).length > 0 ? selectedAppointment : targetAppointment
        }
        onEditComplete={() => {
          dispatch(scheduleServicesApiSlice.util.invalidateTags([SSTagTypes.AppointmentList]));
        }}
        group={selectedAppointmentGroup}
        onCancelAppointmentComplete={() => {
          dispatch(scheduleServicesApiSlice.util.invalidateTags([SSTagTypes.AppointmentList]));
        }}
        isLoading={isLoading}
        isRefetchingAppointmentAfterProcess={isRefetchingAppointmentAfterProcess}
        updateSelectedAppointment={(appointment: AppointmentSlots) =>
          setSelectedAppointment({ ...selectedAppointment, ...appointment })
        }
        isProcessAppointmentModalShow={isProcessAppointmentModalShow}
        onCloseProcessAppointmentModal={handleCloseProcessAppointmentModal}
        handleShowProcessAppointment={() => setIsProcessAppointmentModalShow(true)}
        onSubmitProcessSuccessful={onSubmitProcessSuccessful}
      />
      {isShowInlineAppointment.showModal && (
        <InlineBookingModal
          style={
            (isShowInlineAppointment.position === 'custom' && inlineBookingFormPositions) || {
              top: '20%',
              right: '248px',
              boxShadow: '1px 1px 21px #0000001a',
              maxWidth: '800px',
              width: '100%'
            }
          }
          onCloseModal={handleCloseInlineBookingForm}
          onSubmitSuccess={handleInlineFormSubmitSuccess}
          onSelectOtherType={openOldAppointmentModal}
          practitionerMainOption
          rememberSelectedPractitioner
        />
      )}
    </div>
  );
};

export default CalendarView;
