import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import { Link } from 'react-router-dom';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { Invoice, InvoiceStatus, OnChangeInvoiceStatus } from '../../../../interface';
import InvoiceActionMenu from '../InvoiceActionMenu/InvoiceActionMenu';
import styles from './ClientInvoiceItem.module.scss';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useCurrency } from 'utils/hooks/useCurrency';
import { useAppSelector } from 'redux/hooks';
import { myProfileInfo } from 'redux/slice/profileDataSlice';

export const STATUS_LABELS: Record<InvoiceStatus, string> = {
  draft: 'Draft',
  issued: 'Issued',
  overdue: 'Overdue',
  markedPaid: 'Marked Paid',
  confirmPaid: 'Confirm Paid',
  closed: 'Closed',
  voided: 'Voided',
  writeOff: 'Write Off',
  pendingClaim: 'Pending Claim',
  claimComplete: 'Claim Complete',
  claimSuccess: 'Claim Success',
  claimRejected: 'Claim Rejected',
  fullyRefunded: 'Fully Refunded'
};

interface InvoiceItemProps {
  invoice: Invoice;
  onChangeStatus: OnChangeInvoiceStatus;
  onResendInvoice: (_id: string) => void;
  isInvoiceActionProcessing: boolean;
}

const ClientInvoiceItem = ({
  invoice,
  onChangeStatus,
  onResendInvoice,
  isInvoiceActionProcessing
}: InvoiceItemProps) => {
  const { INVOICES } = useRoutesGenerator();
  const { data: clinicianProfile } = useAppSelector(myProfileInfo);
  const { isEdgeAdminView, isEdgeUserView, isEdgeReceptionist } = useGetAccountPackageView();
  const { CURRENCY_SYMBOL } = useCurrency();

  const { _id, description, issueDate, invoiceAmount, invoiceId, status } = invoice;

  const draftPath =
    (isEdgeAdminView ? `/admin` : isEdgeReceptionist ? '/receptionist' : isEdgeUserView ? '/user' : '') +
    `/invoices/${_id}/edit`;

  const canEditInvoice = !(isEdgeUserView && clinicianProfile?._id !== invoice?.clinician?._id);

  return (
    <div className={styles.container}>
      <div className={styles.clientRecordWrapper}>
        <ClientAvatar
          clientData={invoice.clientRecord.clientProfiles}
          avatarSize={60}
          displayFirstNameOnly={invoice.clientRecord.recordType === 'couple'}
          displayLimit={invoice.clientRecord.recordType === 'couple' ? 2 : 1}
        />
      </div>

      {status === 'draft' ? (
        <Link className={styles.invoiceId} to={{ pathname: draftPath }} state={{ invoice }}>
          {invoiceId}
        </Link>
      ) : (
        <div className={styles.invoiceId}>
          <Link className={styles.invoiceId} to={`${INVOICES.BASE}/${invoice._id}`} state={{ invoice }}>
            {invoiceId}
          </Link>
        </div>
      )}
      <span className={styles.issueDate}>{issueDate}</span>
      {description && description.match(/<[a-z][\s\S]*>/i) ? (
        <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
      ) : (
        <span className={styles.description}>
          {description ? (description.length > 70 ? `${description.slice(0, 70)}...` : description) : '-'}
        </span>
      )}
      <span className={styles.invoiceAmount}>
        {CURRENCY_SYMBOL}
        {invoiceAmount.toFixed(2)}
      </span>
      <div className={styles.statusWrapper}>
        <span className={`${styles.status} ${styles[status]}`}>{STATUS_LABELS[status]}</span>
      </div>
      <div className={styles.actionButtonContainer}>
        {canEditInvoice && (
          <InvoiceActionMenu
            isInvoiceActionProcessing={isInvoiceActionProcessing}
            status={status}
            invoiceId={_id}
            onChangeStatus={onChangeStatus}
            onResendInvoice={onResendInvoice}
          />
        )}
      </div>
    </div>
  );
};

export default ClientInvoiceItem;
