import styles from './CompletedCard.module.scss';
import CompletedCardItem from './components/CompletedCardItem/CompletedCardItem';
import { AppointmentSlots, TelehealthRecordingComposition } from 'interfaces/Schedule/Appointment';
import { Skeleton } from 'antd';

interface CompletedCardProps {
  total: number;
  data: AppointmentSlots[];
  practiceName: string;
  isLoading: boolean;
  onViewAppointmentRecording: (params: {
    appointmentId: string;
    recordingId: string;
    recordings: TelehealthRecordingComposition[];
  }) => void;
  refetchAppointment: () => void;
}

const CompletedCard = ({
  total,
  data,
  practiceName,
  isLoading,
  onViewAppointmentRecording,
  refetchAppointment
}: CompletedCardProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className={styles.title}>All Other Appointments</div>
        {isLoading ? (
          <div>
            <Skeleton.Avatar active />
          </div>
        ) : (
          <div className={styles.total}>{total}</div>
        )}
      </div>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          {[...Array(3)].map((_, i) => (
            <div key={i} className={styles.loadingWrapper}>
              <Skeleton.Avatar active />
              <div className={styles.loadingContent}>
                <div className={styles.headingLoading}>
                  <Skeleton.Input active className={styles.titleLoading} />
                  <Skeleton.Input active className={styles.tags} />
                </div>
                <Skeleton.Input active className={styles.desc1} />
                <Skeleton.Input active className={styles.desc2} />
              </div>
              <Skeleton.Input active className={styles.selectTagsLoading} />
            </div>
          ))}
        </div>
      ) : (
        data.map((obj) => (
          <CompletedCardItem
            appointment={obj}
            key={obj._id}
            refetchAppointment={refetchAppointment}
            practiceName={practiceName}
            onViewAppointmentRecording={onViewAppointmentRecording}
          />
        ))
      )}
    </div>
  );
};

export default CompletedCard;
