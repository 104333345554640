import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { AppointmentSlots } from '../../interfaces/Schedule/Appointment';
import {
  CalendarFilterInterface,
  CalendarHighLightInterface,
  CalendarLayout,
  CalendarRoomFilterInterface
} from 'components/v2/CalendarFilter/interfaces';
import { getSelectedFiltersFromLocalStorage } from 'pages/Calendar/components/CalendarWithHighlightsT23/selectedFilters/selectedFilters';
import { CalendarPractitionerList } from 'interfaces/Practitioners/practitionersListing';
import {
  AUTO_START_VALUE,
  CALENDAR_START_TIME_LS_VALUE,
  CALENDAR_VIEW_VALUE,
  SHOW_CANCELLED_APPOINTMENT_LS_VALUE,
  SHOW_WORKING_SCHEDULE_LS_VALUE
} from 'pages/Calendar/components/CalendarWithHighlightsT23/components/CalendarView/utils';

const getBooleanFromLocalStorage = (key: string, defaultValue: boolean): boolean => {
  const value = localStorage.getItem(key);
  if (value === null) {
    return defaultValue;
  }
  // Assuming 'true' or 'false' strings are used in localStorage
  return value === 'true';
};

interface AppointmentsState {
  [key: string]: { [key: string]: AppointmentSlots[] };
}

export enum dayWeekType {
  week = 'week',
  day = 'day'
}

interface calendarAppointmentListState {
  calendarAppointmentList: AppointmentsState;
  calendarFilter: {
    selectedClinicians: CalendarFilterInterface[];
    selectedRooms: CalendarRoomFilterInterface[];
    selectedHighLights: CalendarHighLightInterface[];
  };
  isLoadingFilters: boolean;
  calendarLayout: CalendarLayout;
  calendarSettings: {
    dayWeekView: dayWeekType;
    zoom2x: boolean;
    displayWorkingSchedule: boolean;
    workingScheduleColor: string;
    displayCancelledAppointment: boolean;
    startTimeValue: string;
  };
  calendarSelectedDate?: Date;
  isLoading: boolean;
}

const initialState: calendarAppointmentListState = {
  calendarAppointmentList: {},
  calendarFilter: {
    selectedClinicians: [],
    selectedRooms: [],
    selectedHighLights: []
  },
  isLoadingFilters: false,
  calendarLayout: (localStorage.getItem(CALENDAR_VIEW_VALUE) as CalendarLayout) || CalendarLayout.blended,
  calendarSettings: {
    dayWeekView: dayWeekType.week,
    zoom2x: false,
    displayWorkingSchedule: getBooleanFromLocalStorage(SHOW_WORKING_SCHEDULE_LS_VALUE, true),
    workingScheduleColor: '#F2F6FB',
    displayCancelledAppointment: getBooleanFromLocalStorage(SHOW_CANCELLED_APPOINTMENT_LS_VALUE, true),
    startTimeValue: localStorage.getItem(CALENDAR_START_TIME_LS_VALUE) || AUTO_START_VALUE
  },
  isLoading: false
};

export const fetchFilters = createAsyncThunk(
  'calendarAppointmentList/fetchFilters',
  async ({
    clinicianProfileId,
    isEdgeAdminView,
    practitionersList,
    defaultFilters
  }: {
    clinicianProfileId: string;
    isEdgeAdminView: boolean;
    practitionersList: CalendarPractitionerList[];
    defaultFilters: CalendarFilterInterface[];
  }) => {
    try {
      const savedFilters = getSelectedFiltersFromLocalStorage(clinicianProfileId, isEdgeAdminView);

      if (savedFilters) {
        const practitionerMap = new Map(practitionersList.map((practitioner) => [practitioner._id, practitioner]));

        let { selectedFilters: selectedClinician } = savedFilters;
        if (selectedClinician && selectedClinician.length > 0) {
          const cleanSelectedClinicianList = selectedClinician.filter(({ _id }) => practitionerMap.has(_id));

          savedFilters.selectedFilters = cleanSelectedClinicianList;
          const massageFilterWithLatestInfo = cleanSelectedClinicianList.map((filterObj) => ({
            ...filterObj,
            workingSchedule: practitionerMap.get(filterObj._id)?.workingSchedule || filterObj.workingSchedule,
            workTimeZone: practitionerMap.get(filterObj._id)?.workTimeZone || filterObj.workTimeZone
          }));

          return {
            selectedClinicians: massageFilterWithLatestInfo,
            selectedRooms: savedFilters.selectedRooms || [],
            selectedHighLights: savedFilters.selectedHighLights
          };
        }
        return {
          selectedClinicians: defaultFilters,
          selectedRooms: savedFilters.selectedRooms || [],
          selectedHighLights: savedFilters.selectedHighLights
        };
      }
      return { selectedClinicians: defaultFilters, selectedRooms: [], selectedHighLights: [] };
    } catch (error) {
      console.error(error);
    }
  }
);

const calendarAppointmentListDataSlice = createSlice({
  name: 'calendarAppointmentList',
  initialState,
  reducers: {
    updateAppointments: (
      state,
      action: PayloadAction<{
        appts: AppointmentSlots[];
        from: string;
        to: string;
        roomIds: string[];
        clinicianIds: string[];
        auth0ClinicianId: string;
      }>
    ) => {
      const { appts, from, to, roomIds, clinicianIds, auth0ClinicianId } = action.payload;

      // Handle Room IDs
      roomIds.forEach((roomId) => {
        const filteredAppts = appts.filter((item) => item.isRoomFilter && item.room?.roomId === roomId);
        state.calendarAppointmentList[roomId] = {
          ...(state.calendarAppointmentList[roomId] || {}),
          [`${from}-${to}`]: filteredAppts
        };
      });

      // Handle Clinician IDs
      let otherClinicianAppts: AppointmentSlots[] = [];
      clinicianIds
        .filter((id) => id !== auth0ClinicianId && id !== '')
        .forEach((clinicianId) => {
          const filteredAppts = appts.filter(
            ({ isRoomFilter, clinicianId: apptClinicianId, groupId }) =>
              !isRoomFilter && (apptClinicianId === clinicianId || (groupId && !apptClinicianId))
          );
          state.calendarAppointmentList[clinicianId] = {
            ...(state.calendarAppointmentList[clinicianId] || {}),
            [`${from}-${to}`]: filteredAppts
          };
          otherClinicianAppts = [...otherClinicianAppts, ...filteredAppts];
        });

      // Handle Auth0 Clinician ID
      if (clinicianIds.includes(auth0ClinicianId) || clinicianIds.includes('')) {
        state.calendarAppointmentList[auth0ClinicianId] = {
          ...(state.calendarAppointmentList[auth0ClinicianId] || {}),
          [`${from}-${to}`]: appts.filter((item) => !item.isRoomFilter && !otherClinicianAppts.includes(item))
        };
      }
    },

    setSelectedClinicians: (state, action: PayloadAction<CalendarFilterInterface[]>) => {
      state.calendarFilter.selectedClinicians = action.payload;
    },

    setSelectedRooms: (state, action: PayloadAction<CalendarRoomFilterInterface[]>) => {
      state.calendarFilter.selectedRooms = action.payload;
    },

    setSelectedHighLights: (state, action: PayloadAction<CalendarHighLightInterface[]>) => {
      state.calendarFilter.selectedHighLights = action.payload;
    },

    setCalendarLayout: (state, action: PayloadAction<CalendarLayout>) => {
      state.calendarLayout = action.payload;
    },
    setDayWeekView: (state, action: PayloadAction<dayWeekType>) => {
      state.calendarSettings.dayWeekView = action.payload;
    },
    setZoom2x: (state, action: PayloadAction<boolean>) => {
      state.calendarSettings.zoom2x = action.payload;
    },
    setDisplayWorkingSchedule: (state, action: PayloadAction<boolean>) => {
      state.calendarSettings.displayWorkingSchedule = action.payload;
    },
    setWorkingScheduleColor: (state, action: PayloadAction<string>) => {
      state.calendarSettings.workingScheduleColor = action.payload;
    },
    setDisplayCancelledAppointment: (state, action: PayloadAction<boolean>) => {
      state.calendarSettings.displayCancelledAppointment = action.payload;
    },
    setStartTimeValue: (state, action: PayloadAction<string>) => {
      state.calendarSettings.startTimeValue = action.payload;
    },
    setCalendarSelectedDate: (state, action: PayloadAction<Date>) => {
      state.calendarSelectedDate = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilters.pending, (state) => {
        state.isLoadingFilters = true;
      })
      .addCase(fetchFilters.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoadingFilters = false;
        state.calendarFilter.selectedClinicians = action.payload.selectedClinicians;
        state.calendarFilter.selectedRooms = action.payload.selectedRooms;
        state.calendarFilter.selectedHighLights = action.payload.selectedHighLights;
      })
      .addCase(fetchFilters.rejected, (state, action) => {
        state.isLoadingFilters = false;
      });
  }
});

export const calendarAppointmentList = (state: RootState) =>
  state.calendarAppointmentListDataSlice.calendarAppointmentList;
export const calendarFilters = (state: RootState) => state.calendarAppointmentListDataSlice.calendarFilter;
export const calendarLayout = (state: RootState) => state.calendarAppointmentListDataSlice.calendarLayout;
export const calendarSettings = (state: RootState) => state.calendarAppointmentListDataSlice.calendarSettings;
export const calendarSelectedDate = (state: RootState) => state.calendarAppointmentListDataSlice.calendarSelectedDate;

export const {
  updateAppointments,
  setSelectedClinicians,
  setSelectedRooms,
  setSelectedHighLights,
  setCalendarLayout,
  setDayWeekView,
  setZoom2x,
  setDisplayWorkingSchedule,
  setWorkingScheduleColor,
  setDisplayCancelledAppointment,
  setStartTimeValue,
  setCalendarSelectedDate
} = calendarAppointmentListDataSlice.actions;

export default calendarAppointmentListDataSlice.reducer;
