import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';

import styles from './ValionProgramEducationalContent.module.scss';

const ValionProgramEducationalContent = () => {
  return (
    <HelmetWrapper title="Tacklit - Program Educational Content">
      <ContentLayout className={styles.container}>
        <iframe
          title={'Program Educational Content'}
          width={'100%'}
          height={'100%'}
          src={'https://customer.tacklit.com/app/valion-resources/page1-670896751c4ee608f4f07db2?embed=true'}
          frameBorder={0}
          allowFullScreen
        />
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default ValionProgramEducationalContent;
