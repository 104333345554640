import { PinType } from 'interfaces/Clients/clientsRecord';
import { CISTagTypes, checkInServiceApiSlice } from 'redux/services/checkInServiceApiSlice';

interface CreateNotePayload {
  clientRecordId: string;
  title: string;
  body: string;
  pinType: PinType | '';
}

export const notesApiSlice = checkInServiceApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createNote: builder.mutation({
      query: (payload: CreateNotePayload) => ({
        url: '/notes',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [CISTagTypes.Notes]
    })
  })
});

export const { useCreateNoteMutation } = notesApiSlice;
