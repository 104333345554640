import * as yup from 'yup';

import { ClaimFormData, ClaimFormErrors } from './initValue';

const validationSchema = yup.object().shape({
  clinicianId: yup.string().required('Please select a practitioner'),
  minorId: yup.string().required('Please select a location ID'),
  providerNumber: yup.string().required('Please select a provider number'),
  duration: yup.string().required('Please select a duration'),
  format: yup.string().required('Please select a format'),
  mode: yup.string().required('Please select a mode'),
  mbsCode: yup.string().required('Please select a MBS code')
});

export const validateClaimForm = (formData: ClaimFormData) => {
  const errors: ClaimFormErrors = {
    clinicianId: '',
    minorId: '',
    providerNumber: '',
    duration: '',
    format: '',
    mode: '',
    mbsCode: '',
    isPracticePayee: '',
    dvaCondition: ''
  };

  try {
    validationSchema.validateSync(formData, { abortEarly: false });
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error: any) => {
        if (Object.keys(errors).includes(error.path)) {
          errors[error.path as keyof ClaimFormData] = error.message;
        }
      });
    }
  }

  return errors;
};
