import ContentLayout from 'components/ContentLayoutT23/ContentLayoutT23';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import { useTranslation } from 'react-i18next';
import styles from './PractitionerControlPanel.module.scss';
import ControlPanelHeader from 'pages/ControlPanel/ControlPanel/components/ControlPanelHeader/ControlPanelHeader';
import { useEffect } from 'react';
import ControlPanelContent from 'pages/ControlPanel/ControlPanel/components/ControlPanelContent/ControlPanelContent';
import { useParams } from 'react-router-dom';
import { useSetRecentlyViewed } from 'utils/hooks/SubTab/recentlyViewed';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { scrollToView } from 'utils/scrollToView';
import { clinicianProfileServicesApiSlice, CPSTagTypes } from '../../redux/services/clinicianProfileServicesApiSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { myProfileInfo } from 'redux/slice/profileDataSlice';
import { useGetClinicianProfileByIdQuery } from 'redux/endpoints/clinicianProfileServices/clinicianProfile';
import LoadingDot from 'components/LoadingDot/LoadingDot';

const PractitionerControlPanel = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { controlPanelTab, practitionerId } = useParams();
  const { data: myProfileData } = useAppSelector(myProfileInfo);
  const { auth0ClinicianId } = useGetClinicianId();
  const { isEdgeAdminView } = useGetAccountPackageView();
  const { data: clinicianProfile, isLoading: clinicianProfileLoading } = useGetClinicianProfileByIdQuery(
    { clinicianId: practitionerId || '' },
    { skip: !practitionerId }
  );

  const isAdminSelected = myProfileData?._id === practitionerId;

  useEffect(() => {
    scrollToView('practitionerProfile');
  }, [controlPanelTab]);

  useSetRecentlyViewed({
    isLoading: !clinicianProfile,
    storageName: 'team_viewed',
    id: clinicianProfile?._id,
    clinicianProfileId: auth0ClinicianId,
    isEdgeAdminView,
    name: clinicianProfile?.name ?? 'Team',
    recordId: clinicianProfile?._id || ''
  });

  return (
    <HelmetWrapper title={t('label.tacklit_practitioner_profile')}>
      <div id={'practitionerProfile'} />
      <ContentLayout>
        <div className={styles.container}>
          {clinicianProfileLoading ? (
            <div className={styles.loadingWrapper}>
              <LoadingDot />
            </div>
          ) : (
            <>
              <ControlPanelHeader tab={controlPanelTab} practitionerName={clinicianProfile?.name} />
              <ControlPanelContent
                profile={clinicianProfile}
                controlPanelTab={controlPanelTab}
                onUpdateProfile={() => {
                  if (isAdminSelected) {
                    dispatch(clinicianProfileServicesApiSlice.util.invalidateTags([CPSTagTypes.ClinicianProfile]));
                  }
                }}
                fetchProfile={() => {
                  dispatch(clinicianProfileServicesApiSlice.util.invalidateTags([CPSTagTypes.ClinicianProfileById]));
                }}
                isMobileOptional
              />
            </>
          )}
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default PractitionerControlPanel;
