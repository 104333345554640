import classNames from 'classnames';
import { FilterCheckListItem } from '../CalendarFilterCheckList/CalendarFilterCheckList';
import CheckBox from 'components/CheckBox/CheckBox';
import styles from './CalendarFilterCheckItem.module.scss';
import FilterColorBox from '../CalendarFilterCheckList/components/FilterColorBox/FilterColorBox';

interface CalendarFilterCheckItemProps {
  id: string;
  item: FilterCheckListItem;
  searchValue: string;
  onChangeItem: (value: {
    item?: FilterCheckListItem;
    toggleAllValue?: boolean;
    color?: string;
    isChecked?: boolean;
  }) => void;
  isChecked: boolean;
  disabled?: boolean;
}

const CalendarFilterCheckItem = ({
  id,
  item,
  searchValue,
  onChangeItem,
  isChecked,
  disabled
}: CalendarFilterCheckItemProps) => {
  const matchedIndex = item.name.toLowerCase().indexOf(searchValue.toLowerCase());

  return (
    <div className={classNames(styles.item, isChecked && styles.active)}>
      <div className={styles.text}>
        <CheckBox
          id={id}
          className={styles.checkBox}
          inputClassName={styles.checkBoxInput}
          value={isChecked}
          onChange={(ev) => {
            onChangeItem({ item, isChecked: ev.target.checked });
          }}
          disabled={disabled}
        />
        <label htmlFor={id} className={styles.label}>
          {/* Staff name */}
          {item.name.substring(0, matchedIndex)}
          <span className={styles.matchedValue}>
            {item.name.substring(matchedIndex, matchedIndex + searchValue.length)}
          </span>
          {item.name.substring(matchedIndex + searchValue.length)}
        </label>
      </div>
      {isChecked && <FilterColorBox color={item.color} onChange={(color) => onChangeItem({ item, color })} />}
    </div>
  );
};

export default CalendarFilterCheckItem;
