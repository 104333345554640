import { Modal, notification } from 'antd';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import ModalV2 from 'components/ModalV2/ModalV2';
import { RESET_FORM_TIME } from 'components/SideDashboardT23/components/ReportsAndLettersSubTab/ReportsAndLettersSubTab';
import ButtonAlt, { ButtonStatusType } from 'components/v2/ButtonAlt/ButtonAlt';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { LetterAccessor, LetterStatus, PutLetterRequest } from 'interfaces/Letters/letter';
import { debounce } from 'lodash';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetClientEncryptedDataByClientRecordIdQuery } from 'redux/endpoints/clinicianProfileServices/client';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { makeTemplateContentRenderer } from 'utils/helpers/makeTemplateContentRenderer';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useFetchLetter } from 'utils/hooks/GetLetters/useFetchLetter';
import { useFetchLetterTemplateList } from 'utils/hooks/GetLetters/useFetchLetterTemplateList';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { useGetAccessToken } from 'utils/hooks/token';
import {
  deleteLetter,
  downloadLetterPdf,
  postLetter,
  putLetter,
  putPublishLetter,
  putRevokeLetterAccessor,
  putUnpublishLetter
} from 'utils/http/DocumentService/Letters/letters';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';
import { timeDiff } from 'utils/timeDifferent';
import LetterPreview from '../components/LetterPreview/LetterPreview';
import ReportContentAsPDF from '../components/ReportContentAsPDF/ReportContentAsPDF';
import ShareReportModal from '../components/ShareReportModal/ShareReportModal';
import { formatContactDetails, getClinicianDetails, mapSignature } from '../helper';
import { useFetchSignature } from '../hooks/useFetchSignature';
import { generatePrintableReportPayload, triggerDownload } from '../utils';
import styles from './LetterDetails.module.scss';
import LetterContent from './components/LetterContent/LetterContent';
import LetterSideBar from './components/LetterSideBar/LetterSideBar';
import { initLetterData } from './helper';
import { documentServicesApiSlice } from 'redux/services/documentServicesApiSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { myProfileInfo } from 'redux/slice/profileDataSlice';

const initialValidationsState = {
  body: false,
  clientRecord: false,
  clinicianSignature: false,
  letterName: false
};

const LetterDetails = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<PutLetterRequest>(initLetterData({}));
  const [downloadPDFButtonStatus, setDownloadPDFButtonStatus] = useState<ButtonStatusType>('');
  const [formattedDuration, setFormattedDuration] = useState<string>();
  const [isBlankFormInitialized, setIsBlankFormInitialized] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [lastEdited, setLastEdited] = useState<Moment>();
  const [letterTemplateId, setLetterTemplateId] = useState<string>();
  const [publishBtnStatus, setPublishBtnStatus] = useState<ButtonStatusType>('');
  const [saveBtnStatus, setSaveBtnStatus] = useState<ButtonStatusType>('');
  const [sharedList, setSharedList] = useState<LetterAccessor[]>([]);
  const [validations, setValidations] = useState(initialValidationsState);
  const [isUpdatingClientOrTemplate, setIsUpdatingClientOrTemplate] = useState(false);

  const contentRenderer = useRef<(template: string) => string>();
  const letterRef = useRef(null);
  const loadingRenderContent = useRef(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { accountId } = useGetAccountId();
  const { auth0ClinicianId } = useGetClinicianId();
  const { data: clinicianProfile } = useAppSelector(myProfileInfo);
  const { faxEnable } = useGetFeatureToggle();
  const { isEdgeAdminView } = useGetAccountPackageView();
  const { letterId: pathLetterId } = useParams();
  const { LETTERS } = useRoutesGenerator();
  const { token } = useGetAccessToken();

  const { letter, isLoadingLetter, fetchLetter } = useFetchLetter(token, accountId, auth0ClinicianId, pathLetterId);
  const { letterTemplateList, isLoadingLetterTemplateList } = useFetchLetterTemplateList(token, accountId);
  const { signature, isSignatureLoading } = useFetchSignature(token);

  const {
    data: clientEncryptedDetails,
    isFetching: isClientEncryptDetailsFetching,
    isLoading: isClientEncryptDetailsLoading
  } = useGetClientEncryptedDataByClientRecordIdQuery(
    { clientRecordId: data.clientRecord._id },
    { skip: !data.clientRecord._id }
  );

  const clinicianDetails = useMemo(() => getClinicianDetails(clinicianProfile), [clinicianProfile]);
  const isAuthor = useMemo(() => auth0ClinicianId === letter?.clinician._id, [auth0ClinicianId, letter?.clinician._id]);
  const isNewLetter = useMemo(() => pathLetterId === 'new', [pathLetterId]);
  const isReadOnly = useMemo(() => data?.status === LetterStatus.Published, [data?.status]);

  const selectedClientEncryptedDetails = useMemo(
    () => (clientEncryptedDetails?._id === data.clientRecord._id ? clientEncryptedDetails : undefined),
    [clientEncryptedDetails, data.clientRecord._id]
  );

  const validateAll = useCallback(
    (data: Pick<PutLetterRequest, 'letterName' | 'clientRecord' | 'body' | 'clinicianSignature'>) => {
      const errors = {
        letterName: !data.letterName,
        clientRecord: !data.clientRecord._id,
        body: !data.body,
        clinicianSignature: !data.clinicianSignature.type
      };
      return {
        errors,
        isValid: Object.values(errors).every((error) => error === false)
      };
    },
    []
  );

  const saveLetter = useCallback(
    async (payload: PutLetterRequest, notify: boolean) => {
      if (validateAll(payload).isValid) {
        setSaveBtnStatus('active');
        try {
          if (isNewLetter) {
            const createdLetterTemplateId = await (await postLetter(token, accountId, payload)).text();
            notification.success({
              message: 'Letter has been created.',
              duration: 3,
              closeIcon: <span className="success">OK</span>
            });
            navigate(`${LETTERS.BASE}/${createdLetterTemplateId}`);
            dispatch(documentServicesApiSlice.util.invalidateTags(['Letters']));
            return createdLetterTemplateId;
          } else if (pathLetterId) {
            await putLetter(token, accountId, pathLetterId, payload);
            dispatch(documentServicesApiSlice.util.invalidateTags(['Letters']));
          }
          setIsUpdatingClientOrTemplate(false);
          setSaveBtnStatus('finished');
          notify &&
            notification.success({
              message: 'Letter has been saved.',
              duration: 3,
              closeIcon: <span className="success">OK</span>
            });
        } catch (e) {
          notify && notification.error({ message: 'Error saving letter' });
        } finally {
          setTimeout(() => {
            setIsUpdatingClientOrTemplate(false);
            setSaveBtnStatus('');
          }, 1000);
        }
      } else {
        setIsUpdatingClientOrTemplate(false);
        notify && notification.error({ message: `Please fill all required fields and sign letter` });
      }
    },
    [LETTERS.BASE, isNewLetter, pathLetterId, navigate, token, accountId, validateAll, dispatch]
  );

  const debouncedSaveLetter = useMemo(
    () => debounce(async (data: PutLetterRequest, notify: boolean) => saveLetter(data, notify), 3000),
    [saveLetter]
  );

  const updateData = useCallback(
    (dataToUpdate: Partial<PutLetterRequest>, validate = true) => {
      const newData = {
        ...data,
        ...dataToUpdate
      };
      setData(newData);
      if (validate) {
        setValidations(validateAll(newData).errors);
      }
      setLastEdited(moment());
      if (saveBtnStatus === '' && !isLoadingTemplate) {
        setIsUpdatingClientOrTemplate(true);
        debouncedSaveLetter(data, false);
      }
    },
    [data, saveBtnStatus, debouncedSaveLetter, isLoadingTemplate, validateAll]
  );

  const applyTemplate = useCallback(
    async (letterTemplateId?: string) => {
      if (letterTemplateId) {
        setIsLoadingTemplate(true);
        const body = letterTemplateList?.find(({ _id }) => _id === letterTemplateId)?.body;

        if (body) {
          if (selectedClientEncryptedDetails && !contentRenderer.current) {
            contentRenderer.current = await makeTemplateContentRenderer(
              token,
              accountId,
              selectedClientEncryptedDetails?._id,
              selectedClientEncryptedDetails?.clientProfiles[0]._id
            );
          }

          const selectedTemplate = letterTemplateList?.find((temp) => temp._id === letterTemplateId);

          setData((data) => {
            const newData = {
              ...data,
              body: contentRenderer.current ? contentRenderer.current(body) : body,
              letterName: `${selectedTemplate ? selectedTemplate.title : 'Letter'} - ${moment().format('DD/MM/YYYY')}`
            };
            setIsUpdatingClientOrTemplate(true);
            debouncedSaveLetter(newData, false);
            return newData;
          });
        }

        if (!loadingRenderContent.current) {
          setTimeout(() => setIsLoadingTemplate(false), 500);
        }
      } else {
        setData((data) => ({
          ...data,
          body: ''
        }));
      }
    },
    [letterTemplateList, debouncedSaveLetter, accountId, selectedClientEncryptedDetails, token]
  );

  const changeClient = useCallback(
    async (clientRecord?: clientRecordsInterface) => {
      if (letterTemplateId && !loadingRenderContent.current) {
        setIsLoadingTemplate(true);
        loadingRenderContent.current = true;
        contentRenderer.current = await makeTemplateContentRenderer(
          token,
          accountId,
          clientRecord?._id,
          clientRecord?.clientProfiles[0]?._id
        );
        loadingRenderContent.current = false;
        // useRef to get latest selected template in case user changed template while loading client profile
        if (letterTemplateId) {
          applyTemplate(letterTemplateId);
        } else {
          setIsLoadingTemplate(false);
        }
      }
    },
    [accountId, applyTemplate, letterTemplateId, token]
  );

  const refetchLetter = useCallback(
    async (id?: string) => {
      setIsLoadingData(true);
      await fetchLetter(token, accountId, auth0ClinicianId, id || pathLetterId);
      setIsLoadingData(false);
    },
    [token, accountId, auth0ClinicianId, pathLetterId, fetchLetter]
  );

  const handleChangeClient = useCallback(
    async (clientRecord?: clientRecordsInterface) => {
      if (clientRecord) {
        if (data.clientRecord._id && letterTemplateId && data.body) {
          Modal.confirm({
            title:
              'Are you sure you want to change the client? Changing the client will reset the fields and headings.',
            onOk: async () => {
              updateData({ clientRecord: { _id: clientRecord._id } }, false);
              setValidations((validations) => ({ ...validations, clientRecord: false }));
              await changeClient(clientRecord);
            }
          });
        } else {
          updateData({ clientRecord: { _id: clientRecord._id } }, false);
          setValidations((validations) => ({ ...validations, clientRecord: false }));
          changeClient(clientRecord);
        }
      }
    },
    [changeClient, data.body, data.clientRecord._id, letterTemplateId, updateData]
  );

  const onLetterNameChange = useCallback(
    (selectedTemplateId?: string) => {
      // Get template name to set letter name
      const selectedTemplate = selectedTemplateId
        ? letterTemplateList?.find((temp) => temp._id === selectedTemplateId)
        : null;

      setData((data) => ({
        ...data,
        letterName: `${selectedTemplate ? selectedTemplate.title : 'Letter'} - ${moment().format('DD/MM/YYYY')}`
      }));

      // Reset letter name validation
      if (validations.letterName) {
        setValidations({
          ...validations,
          letterName: false
        });
      }
    },
    [letterTemplateList, validations]
  );

  const onChangeTemplate = useCallback(
    (templateId?: string) => {
      setLetterTemplateId(templateId);
      applyTemplate(templateId);
      templateId && onLetterNameChange(templateId);
    },
    [applyTemplate, onLetterNameChange]
  );

  const handleChangeTemplate = (id?: string) => {
    if (data.body) {
      Modal.confirm({
        title:
          'Are you sure you want to change the template? Changing the template will reset the fields and headings.',
        onOk: async () => {
          await onChangeTemplate(id);
        }
      });
    } else {
      onChangeTemplate(id);
    }
  };

  const handleSaveBtn = useCallback(() => {
    setValidations(validateAll(data).errors);
    debouncedSaveLetter.cancel();
    saveLetter(data, true);
  }, [data, debouncedSaveLetter, validateAll, saveLetter]);

  const publishLetter = useCallback(
    async (letterIdToPublish: string) => {
      try {
        await putPublishLetter(token, accountId, letterIdToPublish);
        setPublishBtnStatus('finished');
        notification.success({
          message: 'Letter Template has been published.',
          duration: 3,
          closeIcon: <span className="success">OK</span>
        });
        refetchLetter(letterIdToPublish);
      } catch (e) {
        if (isErrorBentStatusError(e) && e.statusCode === 409) {
          notification.warning({
            message: 'Letter status out of sync, refreshing page.',
            duration: 2,
            closeIcon: <span className="notify">OK</span>
          });
        } else {
          notification.error({ message: 'Something went wrong while trying to publish your letter.' });
        }
      }
      setTimeout(() => {
        setPublishBtnStatus('');
      }, 500);
    },
    [token, accountId, refetchLetter]
  );

  const handlePublishBtn = useCallback(async () => {
    if (isNewLetter) {
      setPublishBtnStatus('active');
      debouncedSaveLetter.cancel();
      const id = await saveLetter(data, true);
      if (id) {
        await publishLetter(id);
      } else {
        setPublishBtnStatus('');
      }
    } else if (pathLetterId) {
      setPublishBtnStatus('active');
      await publishLetter(pathLetterId);
    }
  }, [isNewLetter, pathLetterId, data, debouncedSaveLetter, publishLetter, saveLetter]);

  const handleUnpublishBtn = useCallback(async () => {
    if (pathLetterId && data?.status === LetterStatus.Published) {
      Modal.confirm({
        title: 'Are you sure you want to unpublish this letter? ',
        icon: '',
        content: `By unpublishing this letter we will revoke the access to view this letter from anyone it is shared with.`,
        okText: 'Unpublish Letter',
        onOk: async () => {
          try {
            await putUnpublishLetter(token, accountId, pathLetterId, isEdgeAdminView);
            notification.success({
              message: 'Letter has been unpublished.',
              duration: 3,
              closeIcon: <span className="success">OK</span>
            });
            refetchLetter();
          } catch (e) {
            notification.error({ message: 'Something went wrong while trying to unpublish the letter.' });
          }
        },
        onCancel: () => {}
      });
    }
  }, [data?.status, pathLetterId, token, accountId, isEdgeAdminView, refetchLetter]);

  const handleDeleteBtn = useCallback(async () => {
    if (pathLetterId && !isNewLetter) {
      Modal.confirm({
        title: 'Are you sure you want to delete this letter? ',
        okText: 'Delete Letter',
        onOk: async () => {
          try {
            await deleteLetter(token, accountId, pathLetterId);
            notification.success({
              message: 'Letter has been deleted.',
              duration: 3,
              closeIcon: <span className="success">OK</span>
            });
            navigate(LETTERS.BASE);
          } catch (e) {
            notification.error({ message: 'Something went wrong while trying to delete the letter.' });
          }
        },
        onCancel: () => {}
      });
    }
  }, [pathLetterId, isNewLetter, token, accountId, LETTERS.BASE, navigate]);

  const handleRevokeAccess = useCallback(
    async (accessorId: string, name?: string) => {
      if (pathLetterId) {
        try {
          await putRevokeLetterAccessor(token, accountId, pathLetterId, accessorId);
          notification.success({
            message: name ? `${name}'s access has been revoke` : 'Access has been revoke',
            duration: 5,
            closeIcon: <span className="success">OK</span>
          });
          const newSharedList = sharedList.filter((x: any) => x._id !== accessorId);
          setSharedList(newSharedList);
        } catch (ex) {
          notification.error({ message: 'Something went wrong while trying to revoke accessor.' });
        }
      }
    },
    [token, accountId, pathLetterId, sharedList]
  );

  const handleDownloadPdf = useCallback(async () => {
    if (pathLetterId) {
      setDownloadPDFButtonStatus('active');
      try {
        const retrievedPDF = await downloadLetterPdf(
          token,
          accountId,
          pathLetterId,
          !letter?.attachment
            ? {
                printPayload: generatePrintableReportPayload(letterRef.current) || ''
              }
            : undefined
        );
        if (retrievedPDF) {
          triggerDownload(retrievedPDF, `Letter_${pathLetterId}`);
        }
        setDownloadPDFButtonStatus('finished');
        // Refetch letter details for attachment checking
        !letter?.attachment && fetchLetter(token, accountId, auth0ClinicianId, pathLetterId);
        notification.success({
          closeIcon: <span className="success">OK</span>,
          duration: 2,
          message: 'Download letter as PDF successful.'
        });
      } catch (ex) {
        notification.error({
          closeIcon: <span className="success">OK</span>,
          duration: 2,
          message: 'Fail to download letter as PDF.'
        });
      } finally {
        setTimeout(() => {
          setDownloadPDFButtonStatus('');
        }, 1000);
      }
    }
  }, [token, accountId, auth0ClinicianId, pathLetterId, letter?.attachment, fetchLetter]);

  /**
   * "resetFormTime" search query is being set when user click "Blank Letter" or "Template Letter" buttons.
   * This is done to support resetting the new form state without reloading the page.
   */
  useEffect(() => {
    if (searchParams.get(RESET_FORM_TIME)) {
      setIsBlankFormInitialized(false);
    }
  }, [searchParams]);

  /** Initialize a new blank form state and populate it with default values */
  useEffect(() => {
    if (isNewLetter && !isBlankFormInitialized && !isSignatureLoading) {
      setData(
        initLetterData({
          clinician: clinicianDetails?.clinician,
          clinicianSignature: mapSignature(signature),
          clinicianSignatureExtraDetails: signature?.extraDetails,
          contactDetails: formatContactDetails(clinicianDetails),
          isForPractice: isEdgeAdminView,
          practice: clinicianDetails?.practice,
          clientRecord: {
            _id: searchParams.get('clientRecordId') || ''
          }
        })
      );

      const searchParamsTemplateId = searchParams.get('templateId') || undefined;
      onChangeTemplate(searchParamsTemplateId);

      contentRenderer.current = undefined;
      setSharedList([]);
      setValidations(initialValidationsState);

      /* Trigger a re-render of the LetterContent component with the updated data */
      setIsLoadingData(() => {
        setTimeout(() => {
          setIsLoadingData(false);
          searchParams.delete(RESET_FORM_TIME);
          setSearchParams(searchParams);
          setIsBlankFormInitialized(true);
        }, 500);
        return true;
      });
    }
  }, [
    clinicianDetails,
    isBlankFormInitialized,
    isEdgeAdminView,
    isNewLetter,
    isSignatureLoading,
    onChangeTemplate,
    searchParams,
    setSearchParams,
    signature
  ]);

  /**
   * Configure a timer to automatically update the last edited message.
   * The timer updates the duration of time elapsed since the last edit.
   */
  useEffect(() => {
    const timer = setInterval(() => {
      if (lastEdited) {
        const now = moment();
        const duration = timeDiff(now, lastEdited);
        setFormattedDuration(duration);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [lastEdited]);

  /**
   * Populate the form state with the fetched letter.
   * Ensure that the fetched letter matches the specified letter ID in the path to prevent race condition.
   */
  useEffect(() => {
    const isMatchingLetterId = letter?._id === pathLetterId;

    if (!isLoadingLetter && letter && isMatchingLetterId) {
      setIsLoadingData(true);
      setData({
        ...letter,
        body: letter.body,
        clientRecord: { _id: letter.clientRecord._id },
        clinician: letter.clinician,
        clinicianSignature: letter.clinicianSignature,
        clinicianSignatureExtraDetails: letter.clinicianSignatureExtraDetails,
        contactDetails: letter.contactDetails,
        isForPractice: letter.isForPractice,
        issueDate: letter.issueDate,
        letterName: letter.letterName,
        practice: letter.practice,
        status: letter.status
      });

      setSharedList(letter.shareDetails?.accessors?.sort((_, b) => (b.isClient ? 1 : -1)) ?? []);

      /**
       * Just a temporary solution, to force the letter content re-render
       * Then, we should add useEffect to update RichEditor editorValue state
       */
      setTimeout(() => {
        setIsLoadingData(false);
      }, 500);
    }
  }, [isLoadingLetter, letter, pathLetterId]);

  return (
    <HelmetWrapper title={'Tacklit - Letter Builder'}>
      <ContentLayout withMaxWidth className={styles.letterContainer}>
        {isLoadingData || isLoadingLetterTemplateList ? (
          <div className={styles.loading}>
            <LoadingCircle />
          </div>
        ) : (
          <div className={styles.container}>
            <div className={styles.builderHeader}>
              <div className={styles.titleSection}>
                <div className={styles.title}>Letter Builder</div>
                <ButtonAlt
                  variant={'text'}
                  size={'medium'}
                  icon={'arrow_back_ios'}
                  onClick={() => navigate(LETTERS.BASE)}
                >
                  Back to letter listing
                </ButtonAlt>
              </div>
              <div className={styles.actionSection}>
                <div className={styles.btnWrapper}>
                  {data.status === LetterStatus.Published ? (
                    <>
                      <ButtonAlt
                        variant="outlined"
                        icon="file_download"
                        onClick={handleDownloadPdf}
                        status={downloadPDFButtonStatus}
                        loadingWord="Preparing PDF"
                      >
                        Download as PDF
                      </ButtonAlt>

                      {clinicianProfile && letter && (
                        <ReportContentAsPDF
                          type="letter"
                          letter={letter}
                          practice={clinicianProfile?.practice}
                          ref={letterRef}
                        />
                      )}
                      {faxEnable && (
                        <>
                          <ButtonAlt variant="outlined" icon="ios_share" onClick={() => setIsShareModalOpen(true)}>
                            Send eFax
                          </ButtonAlt>
                          <ShareReportModal
                            type="letter"
                            generalPractitionerId={selectedClientEncryptedDetails?.referral?.generalPractitionerId}
                            isModalOpen={isShareModalOpen}
                            reportId={pathLetterId || ''}
                            reportName={data.letterName}
                            closeModal={() => setIsShareModalOpen(false)}
                            letterData={letter}
                            onRefetchReportData={refetchLetter}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <ButtonAlt
                        variant={'outlined'}
                        onClick={handleSaveBtn}
                        status={saveBtnStatus}
                        disabled={false}
                        loadingWord="Processing"
                      >
                        Save Changes
                      </ButtonAlt>
                      <ButtonAlt onClick={() => setIsPreviewModalOpen(true)}>View Preview</ButtonAlt>
                    </>
                  )}
                </div>
                {formattedDuration && (
                  <div className={styles.lastEditMessage}>{`Last edit was ${formattedDuration}`}</div>
                )}
              </div>
            </div>
            <div className={styles.builderContent}>
              <div className={styles.leftSection}>
                <LetterSideBar
                  selectedClientRecordId={data.clientRecord._id}
                  letterName={data.letterName}
                  letterTemplateList={letterTemplateList}
                  publishBtnStatus={publishBtnStatus}
                  isNewLetter={isNewLetter}
                  clientProfiles={selectedClientEncryptedDetails?.clientProfiles}
                  clientAllowCommunicationWithEmail={selectedClientEncryptedDetails?.allowCommunicationWithEmail}
                  recordType={selectedClientEncryptedDetails?.recordType}
                  letterId={pathLetterId}
                  status={data.status}
                  sharedList={sharedList}
                  letterTemplateId={letterTemplateId}
                  isLoadingLetterTemplateList={isLoadingLetterTemplateList}
                  isClientRecordInvalid={validations.clientRecord}
                  isLetterNameInvalid={validations.letterName}
                  isReadOnly={isReadOnly}
                  isLoadingRenderContent={loadingRenderContent.current}
                  isAuthor={isAuthor}
                  handleChangeTemplate={handleChangeTemplate}
                  handlePublishBtn={handlePublishBtn}
                  handleUnpublishBtn={handleUnpublishBtn}
                  handleDeleteBtn={handleDeleteBtn}
                  handleRevokeAccess={handleRevokeAccess}
                  setSharedList={setSharedList}
                  selectClient={handleChangeClient}
                  updateData={updateData}
                  isChangingClientOrTemplateDisabled={
                    isUpdatingClientOrTemplate || isLoadingTemplate || loadingRenderContent.current
                  }
                  episodeId={data.episodeId}
                />
              </div>
              <div className={styles.rightSection}>
                {isReadOnly ? (
                  <LetterPreview data={data} clientRecord={selectedClientEncryptedDetails} />
                ) : (
                  <LetterContent
                    letterName={data.letterName}
                    body={data.body}
                    issueDate={data.issueDate}
                    clientRecordId={data.clientRecord._id}
                    clinician={data.clinician}
                    practice={data.practice}
                    contactDetails={data.contactDetails}
                    clinicianSignature={data.clinicianSignature}
                    clinicianSignatureExtraDetails={data.clinicianSignatureExtraDetails}
                    clientRecordData={selectedClientEncryptedDetails}
                    isLoadingTemplate={isLoadingTemplate}
                    isLoadingClientRecordData={isClientEncryptDetailsLoading || isClientEncryptDetailsFetching}
                    isSignatureLoading={isSignatureLoading}
                    isSignatureInvalid={validations.clinicianSignature}
                    isBodyInvalid={validations.body}
                    updateData={updateData}
                  />
                )}
              </div>
            </div>
            <ModalV2
              containerClassName={styles.previewContainer}
              isModalOpen={isPreviewModalOpen}
              title="Letter Preview"
              onModalClose={() => setIsPreviewModalOpen(false)}
              bodyStyle={{
                padding: '0'
              }}
            >
              <LetterPreview data={data} clientRecord={selectedClientEncryptedDetails} />
            </ModalV2>
          </div>
        )}
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default LetterDetails;
