import { Modal, notification } from 'antd';
import { calculateInvoiceTotals } from 'components/InvoiceView/components/InvoiceTotal/calculateInvoiceTotals';
import { ButtonStatusType } from 'components/v2/Button/Button';
import ModalHeader from 'components/v2/ModalHeader/ModalHeader';
import { clientProfilesInterface } from 'interfaces/Clients/clientsRecord';
import { ClinicianMedicareRole } from 'interfaces/Profile/Profile';
import { useFetchMedicareProviders } from 'pages/ControlPanel/IntegrationDetails/components/IntegrationDetailsContent/components/IntegrationDetailsContentDisplay/components/Integration/hooks/getMedicareData';
import { Invoice } from 'pages/Invoices/interface';
import { useEffect, useMemo, useState } from 'react';
import { getClinicianDefaultProvider } from 'utils/helpers/getClinicianDefaultProvider';
import { useGetInvoiceSettingsQuery } from 'redux/endpoints/billingServices/invoiceSetting';
import { useGetAccessToken } from 'utils/hooks/token';
import { createClaim } from 'utils/http/BillingService/Invoice/claimingcom';

import { MBS_CODE_ITEMS } from '../../../../mbsItems';
import { Claim, ClaimType } from '../../../../hooks/getClaims';
import { MedicareItemDuration, MedicareItemMode } from '../../../../interfaces';
import ClaimForm from './components/ClaimForm/ClaimForm';
import InvoiceListing from './components/InvoiceListing/InvoiceListing';
import styles from './CreateClaimModal.module.scss';
import { useFetchPractitionerOptions } from './hooks/getPractitionerList';
import { usePrepopulateFormFromAppointment } from './hooks/prepopulateFormFromAppointment';
import { ClaimFormData, ClaimFormErrors, defaultClaimFormData, defaultClaimFormErrors } from './initValue';
import { validateClaimForm } from './validation';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import { Location } from 'pages/ControlPanel/Interfaces/Claimingcom';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';

export const CLAIM_TYPE_LABELS = {
  [ClaimType.BulkBill]: 'Bulk-Bill',
  [ClaimType.Medicare]: 'Rebate',
  [ClaimType.DVA]: 'DVA'
};

const MEDICARE_CLIENT_CLAIM_TYPES = [ClaimType.BulkBill, ClaimType.Medicare].map((item) => ({
  label: CLAIM_TYPE_LABELS[item],
  value: item
}));

const DVA_CLIENT_CLAIM_TYPES = [ClaimType.DVA].map((item) => ({
  label: CLAIM_TYPE_LABELS[item],
  value: item
}));

interface CreateClaimModalProps {
  clientRecordId: string;
  clientProfile: clientProfilesInterface;
  parentProfile?: clientProfilesInterface;
  isMedicareClient: boolean;
  isDvaClient: boolean;
  invoices?: Invoice[];
  claims?: Claim[];
  referral: {
    name: string;
    date: string;
    providerNumber: string;
  };
  isInvoicesLoading?: boolean;
  visible: boolean;
  locations: Location[];
  onCancel: () => void;
  refetchClaims: () => void;
  refetchClaimSummary?: () => void;
  preSelectedStep?: 1 | 2;
  hideStepProcessBar?: boolean;
  preSelectedInvoice?: Invoice;
  preSelectedClaimType?: ClaimType;
  isClientReferralValid: boolean;
}

const CreateClaimModal = ({
  clientRecordId,
  clientProfile,
  parentProfile,
  isMedicareClient,
  isDvaClient,
  invoices,
  claims,
  referral,
  isInvoicesLoading,
  visible,
  locations,
  onCancel,
  refetchClaims,
  refetchClaimSummary,
  preSelectedInvoice,
  preSelectedClaimType,
  isClientReferralValid
}: CreateClaimModalProps) => {
  const { token } = useGetAccessToken();
  const { auth0ClinicianId } = useGetClinicianId();

  const [claimForm, setClaimForm] = useState(defaultClaimFormData);

  const { providers, isProvidersLoading } = useFetchMedicareProviders(token, true);
  const { practitionersOptions, isPractitionersOptionsLoading } = useFetchPractitionerOptions(token, setClaimForm);
  const {
    data: invoiceSettings,
    isLoading: isInvoiceSettingLoading,
    isFetching: isInvoiceSettingFetching
  } = useGetInvoiceSettingsQuery({
    clinicianId: auth0ClinicianId
  });
  const isInvoiceSettingsLoading = isInvoiceSettingLoading || isInvoiceSettingFetching;

  const [step, setStep] = useState<1 | 2>(1);
  const [selectedClaimType, setSelectedClaimType] = useState<ClaimType>();
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice>();
  const [errors, setErrors] = useState<ClaimFormErrors>(defaultClaimFormErrors);
  const [showErrors, setShowErrors] = useState(false);
  const [showClaimTypeError, setShowClaimTypeError] = useState(false);
  const [isConfirmChecked, setIsConfirmChecked] = useState(false);
  const [createButtonStatus, setCreateButtonStatus] = useState<ButtonStatusType>('');

  const { isPrepopulateLoading } = usePrepopulateFormFromAppointment({
    token,
    appointmentId: selectedInvoice?.items[0]?.appointmentId,
    setClaimForm,
    setErrors
  });

  const allowPracticePayee = !!(
    selectedClaimType &&
    [ClaimType.BulkBill, ClaimType.DVA].includes(selectedClaimType) &&
    invoiceSettings?.medicare?.providerNumber
  );

  const {
    selectedPractitionerRole,
    filteredPractitionerOptions,
    locationOptions,
    providerOptions,
    mbsItems,
    mbsCodeOptions
  } = useMemo(() => {
    const selectedPractitionerRole = practitionersOptions.find(({ value }) => value === claimForm.clinicianId)?.role;
    const mbsItems = MBS_CODE_ITEMS[selectedPractitionerRole || ClinicianMedicareRole.ClinicalPsychologists];

    return {
      selectedPractitionerRole,
      filteredPractitionerOptions: practitionersOptions.filter(({ value }) =>
        providers.some(({ clinicianId }) => clinicianId === value)
      ),
      locationOptions: locations.map(({ name, minorId }) => ({
        label: name,
        options: [{ label: minorId, value: minorId }]
      })),
      providerOptions: claimForm.clinicianId
        ? providers
            .filter(
              ({ clinicianId, locationMinorId }) =>
                clinicianId === claimForm.clinicianId && (!claimForm.minorId || locationMinorId === claimForm.minorId)
            )
            .map(({ name, providerNumber }) => ({
              label: name,
              options: [{ label: providerNumber, value: providerNumber }]
            }))
        : [],
      mbsItems,
      mbsCodeOptions: mbsItems.map(({ mbsCode, benefit, description }) => ({
        label: mbsCode,
        value: mbsCode,
        benefit,
        description
      }))
    };
  }, [practitionersOptions, locations, providers, claimForm.clinicianId, claimForm.minorId]);

  useEffect(() => {
    const claimFormMode =
      claimForm.mode === MedicareItemMode.PhoneCall
        ? [MedicareItemMode.PhoneCall, MedicareItemMode.Telehealth]
        : claimForm.mode === MedicareItemMode.VideoCall
        ? [MedicareItemMode.VideoCall, MedicareItemMode.Telehealth]
        : claimForm.mode === MedicareItemMode.Telehealth
        ? [MedicareItemMode.PhoneCall, MedicareItemMode.VideoCall, MedicareItemMode.Telehealth]
        : [claimForm.mode];

    const foundMbsItem =
      mbsItems.find(
        ({ duration, format, mode }) =>
          (!claimForm.duration || duration === claimForm.duration) &&
          (!claimForm.format || format === claimForm.format) &&
          (!claimForm.mode || claimFormMode.includes(mode))
      ) ||
      mbsItems.find(
        ({ duration, format, mode }) =>
          (!claimForm.format || format === claimForm.format) &&
          (((!claimForm.duration || duration === claimForm.duration) &&
            (!claimForm.mode || mode === MedicareItemMode.Any || claimFormMode.includes(mode))) ||
            ((!claimForm.duration || duration === MedicareItemDuration.Any || duration === claimForm.duration) &&
              (!claimForm.mode || claimFormMode.includes(mode))))
      );

    setClaimForm((claimForm) => ({
      ...claimForm,
      ...(foundMbsItem && { mbsCode: foundMbsItem.mbsCode })
    }));
  }, [selectedPractitionerRole, mbsItems, claimForm.duration, claimForm.format, claimForm.mode]);

  useEffect(() => {
    setClaimForm((claimForm) => ({
      ...claimForm,
      dvaCondition: selectedClaimType === ClaimType.DVA ? 'Mental Health' : ''
    }));
  }, [selectedClaimType, setClaimForm]);

  useEffect(() => {
    if (claimForm.clinicianId !== '') {
      const defaultProvider = getClinicianDefaultProvider(
        providers.filter(({ clinicianId }) => clinicianId === claimForm.clinicianId)
      );
      if (defaultProvider) {
        setClaimForm((claimForm) => {
          const newForm = {
            ...claimForm,
            minorId: defaultProvider.locationMinorId,
            providerNumber: defaultProvider.providerNumber
          };
          return newForm;
        });
        return;
      }
    }

    const defaultLocation = locations.find(({ default: isDefault }) => isDefault);
    if (defaultLocation) {
      setClaimForm((claimForm) => {
        const newForm = {
          ...claimForm,
          minorId: defaultLocation.minorId
        };
        return newForm;
      });
    }
  }, [claimForm.clinicianId, providers, locations]);

  useEffect(() => {
    if (visible && preSelectedInvoice) {
      setStep(2);
      setSelectedInvoice(preSelectedInvoice);
    }
    if (visible && preSelectedClaimType) {
      setSelectedClaimType(preSelectedClaimType);
    }
  }, [preSelectedClaimType, preSelectedInvoice, visible]);

  const resetFormData = () => {
    setClaimForm(defaultClaimFormData);
    setIsConfirmChecked(false);
    setErrors(defaultClaimFormErrors);
    setShowErrors(false);
    setSelectedClaimType(undefined);
    setSelectedInvoice(undefined);
    setStep(1);
  };

  const handleCancel = () => {
    onCancel();
    resetFormData();
  };

  const handleSelectInvoice = (invoice: Invoice, claimType: ClaimType) => {
    setSelectedClaimType(claimType);
    setSelectedInvoice(invoice);
    setStep(2);
  };

  const handleFormChange = (form: ClaimFormData) => {
    setClaimForm(form);

    const errors = validateClaimForm(form);
    setErrors(errors);
  };

  const handleSubmit = async () => {
    setShowErrors(true);

    const errors = validateClaimForm(claimForm);
    setErrors(errors);

    if (preSelectedInvoice && !selectedClaimType) {
      setShowClaimTypeError(true);
      return;
    }

    if (selectedInvoice && !Object.values(errors).some((value) => !!value) && isConfirmChecked) {
      setCreateButtonStatus('active');

      try {
        const { minorId, providerNumber, duration, format, mode, mbsCode, isPracticePayee, dvaCondition } = claimForm;

        const payload = {
          type: selectedClaimType,
          invoiceId: selectedInvoice._id,
          clientRecordId: clientRecordId,
          clientProfileId: clientProfile._id,
          mbsCode,
          benefit: Math.min(
            selectedInvoice.invoiceAmount,
            mbsItems.find((item) => item.mbsCode === mbsCode)?.benefit || 0
          ),
          service: { duration, format, mode },
          ...(allowPracticePayee && { isPracticePayee }),
          ...(selectedClaimType === ClaimType.DVA && { dvaCondition })
        };

        const response = await createClaim(token, minorId, providerNumber, payload);

        if (response.statusCode === 201) {
          refetchClaims();
          refetchClaimSummary && refetchClaimSummary();
          setCreateButtonStatus('finished');
          handleCancel();
          setShowErrors(false);

          notification.success({
            message: 'Claim created'
          });

          setTimeout(() => {
            setCreateButtonStatus('');
          }, 2000);
        } else if (response.statusCode === 400 || response.statusCode === 404) {
          setCreateButtonStatus('');

          const { message } = (await response.json()) as { message: 'string' };
          notification.error({
            message
          });
        } else {
          setCreateButtonStatus('');
          notification.error({ message: 'Something went wrong while trying to create this claim' });
        }
      } catch (ex) {
        setCreateButtonStatus('');
        notification.error({ message: 'Something went wrong while trying to create this claim' });
        console.error(ex);
      }
    }
  };

  return (
    <Modal
      bodyStyle={{
        padding: 0,
        display: 'flex',
        flexDirection: 'column'
      }}
      width={800}
      footer={null}
      closable={false}
      open={visible}
      destroyOnClose
    >
      <ModalHeader
        title={
          step === 2 && selectedClaimType ? `New ${CLAIM_TYPE_LABELS[selectedClaimType]} Claim` : 'Create New Claim'
        }
        currentStep={step}
        totalSteps={2}
        onBack={resetFormData}
        onCancel={handleCancel}
        hideStepProcessBar={!!preSelectedInvoice}
      />
      <div className={styles.detailsContainer}>
        {step === 2 && preSelectedInvoice && (
          <div className={styles.inputContainer}>
            <div className={styles.dropdown}>
              <MaterialSelect
                id="claimType"
                label="Claim Type"
                options={isMedicareClient ? MEDICARE_CLIENT_CLAIM_TYPES : DVA_CLIENT_CLAIM_TYPES}
                value={(selectedClaimType as string) || ''}
                isSearchable={false}
                isValidNewOption={() => false}
                onChange={(value) => {
                  setShowClaimTypeError(false);
                  setSelectedClaimType(value);
                }}
              />
              {showClaimTypeError && <div className={styles.error}>Please select claim type</div>}
            </div>
          </div>
        )}

        <div className={styles.title}>FOR</div>
        {parentProfile?.medicare && <div className={styles.title}>CHILD DETAILS</div>}
        <div className={styles.details}>
          <div className={styles.item}>
            <div className={styles.label}>Name</div>
            <div className={styles.value}>
              {clientProfile.medicare?.firstName} {clientProfile.medicare?.lastName}
            </div>
          </div>
          {isMedicareClient &&
            (!selectedClaimType || [ClaimType.BulkBill, ClaimType.Medicare].includes(selectedClaimType)) && (
              <div className={styles.item}>
                <div className={styles.label}>Medicare #</div>
                <div className={styles.value}>
                  {clientProfile.medicare?.number}-{clientProfile.medicare?.irn}
                </div>
              </div>
            )}
          {isDvaClient && (!selectedClaimType || selectedClaimType === ClaimType.DVA) && (
            <div className={styles.item}>
              <div className={styles.label}>DVA #</div>
              <div className={styles.value}>{clientProfile.medicare?.dva}</div>
            </div>
          )}
        </div>
        {parentProfile?.medicare && (
          <div className={styles.parentSection}>
            <div className={styles.title}>PARENT DETAILS</div>
            <div className={styles.details}>
              <div className={styles.item}>
                <div className={styles.label}>Name</div>
                <div className={styles.value}>
                  {parentProfile.medicare.firstName} {parentProfile.medicare.lastName}
                </div>
              </div>
              {isMedicareClient &&
                (!selectedClaimType || [ClaimType.BulkBill, ClaimType.Medicare].includes(selectedClaimType)) && (
                  <div className={styles.item}>
                    <div className={styles.label}>Medicare #</div>
                    <div className={styles.value}>
                      {parentProfile.medicare.number}-{parentProfile.medicare.irn}
                    </div>
                  </div>
                )}
              {isDvaClient && (!selectedClaimType || selectedClaimType === ClaimType.DVA) && (
                <div className={styles.item}>
                  <div className={styles.label}>DVA #</div>
                  <div className={styles.value}>{parentProfile.medicare.dva}</div>
                </div>
              )}
            </div>
          </div>
        )}

        {step === 2 && selectedClaimType && selectedInvoice && (
          <div className={styles.details}>
            <div className={styles.item}>
              <div className={styles.label}>Invoice</div>
              <div className={styles.value}>{selectedInvoice.invoiceId}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.label}>Patient Contribution</div>
              <div className={styles.value}>
                $
                {calculateInvoiceTotals(
                  selectedInvoice.items,
                  selectedInvoice.taxRate,
                  selectedInvoice.discount?.type,
                  selectedInvoice.discount?.value
                ).totalPayment.toFixed(2)}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.detailsContainer}>
        <div className={styles.title}>REFERRED BY</div>
        <div className={styles.details}>
          <div className={styles.item}>
            <div className={styles.label}>Name</div>
            <div className={styles.value}>{referral.name}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.label}>Date of Referral</div>
            <div className={styles.value}>{referral.date}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.label}>Provider Number</div>
            <div className={styles.value}>{referral.providerNumber}</div>
          </div>
        </div>
      </div>
      {step === 1 && (
        <InvoiceListing
          isMedicareClient={isMedicareClient}
          isDvaClient={isDvaClient}
          invoices={invoices || []}
          claims={claims || []}
          isLoading={isInvoicesLoading || isInvoiceSettingsLoading}
          onSelectInvoice={handleSelectInvoice}
        />
      )}
      {step === 2 && selectedInvoice && (
        <ClaimForm
          claimForm={claimForm}
          errors={errors}
          createButtonStatus={createButtonStatus}
          invoice={selectedInvoice}
          practitionersOptions={filteredPractitionerOptions}
          locationOptions={locationOptions}
          providerOptions={providerOptions}
          mbsCodeOptions={mbsCodeOptions}
          allowPracticePayee={allowPracticePayee}
          isPractitionersOptionsLoading={isPractitionersOptionsLoading}
          isProvidersLoading={isProvidersLoading}
          isPrepopulateLoading={isPrepopulateLoading}
          isConfirmChecked={isConfirmChecked}
          isSubmitButtonDisabled={
            selectedPractitionerRole !== ClinicianMedicareRole.GeneralPractitioner && !isClientReferralValid
          }
          showErrors={showErrors}
          onChange={handleFormChange}
          onConfirmCheckedChange={setIsConfirmChecked}
          onSubmit={handleSubmit}
          selectedClaimType={selectedClaimType}
        />
      )}
    </Modal>
  );
};

export default CreateClaimModal;
