import classnames from 'classnames';
import { get } from 'lodash';
import styles from './ButtonAlt.module.scss';
import LoadingSpinner from 'components/v2/ButtonAlt/LoadingSpinner/LoadingSpinner';
import Finished from './Finished/Finished';
import { ButtonHTMLAttributes } from 'react';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useNavigate } from 'react-router-dom';

export type ButtonStatusType = '' | 'active' | 'finished';
export type ButtonAltSize = 'small' | 'medium' | 'large';
export type ButtonAltVariant = 'contained' | 'outlined' | 'text' | 'contained-fab';
export enum ButtonTheme {
  Admin = 'adminTheme',
  User = 'userTheme'
}
export enum IconVariant {
  Filled = 'material-icons',
  Outlined = 'material-icons-outlined'
}

export interface ButtonAltProps {
  status?: ButtonStatusType;
  contentClassName?: any;
  activeClassName?: any;
  finishedClassName?: any;
  size?: ButtonAltSize;
  variant?: ButtonAltVariant;
  fab?: boolean;
  icon?: string;
  iconVariant?: IconVariant;
  iconPostFix?: boolean;
  id?: string;
  noTransition?: boolean;
  buttonTheme?: ButtonTheme;
  fullWidth?: boolean;
  to?: string;
  loadingWord?: string;
  completedWord?: string;
  error?: boolean;
  disabled?: boolean;
  useDivAsBtn?: boolean;
  autoHeight?: boolean;
  iconClassName?: string;
}

const ButtonAlt = ({
  status = '',
  contentClassName,
  activeClassName,
  finishedClassName,
  size = 'large',
  variant = 'contained',
  fab,
  icon,
  iconVariant,
  iconPostFix,
  id,
  noTransition,
  children,
  buttonTheme,
  fullWidth,
  to,
  loadingWord,
  completedWord,
  error,
  disabled,
  useDivAsBtn,
  autoHeight,
  iconClassName,
  ...props
}: ButtonAltProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const navigate = useNavigate();
  const { isEdgeAdminView } = useGetAccountPackageView();
  const statusClass = get(
    {
      active: classnames(styles.active, activeClassName),
      finished: classnames(styles.finished, finishedClassName)
    },
    status,
    ''
  );
  const { className } = props;

  const handleNavigate = (value: string) => {
    navigate(value);
  };

  const round = !children && fab;

  const content = () => (
    <>
      <span className={classnames(styles.submit, contentClassName)}>
        {!iconPostFix && icon && (
          <i
            className={classnames(
              iconVariant ? iconVariant : IconVariant.Outlined,
              styles.icon,
              children && styles.iconLeft,
              iconClassName
            )}
          >
            {icon}
          </i>
        )}
        {children && <div>{children}</div>}
        {iconPostFix && icon && (
          <i
            className={classnames(
              iconVariant ? iconVariant : IconVariant.Outlined,
              styles.icon,
              children && styles.iconRight,
              iconClassName
            )}
          >
            {icon}
          </i>
        )}
      </span>
      <span className={styles.loading}>
        <LoadingSpinner className={styles.loadingSpinner} />
        <div>{loadingWord ? loadingWord : 'Loading'}</div>
      </span>
      <span className={styles.check}>
        <Finished />
        <div>{completedWord ? completedWord : 'Loading'}</div>
      </span>
    </>
  );

  return (
    <div
      className={classnames(
        error
          ? styles.errorTheme
          : buttonTheme
          ? styles[buttonTheme]
          : isEdgeAdminView
          ? styles.adminTheme
          : styles.userTheme
      )}
    >
      <div className={classnames(fab && styles.fab, variant && styles[variant], round && styles.round)}>
        {useDivAsBtn ? (
          <div
            className={classnames(
              styles.container,
              className,
              statusClass,
              styles[size],
              fullWidth && styles.fullWidth,
              noTransition && styles.noTransition,
              disabled && styles.disabled
            )}
          >
            {content()}
          </div>
        ) : (
          <button
            {...props}
            onClick={status === '' ? (to ? () => handleNavigate(to) : props.onClick) : undefined}
            className={classnames(
              styles.container,
              className,
              statusClass,
              styles[size],
              autoHeight && styles.autoHeight,
              fullWidth && styles.fullWidth,
              noTransition && styles.noTransition
            )}
            disabled={status !== '' || disabled}
          >
            {content()}
          </button>
        )}
      </div>
    </div>
  );
};

export default ButtonAlt;
