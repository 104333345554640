import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { useAppDispatch } from 'redux/hooks';
import { useEffect, useMemo } from 'react';
import {
  useGetAppointmentsByBatchQuery,
  useGetAppointmentsByClinicianIdsQuery
} from 'redux/endpoints/scheduleServices/appointment';
import { getIds } from './useAppointmentEvents';
import { updateAppointments } from 'redux/calendarAppointmentList/calendarAppointmentListDataSlice';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { useFetchFilterList } from '../../CalendarFilterSection/hooks/GetFilterList';

const REFETCH_EVERY_MINUTES = 5;
/**
 * Custom hook for fetching appointments based on clinician and room IDs.
 *
 * This hook decides whether to batch requests based on the number of IDs. If batching is needed,
 * it fetches appointments in batches. If not, it fetches them directly. It returns the appointment data,
 * and loading and fetching states.
 */

/* Adjust to 5 for reduce performance issue. */
export const BATCH_CHUNK_SIZE = 5;

export const chunkArray = (array: string[], chunkSize: number) => {
  return Array.from({ length: Math.ceil(array.length / chunkSize) }, (_, index) =>
    array.slice(index * chunkSize, index * chunkSize + chunkSize)
  );
};

const rearrangeSelectedChunks = (completeList: string[][], selectedIds: string[]) => {
  return completeList
    .map((group) => group.filter((id) => selectedIds.includes(id)))
    .filter((group) => group.length > 0);
};

interface appointmentsEventByBatchInterface {
  from: string;
  to: string;
  clinicianIds: string[];
  roomIds: string[];
}

export const useAppointmentsEventByBatch = ({ from, to, clinicianIds, roomIds }: appointmentsEventByBatchInterface) => {
  const { accountId } = useGetAccountId();
  const { isEdgeUserView } = useGetAccountPackageView();
  const { accountTimeZone } = useTimeZone();
  const { auth0ClinicianId } = useGetClinicianId();
  const dispatch = useAppDispatch();
  const { practitionersList: practitionerFullList, roomList: roomFullList } = useFetchFilterList();

  const clinicianCompleteListWithBatch = useMemo(
    () => chunkArray(getIds(practitionerFullList), BATCH_CHUNK_SIZE),
    [practitionerFullList]
  );
  const roomCompleteListWithBatch = useMemo(() => chunkArray(getIds(roomFullList), BATCH_CHUNK_SIZE), [roomFullList]);

  const clinicianIdChunks = useMemo(
    () => rearrangeSelectedChunks(clinicianCompleteListWithBatch, clinicianIds),
    [clinicianIds, clinicianCompleteListWithBatch]
  );

  const roomIdChunks = useMemo(
    () => rearrangeSelectedChunks(roomCompleteListWithBatch, roomIds),
    [roomIds, roomCompleteListWithBatch]
  );

  const totalClinicianAndRoomIds = clinicianIds.length + roomIds.length;
  const shouldBatchFetch = totalClinicianAndRoomIds >= BATCH_CHUNK_SIZE;

  const {
    data: batchClinicianAppointmentData,
    isLoading: batchClinicianAppointmentLoading,
    isFetching: batchClinicianAppointmentFetching
  } = useGetAppointmentsByBatchQuery(
    {
      accountId,
      asUser: isEdgeUserView,
      params: {
        from,
        to
      },
      timeZone: accountTimeZone,
      chunkIds: clinicianIdChunks,
      type: 'clinicianIds'
    },
    {
      skip: !shouldBatchFetch || clinicianIdChunks.length <= 0,
      pollingInterval: 1000 * 60 * REFETCH_EVERY_MINUTES
    }
  );

  const {
    data: batchRoomAppointmentData,
    isLoading: batchRoomAppointmentLoading,
    isFetching: batchRoomAppointmentFetching
  } = useGetAppointmentsByBatchQuery(
    {
      accountId,
      asUser: isEdgeUserView,
      params: {
        from,
        to
      },
      timeZone: accountTimeZone,
      chunkIds: roomIdChunks,
      type: 'roomIds'
    },
    {
      skip: !shouldBatchFetch || roomIdChunks.length <= 0,
      pollingInterval: 1000 * 60 * REFETCH_EVERY_MINUTES
    }
  );

  const completeBatchList = useMemo(
    () => [...(batchClinicianAppointmentData || []), ...(batchRoomAppointmentData || [])],
    [batchClinicianAppointmentData, batchRoomAppointmentData]
  );

  useEffect(() => {
    if (shouldBatchFetch && completeBatchList) {
      dispatch(updateAppointments({ appts: completeBatchList, from, to, roomIds, clinicianIds, auth0ClinicianId }));
    }
  }, [
    completeBatchList,
    batchClinicianAppointmentData,
    batchRoomAppointmentData,
    shouldBatchFetch,
    from,
    to,
    roomIds,
    clinicianIds,
    dispatch,
    auth0ClinicianId
  ]);

  const {
    data: notBatchAppointmentData,
    isLoading: notBatchAppointmentLoading,
    isFetching: notBatchAppointmentFetching
  } = useGetAppointmentsByClinicianIdsQuery(
    {
      accountId,
      asUser: isEdgeUserView,
      params: {
        from,
        to,
        clinicianIds: clinicianIds.join(','),
        roomIds: roomIds.join(',')
      },
      timeZone: accountTimeZone
    },
    {
      skip: clinicianIds.length <= 0 || shouldBatchFetch,
      pollingInterval: 1000 * 60 * REFETCH_EVERY_MINUTES
    }
  );

  useEffect(() => {
    if (!shouldBatchFetch && notBatchAppointmentData) {
      dispatch(
        updateAppointments({ appts: notBatchAppointmentData, from, to, roomIds, clinicianIds, auth0ClinicianId })
      );
    }
  }, [notBatchAppointmentData, shouldBatchFetch, from, to, roomIds, clinicianIds, dispatch, auth0ClinicianId]);

  return {
    data: shouldBatchFetch ? completeBatchList : notBatchAppointmentData,
    isLoading: shouldBatchFetch
      ? batchClinicianAppointmentLoading || batchRoomAppointmentLoading
      : notBatchAppointmentLoading,
    isFetching: shouldBatchFetch
      ? batchClinicianAppointmentFetching || batchRoomAppointmentFetching
      : notBatchAppointmentFetching
  };
};
